import { styled } from '@mui/material'
import React from 'react'
import SimpleBar from 'simplebar-react'

const StyledScrollbar = styled(SimpleBar)({})

const Scrollbar = React.forwardRef<
  typeof StyledScrollbar,
  React.ComponentProps<typeof StyledScrollbar>
>((props, ref) => {
  const { sx, children, ...rest } = props
  return (
    <StyledScrollbar
      ref={ref as any}
      sx={{ height: '100%', maxHeight: '100%', flex: 1, ...sx }}
      {...rest}
    >
      {children}
    </StyledScrollbar>
  )
})

export { Scrollbar }
