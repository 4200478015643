import { Drawer, IconButton, SvgIcon, useTheme } from '@mui/material'
import { XClose } from '@untitled-ui/icons-react'
import React, { Fragment, useMemo } from 'react'

import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'
import type { NavColor } from '~ui-components/types/settings'

interface MobileNavProps {
  disableScrollbar?: boolean
  open?: boolean
  color?: NavColor
  mobileNavWidth?: number | string
  onClose?: () => void
  slots?: {
    Content?: React.ElementType
  }
  slotProps?: {
    Content?: any
  }
}

const defaultSlots = {
  Content: Fragment
}

function MobileNav(props: MobileNavProps) {
  const {
    disableScrollbar = false,
    mobileNavWidth = 280,
    color = 'evident',
    open,
    onClose,
    slots,
    slotProps
  } = props
  const cssVars = useCssVars(color)

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const ScrollbarRoot = disableScrollbar ? Fragment : Scrollbar

  return (
    <Drawer
      anchor='left'
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          color: 'var(--nav-color)',
          width: mobileNavWidth
        }
      }}
      variant='temporary'
    >
      <ScrollbarRoot
        {...(disableScrollbar
          ? {}
          : {
              sx: {
                height: '100%',
                '& .simplebar-content': {
                  height: '100%'
                },
                '& .simplebar-scrollbar:before': {
                  background: 'var(--nav-scrollbar-color)'
                }
              }
            })}
      >
        {mobileNavWidth === '100%' && (
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              mr: 2,
              mt: 2
            }}
          >
            <SvgIcon>
              <XClose />
            </SvgIcon>
          </IconButton>
        )}
        {Slots.Content && <Slots.Content {...slotProps?.Content} />}
      </ScrollbarRoot>
    </Drawer>
  )
}

export function useCssVars(color: NavColor): Record<string, string> {
  const theme = useTheme()

  return useMemo((): Record<string, string> => {
    switch (color) {
      // Blend-in and discrete have no difference on mobile because
      // there's a backdrop and differences are not visible
      case 'blend-in':
      case 'discrete':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400]
          }
        } else {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.text.primary,
            '--nav-logo-border': theme.palette.neutral[100],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.text.secondary,
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900]
          }
        }

      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400]
          }
        } else {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400]
          }
        }

      default:
        return {}
    }
  }, [theme, color])
}

export { MobileNav }
