import {
  Box,
  ClickAwayListener,
  Tooltip as MuiTooltip,
  tooltipClasses
} from '@mui/material'
import { isEmpty } from 'lodash'
import { Fragment, useState } from 'react'

let timeout: NodeJS.Timeout | undefined = undefined

export const QuantityColor = (props) => {
  const { index, colors, labels, align } = props

  const color = colors[index]
  const label = labels[index]
  const title = undefined
  const length = colors.length

  const firstColor = index == 0
  const lastColor = index === colors.length - 1
  const onlyColor = colors.length === 1

  const firstLabel = index === 0
  const lastLabel = index === labels.length - 1

  const left = `calc((100% / ${length}) * ${index})`
  const width = `calc(100% / ${length})`
  const halfWidth = `calc((100% / ${length}) / 1.5)`

  const [open, setOpen] = useState(false)

  const hideDelay = (duration) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      setOpen(false)
    }, duration)
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
    hideDelay(2000)
  }

  return (
    <Fragment>
      {!isEmpty(color) && (
        <Tooltip
          title={title}
          open={open}
          onClose={handleTooltipClose}
        >
          <Box
            onClick={handleTooltipOpen}
            data-color={color}
            sx={[
              {
                backgroundColor: color,
                height: '100%',
                width
              },
              firstColor && {
                borderTopLeftRadius: (theme) => theme.shape.borderRadius * 0.5,
                borderBottomLeftRadius: (theme) =>
                  theme.shape.borderRadius * 0.5
              },
              lastColor && {
                borderTopRightRadius: (theme) => theme.shape.borderRadius * 0.5,
                borderBottomRightRadius: (theme) =>
                  theme.shape.borderRadius * 0.5
              },
              onlyColor && {
                borderRadius: (theme) => theme.shape.borderRadius * 0.1
              }
            ]}
          />
        </Tooltip>
      )}

      {(firstLabel || lastLabel) && (
        <Box
          data-label={label}
          sx={[
            {
              fontSize: '0.875rem',
              fontWeight: 'semibold',
              zIndex: 1,
              textAlign: 'center',
              position: 'absolute',
              top: 'calc(100% + 8px)',
              left,
              userSelect: 'text'
            },

            align === 'left' &&
              firstLabel && {
                textAlign: 'left'
              },

            align === 'left' &&
              !firstLabel && {
                transform: 'translateX(-50%)'
              },

            align === 'left' &&
              lastLabel && {
                textAlign: 'right',
                transform: 'translateX(-100%)'
              },

            align === 'left' && {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: halfWidth
            },

            align === 'center' && {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width,
              textAlign: 'center',
              paddingRight: 'unset',
              px: '1rem'
            }
          ]}
        >
          {label}
        </Box>
      )}
    </Fragment>
  )
}

const Tooltip = (props) => {
  const { title, open, onClose, children } = props

  return (
    <ClickAwayListener onClickAway={onClose}>
      <MuiTooltip
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement='top'
        title={title}
        open={open}
        onClose={onClose}
        PopperProps={{
          disablePortal: true,
          sx: {
            [`& .${tooltipClasses.tooltipPlacementTop}`]: {
              mb: '16px !important'
            }
          }
        }}
      >
        {children}
      </MuiTooltip>
    </ClickAwayListener>
  )
}
