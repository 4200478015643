import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isEmpty, forEach, find } from 'lodash'
import { isMapstackProperty } from 'utils'
import { useSelector } from '../'

export const initialState = {
  mapData: {},
  selectedFeature: {}
}

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialState,
  reducers: {
    setMapData: (state, action) => {
      state.mapData = action.payload
    },

    setSelectedFeature: (state, action) => {
      state.selectedFeature = action.payload
    },

    clearSelectedFeature: (state) => {
      state.selectedFeature = {}
    }
  }
})

export const { setMapData, setSelectedFeature, clearSelectedFeature } =
  mapSlice.actions

const selectMap = (state) => state.map.mapData
const selectProperties = (state) => state.map.mapData?.properties || []
const selectSelectedFeature = (state) => state.map.selectedFeature
const selectSelectedFeatureId = (state) =>
  state.map.selectedFeature?.properties?.msuuid || ''

const selectFeatureInfo = createSelector(
  [selectProperties, selectSelectedFeature],
  (properties, selectedFeature) => {
    if (isEmpty(selectedFeature)) return {}

    const { id } = selectedFeature

    const featureProperties: any[] = []

    forEach(selectedFeature.properties, (value, name) => {
      if (isMapstackProperty(name)) return

      const property = find(properties, (p) => p.name === name)
      if (!property) return

      featureProperties.push({
        ...property,
        value
      })
    })

    return { id, properties: featureProperties }
  }
)

export const useMap = () => useSelector(selectMap)
export const useSelectedFeature = () => useSelector(selectSelectedFeature)
export const useFeatureInfo = () => useSelector(selectFeatureInfo)
export const useSelectedFeatureId = () => useSelector(selectSelectedFeatureId)

export default mapSlice.reducer
