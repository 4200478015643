import { NavAvatar as View } from 'ui-components'
import { useAuth } from 'user-auth'

import { NavAvatarUser } from './NavAvatarUser'
import { UserMenuList } from './UserMenuList'
import { WorkspaceAvatar } from './WorkspaceAvatar'
import { WorkspaceInput } from './WorkspaceInput'
import { WorkspaceMenuList } from './WorkspaceMenuList'

export function NavAvatar() {
  const { authenticated, ...rest } = useAuth()

  if (!authenticated) return

  return (
    <View
      {...rest}
      slots={{
        WorkspaceAvatar,
        NavAvatarUser,
        WorkspaceInput,
        WorkspaceMenuList,
        UserMenuList
      }}
    />
  )
}
