import { FC, ReactNode } from 'react'
import { MapstackLoadingBackdrop, Unauthorized } from 'ui-components'

import { useAuth } from 'user-auth/hooks/useAuth'

export const StaffLoginRequired: FC<{
  children: ReactNode
}> = ({ children }) => {
  const { initialized, isStaff } = useAuth()

  if (!initialized) return <MapstackLoadingBackdrop text='Redirecting...' />

  if (!isStaff) return <Unauthorized />

  return children
}
