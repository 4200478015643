import {
  Box,
  Paper,
  Stack,
  alpha,
  useMediaQuery,
  useTheme
} from '@mui/material'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import BrandLogo from '~ui-components/components/atoms/BrandLogo'
import AddMapAction from '~ui-components/components/molecules/AddMapAction'
import { MapProfile } from '~ui-components/components/organisms/MapProfile'

const defaultSlots = {
  MapProfile,
  AddMapAction
}

const MapNav = (props) => {
  const { sx = {}, slots = {}, slotProps = {}, href = '/' } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('mobile'))

  return (
    <>
      <Paper
        square
        component='header'
        variant='outlined'
        sx={[
          {
            display: 'flex',
            flexDirection: 'row',
            zIndex: 'mapHeader',
            position: 'relative',
            width: '100%',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            p: 3,
            backgroundColor: alpha(theme.palette.background.paper, 0.9),
            [theme.breakpoints.down('mobile')]: {
              p: 1,
              backgroundColor: theme.palette.background.paper
            }
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
      >
        <Box
          flex={1}
          display='flex'
          alignItems='center'
        >
          <Box
            component={NextLink}
            href={href}
            data-testid='AppNavigationLogo'
            sx={{ all: 'unset', display: 'flex', cursor: 'pointer' }}
          >
            <BrandLogo
              variant='logoCopy'
              size='md'
            />
          </Box>
        </Box>

        <Stack
          direction='row'
          alignItems='center'
          sx={{
            '& .AddMapIconButton': {
              mr: 2
            }
          }}
        >
          <Slots.AddMapAction {...slotProps?.AddMapAction} />

          <Slots.MapProfile
            variant={mobile ? 'mobile' : 'desktop'}
            {...slotProps?.MapProfile}
          />
        </Stack>
      </Paper>
    </>
  )
}

MapNav.propTypes = {
  slots: PropTypes.object,
  slotProps: PropTypes.object
}

export { MapNav }
export default MapNav
