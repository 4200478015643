import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  Stack,
  SvgIcon,
  TextField,
  Tooltip
} from '@mui/material'
import { Link } from '@phosphor-icons/react'
import { Field, FieldArray, Formik, Form as FormikForm } from 'formik'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import {
  Body,
  Card,
  Footer,
  Head,
  Layout,
  Left,
  Right
} from './UserOnboardTellUs'

const validate = (value) => {
  if (!value.length) return
  let errorMessage
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    errorMessage = 'Invalid email address'
  }
  return errorMessage
}

export function UserOnboardInvite(props) {
  const { slots = {} } = props

  const Slots = {
    Footer,
    Card,
    Form,
    WorkspaceInviteLinkInput,
    ...slots
  }

  const n = 3

  return (
    <Layout>
      <Left>
        <Slots.Form n={n}>
          <Head
            heading='Who do you collabrate with?'
            subheading='Get more done with your team.'
          />

          <Body
            component={Stack}
            spacing={3}
          >
            <FieldArray
              name='to'
              render={(arrayHelpers) => (
                <>
                  {Array.from({ length: n }).map((_, index) => (
                    <Field
                      key={`EmailInput-${index}`}
                      name={`to.${index}`}
                      validate={validate}
                    >
                      {({ field, meta }) => (
                        <EmailInput
                          {...field}
                          {...(field.value !== '' && meta.error
                            ? {
                                error: meta.error,
                                helperText: meta.error
                              }
                            : {})}
                        />
                      )}
                    </Field>
                  ))}
                </>
              )}
            ></FieldArray>

            <Slots.WorkspaceInviteLinkInput />

            <Tooltip
              title='Credit will be awarded once collabrators sign up and validate their email address. Credit can be applied toward paid plans.'
              placement='right'
            >
              <Alert
                icon={false}
                severity='success'
                sx={{
                  borderRadius: 1,
                  width: 'fit-content',
                  fontWeight: 600
                }}
              >
                Earn $20 in credit per invite!
              </Alert>
            </Tooltip>
          </Body>

          <Slots.Footer progress={100} />
        </Slots.Form>
      </Left>

      <Right>
        <Slots.Card />
      </Right>
    </Layout>
  )
}

export function EmailInput(props) {
  return (
    <TextField
      variant='outlined'
      placeholder='coworker@mapstack.io'
      InputLabelProps={{
        shrink: true
      }}
      {...props}
    />
  )
}

export const Form = forwardRef((props: any, ref: any) => {
  const { n, children, ...rest } = props
  return (
    <Formik
      innerRef={ref}
      initialValues={{
        to: Array.from({ length: n }).map(() => '')
      }}
      {...rest}
    >
      <Stack
        component={FormikForm}
        spacing={12}
        sx={{
          height: '100%'
        }}
      >
        {children}
      </Stack>
    </Formik>
  )
})

export function WorkspaceInviteLinkInput(props) {
  const { value, slotProps } = props

  const timeout = useRef<NodeJS.Timeout>()
  const [copied, setCopied] = useState(false)
  const [, copy] = useCopyToClipboard()

  const handleClick = () => {
    slotProps?.Button?.onClick()
    copy(value)
    setCopied(true)
    timeout.current = setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  return (
    <FormControl>
      <FormLabel>Invite link for the workspace</FormLabel>
      <Stack
        direction='row'
        spacing={1}
        sx={{ mt: 1 }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <SvgIcon
                component={Link}
                weight='bold'
                inheritViewBox
                sx={{
                  width: 18,
                  height: 18,
                  mr: 1
                }}
              />
            )
          }}
          variant='outlined'
          value={value}
          placeholder='https://mapstack.io/xxxx-xxxx-xxxx'
          InputLabelProps={{
            shrink: true
          }}
          sx={{ flex: 1 }}
          disabled
        />
        <Button
          variant='outlined'
          color='primary'
          onClick={handleClick}
          sx={{
            width: 100
          }}
        >
          {copied ? 'Copied' : 'Copy link'}
        </Button>
      </Stack>
    </FormControl>
  )
}
