import { atom } from 'jotai'

export const sessionAtom = <T>(key, initialValue: T) => {
  const getInitialValue = () => {
    if (typeof window === 'undefined') return initialValue
    const item = sessionStorage.getItem(key)
    if (item == null) return initialValue
    return JSON.parse(item) as T
  }
  const baseAtom = atom(getInitialValue())
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === 'function' ? update(get(baseAtom)) : update
      set(baseAtom, nextValue)

      if (typeof window === 'undefined') return
      sessionStorage.setItem(key, JSON.stringify(nextValue))
    }
  )
  return derivedAtom
}
