import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { ArrowSquareUp, ListChecks } from '@phosphor-icons/react'
import { useState } from 'react'
import {
  PlanCard,
  PlanIncludes,
  PlanSimplePrice
} from '~ui-components/components/templates/Pricing/Section.1'

import {
  EnterprisePlanCard,
  PlanIcon,
  PlanUpgradeButton
} from '~ui-components/components/templates/Pricing/Section.1'
import { PlanComparisonTable } from '~ui-components/components/templates/Pricing/Section.2'
import { PLANS } from './plans'

export function UpgradeTab(props) {
  const { onUpgrade, isLoading } = props

  const [period, setPeriod] = useState<string>('yearly')

  const getActiveStyle = (value: string) => {
    return value === period
      ? {
          color: 'primary' as const,
          variant: 'contained' as const
        }
      : {}
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { period = '' } = event.currentTarget.dataset
    setPeriod(period)
  }

  return (
    <>
      <Stack spacing={4}>
        <Card>
          <CardHeader
            title={
              <Stack>
                <Typography
                  variant='next:h6'
                  fontWeight={700}
                >
                  Upgrade your account
                </Typography>
                <Typography
                  variant='next:subtitle2'
                  color='text.secondary'
                >
                  You upgrade, downgrade or cancel at any time.
                </Typography>
              </Stack>
            }
            avatar={
              <Avatar>
                <SvgIcon
                  inheritViewBox
                  component={ArrowSquareUp}
                  weight='duotone'
                />
              </Avatar>
            }
          />
          <CardContent>
            <Stack spacing={4}>
              <ButtonGroup>
                <Button
                  {...getActiveStyle('monthly')}
                  data-period='monthly'
                  onClick={handleClick}
                >
                  Pay monthly
                </Button>
                <Button
                  {...getActiveStyle('yearly')}
                  data-period='yearly'
                  onClick={handleClick}
                >
                  Pay yearly (big discount)
                </Button>
              </ButtonGroup>

              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
                  gap: 4
                }}
              >
                <PlanCard
                  sx={{ minWidth: '420px' }}
                  icon={<PlanIcon />}
                  highlight={PLANS.teamYearly.highlight}
                  type='team'
                  description={PLANS.teamYearly.description}
                  price={
                    period === 'yearly' ? (
                      <PlanSimplePrice
                        currency={PLANS.teamYearly.currency}
                        fee={PLANS.teamYearly.price}
                        feeDescription='per member / year'
                      />
                    ) : (
                      <PlanSimplePrice
                        currency={PLANS.teamMonthly.currency}
                        fee={PLANS.teamMonthly.price}
                        feeDescription='per member / month'
                      />
                    )
                  }
                  action={
                    <PlanUpgradeButton
                      color='primary'
                      data-period={period}
                      data-plan='starter'
                      onClick={() => {
                        const { id, name } =
                          period === 'yearly'
                            ? PLANS.teamYearly
                            : PLANS.teamMonthly
                        onUpgrade?.(id, name, period)
                      }}
                    />
                  }
                  includes={
                    <PlanIncludes
                      title={PLANS.teamYearly.includesTitle}
                      items={PLANS.teamYearly.includes}
                    />
                  }
                />
                <PlanCard
                  icon={<PlanIcon />}
                  type='business'
                  description={PLANS.businessYearly.description}
                  price={
                    period === 'yearly' ? (
                      <PlanSimplePrice
                        currency={PLANS.businessYearly.currency}
                        fee={PLANS.businessYearly.price}
                        feeDescription='per member / year'
                      />
                    ) : (
                      <PlanSimplePrice
                        currency={PLANS.businessMonthly.currency}
                        fee={PLANS.businessMonthly.price}
                        feeDescription='per member / month'
                      />
                    )
                  }
                  action={
                    <PlanUpgradeButton
                      data-period={period}
                      data-plan='business'
                      onClick={() => {
                        const { id, name } =
                          period === 'yearly'
                            ? PLANS.businessYearly
                            : PLANS.businessMonthly
                        onUpgrade?.(id, name, period)
                      }}
                    />
                  }
                  includes={
                    <PlanIncludes
                      title={PLANS.businessYearly.includesTitle}
                      items={PLANS.businessYearly.includes}
                    />
                  }
                />
                <EnterprisePlanCard icon={<PlanIcon />} />
              </Box>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title={
              <Stack>
                <Typography
                  variant='next:h6'
                  fontWeight={700}
                >
                  Compare all features
                </Typography>
                <Typography
                  variant='next:subtitle2'
                  color='text.secondary'
                >
                  A summary of features by plan
                </Typography>
              </Stack>
            }
            avatar={
              <Avatar>
                <SvgIcon
                  inheritViewBox
                  component={ListChecks}
                />
              </Avatar>
            }
          />
          <CardContent>
            <PlanComparisonTable hideAction />
          </CardContent>
        </Card>
      </Stack>
    </>
  )
}
