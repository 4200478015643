import { Stack, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import { GearSix as SettingsIcon } from '@phosphor-icons/react'
import * as React from 'react'

import DotsThreeVertical from '@untitled-ui/icons-react/build/esm/DotsVertical'

interface MapInfoHeaderProps {
  title: string
}

export const MapInfoHeader = (props: MapInfoHeaderProps) => {
  const { title } = props

  return (
    <Stack
      sx={{ flex: 1 }}
      alignItems='center'
      direction='row'
      spacing={1}
    >
      <Typography
        sx={{ flex: 1, pr: 2 }}
        variant='h6'
      >
        {title}
      </Typography>
    </Stack>
  )
}
