import { LoadingButton } from '@mui/lab'

export function CreateMapButton(props) {
  return (
    <LoadingButton
      {...props}
      variant='contained'
      color='primary'
    >
      Create map
    </LoadingButton>
  )
}
