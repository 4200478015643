import { signIn } from 'aws-amplify/auth'
import { useFormik } from 'formik'
import { SignInEmailPasswordForm as View } from 'ui-components'
import * as yup from 'yup'

import { useAtom } from 'user-auth/libs/jotai'
import { atoms } from 'user-auth/states/form'

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  password: yup.string().min(8).required('Password is required')
})

export function SignInEmailPasswordForm() {
  const [, setEmail] = useAtom(atoms.email)
  const [, setPassword] = useAtom(atoms.password)

  const form = useFormik({
    validationSchema: schema,
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: async (values) => {
      const { email, password } = values

      setEmail(email)
      setPassword(password)

      try {
        await signIn({
          username: email,
          password
        })

        go('/sign-in/callback')

        return
      } catch (error) {
        console.warn(error)
      }
    }
  })

  const go = (path) => {
    window.location.href = path
  }

  return (
    <View
      slotProps={{
        Form: {
          component: 'form',
          onSubmit: form.handleSubmit
        },
        EmailInput: form.getFieldProps({ name: 'email', type: 'email' }),
        PasswordInput: form.getFieldProps({
          name: 'password',
          type: 'password'
        }),
        Button: {
          loading: form.isSubmitting,
          disabled: !form.isValid
        }
      }}
    />
  )
}
