import { IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  DotsSixVertical as DragIcon,
  Eye as EyeIcon,
  EyeSlash as EyeSlashIcon,
  Gear as SettingIcon
} from '@phosphor-icons/react'
import { DEFINED_FIELD_OPTIONS } from '~ui-components/components/atoms/PrimaryFieldOptions'

export const PropertyItem = (props) => {
  const {
    property,
    listeners,
    setActivatorNodeRef,
    handleSetVisible,
    handleSettingClick
  } = props
  const {
    name,
    options: { fieldType },
    visible = true
  } = property

  const Icon = DEFINED_FIELD_OPTIONS.find(
    (option) => option.value === fieldType
  )?.icon

  return (
    <Stack
      spacing={1}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{ opacity: visible ? 1 : 0.5 }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
      >
        <IconButton
          sx={{ cursor: 'grab' }}
          ref={setActivatorNodeRef}
          {...listeners}
        >
          <DragIcon size={24} />
        </IconButton>
        {Icon && <Icon size={24} />}
        <Typography variant='subtitle1'>{name}</Typography>
      </Stack>
      <Stack
        direction={'row'}
        gap={1}
      >
        <IconButton onClick={() => handleSetVisible(property?.id)}>
          {visible ? (
            <EyeIcon
              weight='bold'
              size={24}
            />
          ) : (
            <EyeSlashIcon />
          )}
        </IconButton>
        <IconButton
          disabled={!visible}
          onClick={() => handleSettingClick(property)}
        >
          <SettingIcon
            weight='bold'
            size={24}
          />
        </IconButton>
      </Stack>
    </Stack>
  )
}
