import { EmailForm as Form } from 'ui-components'

import { useAtom } from 'user-auth/libs/jotai'
import { atoms } from 'user-auth/states/form'

export function EmailForm() {
  const [, setForm] = useAtom(atoms.form)
  const [email, setEmail] = useAtom(atoms.email)

  const handleChange = (event) => {
    setEmail(event.target.value)
  }

  const handleClick = () => {
    setForm('register')
  }

  return (
    <Form
      slotProps={{
        EmailInput: {
          onChange: handleChange
        },
        Button: {
          disabled: !validate(email),
          onClick: handleClick
        }
      }}
    />
  )
}

const validate = (value) => {
  if (!value.length) return false
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}
