import {
  Box,
  Button,
  Chip,
  Container,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { Buildings, Check, Stack as StackIcon } from '@phosphor-icons/react'
import NextLink from 'next/link'
import React from 'react'
import { getSignInLink, hashMD5 } from 'utils'

import { PLANS } from '~ui-components/components/molecules/UpgradeTab/plans'

import ringEffect from '~ui-components/utilities/ringEffect'

export function Section1() {
  return (
    <Container
      maxWidth='xl'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 8,
        gap: 8
      }}
    >
      <Typography
        variant='next:h2'
        component='h1'
        fontWeight={700}
        textAlign='center'
      >
        Simple, Transparent Pricing for Every Business
      </Typography>

      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 4,
          '> *': {
            flex: 1,
            minWidth: 320,
            [theme.breakpoints.up('md')]: {
              maxWidth: '320px'
            }
          }
        })}
      >
        <FreePlanCard />
        <TeamPlanCard />
        <BusinessPlanCard />
        <EnterprisePlanCard />
      </Box>
    </Container>
  )
}

export function FreePlanCard(props) {
  return (
    <PlanCard
      type='free'
      description={PLANS.free.description}
      price={<PlanPrice annualFee='free' />}
      action={<PlanActionButton>Try for free</PlanActionButton>}
      includes={
        <PlanIncludes
          title={PLANS.free.includesTitle}
          items={PLANS.free.includes}
        />
      }
      {...props}
    />
  )
}

export function TeamPlanCard(props) {
  const { period, highlight } = props

  let plan = PLANS.teamMonthly

  if (period === 'monthly') {
    plan = PLANS.teamMonthly
  }

  if (period === 'yearly') {
    plan = PLANS.teamYearly
  }

  return (
    <PlanCard
      type='team'
      highlight={highlight || plan.highlight}
      description={plan.description}
      price={
        <PlanPrice
          currency={plan.currency}
          annualFee={plan.price}
          annualFeeDescription='Per seat/month billed annually'
          monthlyFee={plan.price}
          monthlyFeeDescription='billed monthly'
        />
      }
      action={<PlanActionButton color='primary'>Try for free</PlanActionButton>}
      includes={
        <PlanIncludes
          title={plan.includesTitle}
          items={plan.includes}
        />
      }
      {...props}
    />
  )
}

export function BusinessPlanCard(props) {
  const { period } = props

  let plan = PLANS.businessMonthly

  if (period === 'monthly') {
    plan = PLANS.businessMonthly
  }

  if (period === 'yearly') {
    plan = PLANS.businessYearly
  }

  return (
    <PlanCard
      type='business'
      description={plan.description}
      price={
        <PlanPrice
          currency='$'
          annualFee={plan.price}
          annualFeeDescription='Per seat/month billed annually'
          monthlyFee={plan.price.toFixed(2)}
          monthlyFeeDescription='billed monthly'
        />
      }
      action={
        <PlanActionButton onClick={() => {}}>Try for free</PlanActionButton>
      }
      includes={
        <PlanIncludes
          title={plan.includesTitle}
          items={plan.includes}
        />
      }
      {...props}
    />
  )
}

export function EnterprisePlanCard(props) {
  return (
    <PlanCard
      type='enterprise'
      description={PLANS.enterprise.description}
      price={
        <PlanPrice
          annualFee={
            <Stack
              direction='row'
              alignItems='center'
              gap={1}
            >
              <Buildings
                weight='duotone'
                size='5rem'
              />
              <Typography
                variant='next:subtitle2'
                component='p'
                fontWeight={600}
                color='text.secondary'
                lineHeight={1.2}
              >
                {PLANS.enterprise.price}
              </Typography>
            </Stack>
          }
        />
      }
      action={
        <PlanActionButton href='mailto:support@mapstack.io'>
          Contact sales
        </PlanActionButton>
      }
      includes={
        <PlanIncludes
          title={PLANS.enterprise.includesTitle}
          items={PLANS.enterprise.includes}
        />
      }
      {...props}
    />
  )
}

export function PlanCard(props) {
  const {
    variant = 'elevation',
    highlight,
    icon,
    type,
    label,
    description,
    price,
    action,
    includes
  } = props

  return (
    <Stack
      sx={[
        { position: 'relative', borderRadius: 2 },
        (theme) =>
          highlight
            ? ringEffect({
                ringColor: theme.palette.primary.main,
                ringWidth: '3px',
                borderRadius: 2
              })
            : {
                mt: '42px'
              }
      ]}
    >
      {highlight && (
        <Box
          px={2}
          height={42}
          alignContent='center'
          bgcolor='primary.main'
          sx={(theme) => ({
            borderTopLeftRadius: theme.shape.borderRadius * 2,
            borderTopRightRadius: theme.shape.borderRadius * 2
          })}
        >
          <Typography
            variant='next:subtitle1'
            color='primary.contrastText'
            fontWeight={600}
          >
            {highlight}
          </Typography>
        </Box>
      )}

      <Stack
        component={Paper}
        variant={variant}
        sx={[
          variant === 'elevation' && {
            boxShadow: 8
          },
          highlight && { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
          {
            gap: 4,
            p: 3,
            borderRadius: 2,
            height: '100%'
          }
        ]}
      >
        {icon}
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
        >
          <Typography
            variant='next:h5'
            fontWeight={700}
            textTransform='capitalize'
          >
            {type}
          </Typography>
          {label && (
            <Chip
              label={label}
              color='primary'
              size='small'
            />
          )}
        </Stack>
        <Typography
          variant='next:subtitle1'
          component='p'
          height='80px'
        >
          {description}
        </Typography>
        {price}
        {action}
        {includes}
      </Stack>
    </Stack>
  )
}

export function PlanIcon() {
  return (
    <SvgIcon
      inheritViewBox
      component={StackIcon}
      weight='duotone'
      sx={{
        fontSize: 36
      }}
    />
  )
}

export function PlanPrice(props) {
  const {
    currency,
    annualFee,
    annualFeeDescription,
    monthlyFee,
    monthlyFeeDescription
  } = props

  return (
    <Stack direction='column'>
      <Stack
        direction='row'
        gap={1}
        alignItems='center'
      >
        {!React.isValidElement(annualFee) ? (
          <Typography
            variant='next:h1'
            component='h2'
            fontWeight={500}
            fontSize='4rem'
            letterSpacing='tighter'
            textTransform='capitalize'
          >
            {currency}
            {annualFee}
          </Typography>
        ) : (
          annualFee
        )}

        {annualFeeDescription && (
          <Typography
            variant='next:subtitle2'
            component='p'
            fontWeight={600}
            color='text.secondary'
            lineHeight={1.2}
          >
            {annualFeeDescription}
          </Typography>
        )}
      </Stack>

      <Typography
        variant='next:subtitle2'
        component='p'
        fontWeight={600}
        color='text.secondary'
        height={22}
      >
        {currency}
        {monthlyFee} {monthlyFeeDescription}
      </Typography>
    </Stack>
  )
}

export function PlanSimplePrice(props) {
  const { fee, currency, feeDescription } = props

  return (
    <Stack
      direction='row'
      gap={1}
      alignItems='center'
      height={104}
    >
      {!React.isValidElement(fee) ? (
        <Typography
          variant='next:h1'
          component='h2'
          fontWeight={500}
          fontSize='3rem'
          letterSpacing='tighter'
        >
          {currency}
          {fee}
        </Typography>
      ) : (
        fee
      )}

      {feeDescription && (
        <Typography
          variant='next:subtitle2'
          component='p'
          fontWeight={600}
          color='text.secondary'
          lineHeight={1.2}
        >
          {feeDescription}
        </Typography>
      )}
    </Stack>
  )
}

export function PlanActionButton(props) {
  const { children, ...rest } = props
  return (
    <Button
      component={NextLink}
      href={getSignInLink()}
      size='medium'
      color='inherit'
      fullWidth
      disableElevation
      {...rest}
    >
      {children}
    </Button>
  )
}

export function PlanUpgradeButton(props) {
  return (
    <Button
      variant='contained'
      size='medium'
      color='inherit'
      fullWidth
      disableElevation
      {...props}
    >
      Upgrade
    </Button>
  )
}

export function PlanIncludes(props) {
  const { title, items, ...rest } = props
  return (
    <Stack
      direction='column'
      {...rest}
    >
      <Typography
        variant='next:subtitle1'
        component='p'
      >
        {title}
      </Typography>
      <Stack
        component='ul'
        p={0}
        m={0}
        mt={2}
        gap={1.5}
      >
        {items.map((item, index) => (
          <Stack
            key={`Include-${hashMD5(item)}`}
            component='li'
            direction='row'
            gap={1}
            alignItems='center'
          >
            <Check
              size={18}
              weight='bold'
            />
            <Typography variant='next:subtitle2'>{item}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
