import { ListSubheader, Select, Stack, Typography } from '@mui/material'
import { Option } from '~ui-components/components/atoms/Option'
import { BootstrapInput } from '~ui-components/components/molecules/Visualization/BootstrapInput'

export const Visualization = (props) => {
  const { value, onChange, options = [], slots = {} } = props
  const pointOptions = options.filter((item) => item.parent === 'point')
  const aggregateOptions = options.filter((item) => item.parent === 'aggregate')
  const dataViews = options.filter((item) => item.parent === 'data-views')

  return (
    <>
      <Select
        sx={{ flex: 1 }}
        value={value}
        onChange={onChange}
        input={<BootstrapInput />}
      >
        <ListSubheader>Point maps</ListSubheader>
        {pointOptions.map(
          (option) =>
            option.visible && (
              <Option
                key={option.id}
                value={option.id}
              >
                <Stack
                  direction='row'
                  flex={1}
                  gap={2}
                  alignItems='center'
                >
                  {option.Icon && (
                    <option.Icon
                      weight='bold'
                      size={24}
                    />
                  )}
                  <Typography variant='body2'>{option.label}</Typography>
                </Stack>
              </Option>
            )
        )}
        <ListSubheader>Aggregate maps</ListSubheader>
        {aggregateOptions.map((option) => (
          <Option
            key={option.id}
            value={option.id}
          >
            <Stack
              direction='row'
              flex={1}
              gap={2}
              alignItems='center'
            >
              {option.Icon && (
                <option.Icon
                  weight='bold'
                  size={24}
                />
              )}
              <Typography variant='body2'>{option.label}</Typography>
            </Stack>
          </Option>
        ))}
        <ListSubheader>Data views</ListSubheader>
        {dataViews.map((item) => (
          <Option
            key={item.id}
            value={item.id}
          >
            <Stack
              direction='row'
              flex={1}
              gap={2}
              alignItems='center'
            >
              {item.Icon && (
                <item.Icon
                  weight='bold'
                  size={24}
                />
              )}
              <Typography variant='body2'>{item.label}</Typography>
            </Stack>
          </Option>
        ))}
      </Select>

      {slots.input && slots.input}
    </>
  )
}
