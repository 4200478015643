import {
  Badge,
  Box,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuList,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material'
import { ellipsis } from 'polished'
import { forwardRef, useMemo, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import { DotsHorizontal } from '@untitled-ui/icons-react'

function CategoryColorList(props) {
  const { take: initialTake = 5, legend: legendProp } = props

  const { ref, width } = useResizeObserver()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [legend, ...rest] = useMemo(() => {
    let take = initialTake
    if (width) {
      take =
        Math.floor(width / 76) < initialTake
          ? Math.floor(width / 76) - 1
          : initialTake
    }
    return [legendProp.slice(0, take), ...legendProp.slice(take)]
  }, [width, initialTake, legendProp])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <MenuList
      ref={ref}
      disablePadding
      sx={{
        mt: 1,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(60px, 1fr))'
      }}
    >
      {legend.map((item, index) => {
        return (
          <Tooltip
            key={`CategoryColor-${index}`}
            enterDelay={0}
            leaveDelay={0}
            title={item.label}
          >
            <Item
              label={item.label}
              color={item.value}
              disableTouchRipple
            />
          </Tooltip>
        )
      })}

      {rest.length > 0 && (
        <Box>
          <Item
            label='More'
            count={rest.length}
            icon={
              <SvgIcon sx={{ fontSize: 12 }}>
                <DotsHorizontal />
              </SvgIcon>
            }
            onClick={handleClick}
          />

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            MenuListProps={{
              sx: {
                p: 1
              }
            }}
          >
            {rest.map((item, index) => (
              <Tooltip
                placement='top'
                enterDelay={0}
                leaveDelay={0}
                key={`CategoryColorItem-${index}`}
                title={item.label?.length >= 15 ? item.label : undefined}
                PopperProps={{
                  sx: {
                    zIndex: (theme) => theme.zIndex.tooltip + 9
                  }
                }}
              >
                <MenuItem
                  disableTouchRipple
                  sx={{
                    borderRadius: 0.5,
                    p: 1,
                    gap: 1
                  }}
                >
                  <Box
                    justifyContent='center'
                    alignItems='center'
                    sx={[
                      {
                        width: 16,
                        height: 16,
                        bgcolor: item.value,
                        border: (theme) =>
                          `solid 2px ${theme.palette.neutral[900]}`,
                        borderRadius: '100%'
                      }
                    ]}
                  ></Box>
                  <Typography
                    variant='overline'
                    sx={[ellipsis('16ch')]}
                  >
                    {item.label}
                  </Typography>
                </MenuItem>
              </Tooltip>
            ))}
          </Menu>
        </Box>
      )}
    </MenuList>
  )
}

interface ItemProps extends Partial<MenuItemProps> {
  label: string
  color?: string
  icon?: React.ReactNode
  count?: number
}

const Item = forwardRef((props: ItemProps, ref: React.Ref<HTMLLIElement>) => {
  const { label, color, icon, count, ...rest } = props
  return (
    <Stack
      ref={ref}
      component={MenuItem}
      direction='column'
      alignItems='center'
      position='relative'
      sx={{
        borderRadius: 1,
        gap: 1,
        p: 1
      }}
      {...rest}
    >
      <Badge
        badgeContent={count}
        color='error'
      >
        <Stack
          justifyContent='center'
          alignItems='center'
          sx={[
            {
              width: 20,
              height: 20,
              border: (theme) => `solid 2px ${theme.palette.neutral[900]}`,
              borderRadius: '100%',
              backgroundColor: color || undefined
            }
          ]}
        >
          {icon}
        </Stack>
      </Badge>
      <Typography
        variant='overline'
        sx={[
          ellipsis('fill-available'),
          {
            lineHeight: '1em'
          }
        ]}
      >
        {label}
      </Typography>
    </Stack>
  )
})

export { CategoryColorList }
