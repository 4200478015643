import * as qs from 'qs'
import { baseUrl } from '~utils/functions/baseUrl'
import { slug } from '~utils/functions/slug'

export function getWorkspaceInviteLink(
  workspaceId,
  workspaceName,
  inviteId,
  redirect?
) {
  const origin = baseUrl()
  const query = qs.stringify({ inviteId, redirect })
  // prettier-ignore
  return `${origin}/workspace/${workspaceId}/${slug(workspaceName)}/admin/shared-invite${query ? `?${query}` : ''}`
}
