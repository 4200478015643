import { Avatar, Box, Container, Paper, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'

import fergalCrotty from './fergal-crotty.jpeg'
import upDigital from './up-digital.png'

export function Section3() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <CommentCard
        avatar={fergalCrotty.src}
        companyLogo={upDigital}
        name='Fergal Crotty'
        company='Up Digital'
        comment={
          <i>
            Mapstack transformed how we handle user data. We quickly filter by
            location and interests, and our clients are blown away by the
            interactive maps. It’s a must-have for data-driven services.
          </i>
        }
      />
    </Box>
  )
}

function CommentCard(props) {
  const { avatar, avatarAlt, companyLogo, name, company, comment } = props
  return (
    <Stack
      direction='row'
      component={Paper}
      sx={{
        borderRadius: 4
      }}
      p={4}
      gap={4}
      elevation={8}
    >
      <Stack
        direction='column'
        spacing={4}
      >
        <Typography
          flex={1}
          variant='next:h5'
          letterSpacing='tight'
          alignContent='center'
        >
          {comment}
        </Typography>
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignContent='flex-end'
          spacing={2}
        >
          <Avatar
            src={avatar}
            variant='rounded'
            sx={{ width: 54, height: 54 }}
            alt={`${name} from ${company}`}
          />
          <Stack
            direction='column'
            justifyContent='center'
          >
            <Typography variant='next:subtitle1'>{name}</Typography>
            <Typography variant='next:subtitle2'>{company}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box
        sx={(theme) => ({
          position: 'relative',
          minWidth: 280,
          aspectRatio: '1/1',
          [theme.breakpoints.down(768)]: { display: 'none' }
        })}
      >
        <NextImage
          src={companyLogo}
          fill
          alt={company}
          objectFit='contain'
        />
      </Box>
    </Stack>
  )
}
