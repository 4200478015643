import { Button, Stack, Typography } from '@mui/material'
import { Fragment } from 'react'

import { ApplyFiltersInput } from '~ui-components/components/molecules/ApplyFiltersInput'
import { IncludePropertiesInput } from '~ui-components/components/molecules/IncludePropertiesInput'
import { RemixButton } from '~ui-components/components/molecules/RemixButton'
import { Sidebar } from '~ui-components/components/molecules/Sidebar'
import { WorkspaceSaveToInput } from '~ui-components/components/molecules/WorkspaceSaveToInput'

interface Props {
  open: boolean
  onClose: () => void
  slots?: {
    Form?: any
    ApplyFiltersInput?: any
    IncludePropertiesInput?: any
    WorkspaceSaveToInput?: any
    RemixButton?: any
  }
  slotProps?: {
    Form?: any
    ApplyFiltersInput?: any
    IncludePropertiesInput?: any
    WorkspaceSaveToInput?: any
    RemixButton?: any
  }
}

const defaultSlots = {
  Form: Fragment,
  ApplyFiltersInput,
  IncludePropertiesInput,
  WorkspaceSaveToInput,
  RemixButton
}

function PolygonMapRemixDrawer(props: Props): JSX.Element {
  const { open, onClose, slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Sidebar
      anchor='left'
      title='Map remix'
      onClose={onClose}
      open={open}
      slotProps={{
        backdrop: {
          invisible: true
        }
      }}
      PaperProps={{
        elevation: 24,
        sx: {
          maxWidth: '100%',
          width: 390
        }
      }}
    >
      <Slots.Form
        {...slotProps?.Form}
        onClose={onClose}
      >
        <Stack
          spacing={4}
          p={2}
        >
          <Typography variant='subtitle2'>
            Import a remix of this map to your account.
          </Typography>

          <Slots.ApplyFiltersInput {...slotProps?.ApplyFiltersInput} />

          <Slots.IncludePropertiesInput
            {...slotProps?.IncludePropertiesInput}
          />

          <Slots.WorkspaceSaveToInput {...slotProps?.WorkspaceSaveToInput} />

          <Stack
            direction='row'
            justifyContent='flex-end'
          >
            <Button
              disableElevation
              variant='contained'
              color='inherit'
              sx={{ mr: 1 }}
              onClick={onClose}
            >
              Cancel
            </Button>

            <Slots.RemixButton {...slotProps?.RemixButton} />
          </Stack>
        </Stack>
      </Slots.Form>
    </Sidebar>
  )
}

export { PolygonMapRemixDrawer }
