import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Dialog,
  DialogProps,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { Check as CheckIcon } from '@untitled-ui/icons-react'

interface MapRemixDialogProps extends DialogProps {
  loading?: boolean
  onActionClick: () => void
}

function MapRemixDialog(props: MapRemixDialogProps) {
  const { loading, open, onClose, onActionClick, ...rest } = props

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      onClose={onClose}
      {...rest}
      sx={{
        p: 2
      }}
    >
      <Paper
        component={Stack}
        spacing={2}
        elevation={12}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          sx={{
            backgroundColor: 'success.lightest',
            color: 'success.main',
            width: 60,
            height: 60
          }}
        >
          <SvgIcon fontSize='large'>
            <CheckIcon />
          </SvgIcon>
        </Avatar>

        <Typography variant='h5'>Copy successful</Typography>

        <Typography
          align='center'
          color='text.secondary'
          sx={{ mt: 1 }}
          variant='body2'
        >
          We recommend giving the map a new name to reflect the changes you made
          in the copy, this will allow users to better understand the new map.
        </Typography>

        <LoadingButton
          loading={loading}
          fullWidth
          size='large'
          variant='contained'
          onClick={onActionClick}
        >
          Go to copied map
        </LoadingButton>
      </Paper>
    </Dialog>
  )
}

export { MapRemixDialog }
