import { CreateMapButton } from 'create-map-button'
import { useRouter } from 'next/router'
import { Search as SearchBar } from 'search-box'
import { NavHeader as Layout } from 'ui-components'
import { useAuth } from 'user-auth'

import { Logo } from '../Logo'
import { NavAvatar } from '../NavAvatar'
import { NavCommunity } from '../NavCommunity'
import { NavDrawer } from '../NavDrawer'
import { SignInButton } from '../SignInButton'
import { SignUpButton } from '../SignUpButton'

export const Header = (props) => {
  const { hideSearch: hideSearchProp = false } = props
  const { authenticated } = useAuth()

  const { route } = useRouter()

  // prettier-ignore
  const hideSearchExact = ["/", '/privacy', '/terms'].some((value) => route === value)

  const hideSearchStarWith = [
    '/explore',
    '/workspace',
    '/user',
    '/pricing',
    '/solutions'
  ].some((value) => route.startsWith(value))

  const hideSearch = hideSearchExact || hideSearchStarWith || hideSearchProp

  return (
    <Layout
      hideSearch={hideSearch}
      authenticated={authenticated}
      slots={{
        Logo,
        NavCommunity,
        CreateMapButton,
        SearchBar,
        NavAvatar,
        NavDrawer,
        SignInButton,
        SignUpButton
      }}
    />
  )
}
