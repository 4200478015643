import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { MapstackLoadingBackdrop } from 'ui-components'
import { getCreateMapLink } from 'utils'

import { useGetCurrentUserById } from 'user-auth/hooks/useGetUserById'

export function RedirectCreateMap() {
  const router = useRouter()
  const { data, isSuccess } = useGetCurrentUserById()

  const go = (pathname: string) => {
    window.location.href = pathname
  }

  useEffect(() => {
    if (!isSuccess) return
    if (!data?.defaultWorkspace) return

    go(
      getCreateMapLink({
        workspaceId: data.defaultWorkspace.id,
        workspaceName: data.defaultWorkspace.name
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess])

  return <MapstackLoadingBackdrop text='Redirecting...' />
}
