import { signUp } from 'aws-amplify/auth'
import { useFormik } from 'formik'
import { SignUpEmailPasswordForm as Form } from 'ui-components'
import * as yup from 'yup'

import { useAtom } from 'user-auth/libs/jotai'
import { atoms } from 'user-auth/states/form'

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
})

export function SignUpEmailPasswordForm() {
  const [, setForm] = useAtom(atoms.form)
  const [, setFirstName] = useAtom(atoms.firstName)
  const [, setLastName] = useAtom(atoms.lastName)
  const [, setEmail] = useAtom(atoms.email)
  const [, setPassword] = useAtom(atoms.password)

  const form = useFormik({
    validationSchema: schema,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    onSubmit: async (values) => {
      const { firstName, lastName, email, password } = values

      setFirstName(firstName)
      setLastName(lastName)
      setEmail(email)
      setPassword(password)

      try {
        await signUp({
          username: email,
          password,
          options: {
            userAttributes: {
              given_name: firstName,
              family_name: lastName,
              email,
              picture: ''
            },
            autoSignIn: true
          }
        })

        setForm('confirmSignUp')
      } catch (error) {
        console.warn(error)
      }
    }
  })

  return (
    <Form
      slotProps={{
        Form: {
          component: 'form',
          onSubmit: form.handleSubmit
        },
        FirstnameInput: form.getFieldProps('firstName'),
        LastnameInput: form.getFieldProps('lastName'),
        EmailInput: form.getFieldProps('email'),
        PasswordInput: form.getFieldProps('password'),
        Button: {
          loading: form.isSubmitting,
          disabled: !form.isValid
        }
      }}
    />
  )
}
