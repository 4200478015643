import { Avatar, Fab, SvgIcon } from '@mui/material'
import User01Icon from '@untitled-ui/icons-react/build/esm/User01'
import { usePopover } from '~ui-components/hooks/usePopover'
import { User } from '~ui-components/types/__generated/gql/graphql'

import { NavAvatarDropdown, NavAvatarDropdownUser } from './NavAvatarDropdown'

export type AccountButtonUser = Pick<User, 'avatar'> & NavAvatarDropdownUser

export const UserAvatar = (props) => {
  const { sx, authenticated } = props

  const popover = usePopover<HTMLButtonElement>()

  if (!authenticated) return null

  return (
    <>
      <Fab
        sx={sx}
        aria-label='Profile'
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
      >
        <Avatar
          sx={{ height: 48, width: 48 }}
          src={undefined}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </Fab>

      <NavAvatarDropdown
        anchorEl={popover.anchorRef.current}
        open={popover.open}
        onClose={popover.handleClose}
      />
    </>
  )
}
