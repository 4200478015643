import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Divider,
  InputLabel,
  OutlinedInput,
  Stack,
  StackProps,
  Switch
} from '@mui/material'
import { FC } from 'react'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'

export type PointMapFormProps = StackProps & {
  form: any //ReturnType<typeof useFormik<SavePointMapInput>>
  slots?: any
  properties?: any
  onPropertiesChange?: (properties: any) => void
  onPropertySettingsClick?: (property: any) => void
}

const PointMapForm: FC<PointMapFormProps> = (props) => {
  const {
    form,
    slots,
    properties = [],
    onPropertiesChange,
    onPropertySettingsClick,
    ...rest
  } = props

  return (
    <Scrollbar>
      <Stack
        spacing={2}
        {...rest}
      >
        <Stack spacing={1}>
          <InputLabel>Map Title</InputLabel>
          <OutlinedInput
            name='name'
            type='text'
            value={form?.values?.name}
            autoFocus
          />
        </Stack>
        <Divider />

        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          spacing={3}
        >
          <InputLabel>Download data</InputLabel>
          <Switch
            color='primary'
            edge='start'
            name='isVerified'
            value={'download'}
          />
        </Stack>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant='text'
            color='inherit'
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            loading={form?.isSubmitting}
            disabled={!form?.isValid}
            onClick={() => form?.submitForm()}
            sx={{ alignSelf: 'flex-end' }}
          >
            Save
          </LoadingButton>
        </Box>
      </Stack>
    </Scrollbar>
  )
}

export { PointMapForm }
