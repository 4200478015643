import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  hide,
  offset,
  Placement,
  shift,
  useFloating,
  useInteractions,
  useRole
} from '@floating-ui/react'
import { Paper, Portal, styled, Typography } from '@mui/material'
import { useLayoutEffect, useRef, useState } from 'react'

type Step = {
  target: string
  content: string
  placement?: Placement
  offset?: number
}

type Onboard = {
  disablePortal?: boolean
  open: boolean
  index?: number
  steps?: Step[]
  offset?: number
  placement?: Placement
}

const Arrow = styled(FloatingArrow)({})

export function Onboard(props) {
  const {
    disablePortal = false,
    open: openProp,
    index = 0,
    steps,
    offset: offsetProp = 10,
    placement = 'bottom'
  } = props

  const {
    target,
    content,
    placement: stepPlacement,
    offset: stepOffset
  } = steps?.[index] || {}

  const arrowRef = useRef(null)
  const [open, setOpen] = useState(openProp)

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement: stepPlacement || placement,
    open: open,
    onOpenChange: setOpen,
    middleware: [
      shift(),
      flip(),
      offset(stepOffset || offsetProp),
      arrow({
        element: arrowRef
      }),
      hide()
    ],
    whileElementsMounted: autoUpdate
  })

  const role = useRole(context, {
    role: 'tooltip'
  })

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([role])

  useLayoutEffect(() => {
    setOpen(openProp)
  }, [openProp])

  useLayoutEffect(() => {
    const element = document.querySelector(target) || null
    if (element) refs.setReference(element)
    const shouldOpen = openProp === false ? false : Boolean(element)
    setOpen(shouldOpen)
  }, [target])

  const hidden = middlewareData.hide?.referenceHidden

  return (
    <Portal disablePortal={disablePortal}>
      {open && (
        <Paper
          variant='outlined'
          ref={refs.setFloating}
          sx={[
            hidden ? { display: 'none' } : {},
            {
              px: 1.5,
              py: 1,
              zIndex: (theme) => theme.zIndex.tooltip,
              boxShadow: '0px 1px 18px rgba(0, 0, 0, 0.1)',
              ...floatingStyles
            }
          ]}
          {...getFloatingProps()}
        >
          {typeof content === 'string' && (
            <Typography variant='next:subtitle1'>{content}</Typography>
          )}
          <Arrow
            ref={arrowRef}
            context={context}
            fill='#fff'
          />
        </Paper>
      )}
    </Portal>
  )
}
