import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { TextEditor } from 'ui-components'

interface MapInfoProps {
  content: React.ReactNode
  onOpenAddInfo: () => void
}

export const MapInfo = (props: MapInfoProps) => {
  const { content, onOpenAddInfo = () => {} } = props

  return (
    <Stack
      sx={{ position: 'absolute', overflowY: 'auto', p: 2, height: '100%' }}
    >
      {content && (
        <TextEditor
          value={content}
          readOnly
          initialValue={content}
        />
      )}
      {(content === '' || content === undefined) && (
        <Stack
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Typography
            variant='body1'
            textAlign='center'
          >
            Help your users understand the story behind this map by adding
            details about its purpose, data sources, or key insights.
          </Typography>
          <Button
            onClick={onOpenAddInfo}
            variant='contained'
            color='primary'
          >
            Add info
          </Button>
        </Stack>
      )}
    </Stack>
  )
}
