import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'

const DeleteMapButton = ({ isLoading, onClick, ...props }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirmDelete = async (e) => {
    setOpen(false)
    if (onClick) {
      await onClick(e)
    }
  }

  return (
    <>
      <LoadingButton
        loading={isLoading}
        variant='contained'
        color='error'
        startIcon={
          <SvgIcon sx={{ fontSize: '1.25em !important' }}>
            <XClose />
          </SvgIcon>
        }
        {...props}
        onClick={handleClickOpen}
      >
        Delete Map
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='confirm-delete-dialog-title'
        aria-describedby='confirm-delete-dialog-description'
      >
        <DialogTitle id='confirm-delete-dialog-title'>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-delete-dialog-description'>
            Are you sure you want to delete this map? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            autoFocus
            color='error'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { DeleteMapButton }
export default DeleteMapButton
