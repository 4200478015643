import {
  Box,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { Option } from '~ui-components/components/atoms/Option'
import { AppLayout } from '~ui-components/components/organisms/AppLayout'
import {
  Tag_Types,
  Tag as TagType
} from '~ui-components/types/__generated/gql/graphql'

import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react'
import { getSearchQuery } from 'utils'
import { MaskLink } from '~ui-components/components/atoms/MaskLink'
import { Pagination } from '~ui-components/components/atoms/Pagination'
import { Footer } from '~ui-components/components/organisms/Footer'

type Tag = Pick<TagType, 'id' | 'name' | 'description' | 'count' | 'type'>

const defaultSlots = {
  TagsFilters,
  TagsView,
  TagsPagination: Pagination
}

export function MapTags(props) {
  const { slots } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const Main = (
    <Container sx={{ pt: 8 }}>
      <Stack spacing={4}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={3}
          sx={{ alignItems: 'flex-start' }}
        >
          <Box sx={{ flex: '1 1 auto' }}>
            <Typography variant='h4'>Tags</Typography>
          </Box>
        </Stack>
        <Grid
          container
          spacing={4}
        >
          <Grid xs={12}>
            <Stack spacing={4}>
              <Slots.TagsFilters />
              <Slots.TagsView />
              <Stack alignItems='center'>
                <Slots.TagsPagination />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  )

  return (
    <AppLayout
      slots={{
        ...slots,
        Main,
        Footer
      }}
    />
  )
}

export function TagsFilters(props) {
  const {
    optionValue,
    inputValue,
    onInputChange = () => {},
    onChange = () => {},
    onApply = () => {}
  } = props
  return (
    <Card>
      <Stack
        direction='row'
        spacing={2}
        sx={{ alignItems: 'center', flexWrap: 'wrap', p: 2 }}
      >
        <OutlinedInput
          name='name'
          value={inputValue}
          onChange={onInputChange}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              onApply?.()
            }
          }}
          placeholder='Search'
          startAdornment={
            <InputAdornment position='start'>
              <MagnifyingGlassIcon size={22} />
            </InputAdornment>
          }
          sx={{ flex: '1 1 auto' }}
        />
        <Select
          name='sort'
          onChange={onChange}
          sx={{
            maxWidth: '100%',
            width: '120px'
          }}
          value={optionValue || 'popular'}
        >
          <Option
            sx={{ mx: 1, borderRadius: 1 }}
            value={tagsFiltersOptions.popular}
          >
            Popular
          </Option>
          <Option
            sx={{ mx: 1, borderRadius: 1 }}
            value={tagsFiltersOptions.name}
          >
            Name
          </Option>
          <Option
            sx={{ mx: 1, borderRadius: 1 }}
            value={tagsFiltersOptions.new}
          >
            New
          </Option>
        </Select>
      </Stack>
    </Card>
  )
}

export const tagsFiltersOptions = {
  new: 'new',
  popular: 'popular',
  name: 'name'
}

interface TagsViewProps {
  items: Tag[]
}

export function TagsView(props: TagsViewProps) {
  const { items = [] } = props
  return (
    <>
      {items.length ? (
        <GridView items={items} />
      ) : (
        <Box sx={{ p: 3 }}>
          <Typography
            color='text.secondary'
            sx={{ textAlign: 'center' }}
            variant='body2'
          >
            No items found
          </Typography>
        </Box>
      )}
    </>
  )
}

interface GridViewProps {
  items: Tag[]
}

function GridView(props: GridViewProps) {
  const { items = [] } = props

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)'
        }
      }}
    >
      {Array.from(items.values()).map((item, index) => (
        <ItemCard
          key={item.id}
          item={item}
        />
      ))}
    </Box>
  )
}

interface ItemCardProps {
  item: Tag
}

function ItemCard(props: ItemCardProps) {
  const { item } = props
  return (
    <Card
      sx={(theme) => ({
        position: 'relative',
        transition: 'box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': { boxShadow: theme.shadows[16] }
      })}
    >
      <Stack
        divider={<Divider />}
        spacing={1}
        sx={{ p: 2 }}
      >
        <Box sx={{ display: 'inline-flex', cursor: 'pointer' }}>
          <Chip
            label={getName(item).toLowerCase()}
            size='small'
          />
        </Box>
        <div>
          {(item?.description?.length ?? 0) > 0 && (
            <Typography
              sx={{ cursor: 'pointer' }}
              variant='subtitle2'
            >
              {item.description}
            </Typography>
          )}
          <Typography
            color='text.secondary'
            variant='caption'
          >
            Maps using this tag: {item.count}
          </Typography>
        </div>
      </Stack>
      <MaskLink href={getSearchQuery({ tags: [item.id], sort: false })} />
    </Card>
  )
}

function getName(tag: Tag) {
  const { name, type } = tag
  if (type === Tag_Types.Topic) return name
  if (type === Tag_Types.FeatureType) return `type: ${name}`
  if (type === Tag_Types.CoverageArea) return `place: ${name}`
  if (type === Tag_Types.Category) return `category: ${name}`

  throw Error('Unknown tag type')
}
