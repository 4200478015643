import {
  Box,
  BoxProps,
  Chip,
  Container,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'

import React from 'react'
import { Video } from '~ui-components/components/atoms/Video'
import ringEffect from '~ui-components/utilities/ringEffect'
import { CTAButtons } from './CTAButtons'

// todo: animate sales -> customer -> site -> retail -> incident -> survey

export function Section1() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <Stack
        spacing={6}
        alignItems='center'
      >
        <Stack
          spacing={4}
          alignItems='center'
        >
          <Chip
            label='Instant geocoder & map maker'
            size='medium'
          />
          <Typography
            component='h1'
            variant='next:h2'
            fontWeight={900}
            textAlign='center'
          >
            Turn your{' '}
            <RotateWords
              words={[
                'customer',
                'sales',
                'site',
                'retail',
                'incident',
                'survey'
              ]}
              sx={{
                fontStyle: 'italic',
                px: 0.75
              }}
            />{' '}
            address data into valuable growth insights in seconds.
          </Typography>
        </Stack>

        <Typography
          component='p'
          variant='next:h5'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Confidently make business decisions by instantly mapping your data for
          clear trend and distribution analysis. Create powerful, shareable
          interactive maps—no spreadsheets required.
        </Typography>

        <CTAButtons />

        <Box
          component={Paper}
          variant='elevation'
          elevation={8}
          sx={(theme) => ({
            width: '100%',
            height: '100%',
            maxHeight: '600px',
            aspectRatio: '16/9',
            borderRadius: 2,
            overflow: 'hidden',
            '&:hover': ringEffect({
              ringColor: theme.palette.primary.main,
              ringWidth: '4px',
              borderRadius: 2
            }),
            '--plyr-color-main': theme.palette.primary.main
          })}
        >
          <Video
            muted
            autoplay
            loop={{
              active: true
            }}
            source={{
              type: 'video',
              sources: [
                {
                  src: `${process.env.NEXT_PUBLIC_MS_ASSETS_URL}/public/videos/sales-demo-2.mp4`,
                  type: 'video/mp4'
                }
              ]
            }}
          />
        </Box>
      </Stack>
    </Box>
  )
}

type RotateWordsProps = {
  words: string[]
  delay?: number
} & BoxProps

export function RotateWords(props: RotateWordsProps) {
  const { sx, words, delay, ...rest } = props

  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length)
    }, 2000)
    // Clean up interval on unmount
    return () => clearInterval(interval)
  }, [])
  return (
    <Box
      {...rest}
      sx={[
        {
          display: 'inline-flex',
          textAlign: 'center',
          width: 248,
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          overflow: 'hidden',
          borderBottom: (theme) => `2px solid ${theme.palette.primary.main}`,
          color: (theme) => theme.palette.primary.main
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <AnimatePresence mode='wait'>
        <motion.span
          key={words[index]}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -40 }}
          transition={{ duration: 0.5 }}
          style={{ margin: 0, color: 'inherit' }}
        >
          {words[index]}
        </motion.span>
      </AnimatePresence>
    </Box>
  )
}
