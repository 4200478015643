import { Container, Grid, Skeleton } from '@mui/material'
import { Card, CardProps } from '~ui-components/components/molecules/Card'
import { Tag } from '~ui-components/types/__generated/gql/graphql'

export type SecondaryTagsProps = {
  isLoading: boolean
  tags: any[]
  getKey: (tag: Tag) => string
  getHref: (tag: Tag) => CardProps['href']
  getCover: (tag: Tag) => CardProps['cover']
  getTitle: (tag: Tag) => CardProps['title']
}

export const SecondaryTags = ({
  isLoading,
  tags,
  getKey,
  getHref,
  getCover,
  getTitle
}: SecondaryTagsProps) => {
  return (
    <Container>
      <Grid
        container
        spacing={4}
      >
        {isLoading
          ? new Array(5).fill(null).map((_, i) => (
              <Grid
                key={i}
                item
                xs={12}
                md={2.4}
              >
                <Skeleton sx={{ maxWidth: 'unset' }}>
                  <Card
                    href='https://dummy-url'
                    cover='https://dummy-url'
                    title='Dummy title'
                  />
                </Skeleton>
              </Grid>
            ))
          : tags.map((tag) => (
              <Grid
                key={getKey(tag)}
                item
                xs={12}
                md={2.4}
              >
                <Card
                  href={getHref(tag)}
                  cover={getCover(tag)}
                  title={getTitle(tag)}
                />
              </Grid>
            ))}
      </Grid>
    </Container>
  )
}
