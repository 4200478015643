import { useFilterResults } from '~map-filter-view/states/filter'
import { MapFilterHeader as MapFilterHeaderUI } from 'ui-components'

const MapFilterHeader = () => {
  const filterResults = useFilterResults()

  return <MapFilterHeaderUI filterResults={filterResults} />
}

export { MapFilterHeader }
