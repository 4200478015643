import {
  Alert,
  Box,
  Chip,
  Stack,
  Button,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Hexagon02 } from '@untitled-ui/icons-react'

type Event = {
  type: string
}

type Value = string

interface HexagonSizeProps {
  value?: Value
  onChange?: (event: Event, value: Value) => void
}

const options = ['small', 'medium', 'large']

function HexagonSize(props: HexagonSizeProps) {
  const { value, onChange } = props

  const selectOption = (option) => {
    onChange?.({ type: 'change' }, option)
  }

  const unselectOption = (option) => {
    onChange?.({ type: 'change' }, option)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const alertMessage = isMobile
    ? 'Use two fingers to rotate the map'
    : 'Right click and drag to rotate the map'

  return (
    <Stack spacing={1}>
      <Stack
        alignItems='center'
        direction='row'
        flexWrap='wrap'
        gap={2}
      >
        <ButtonGroup variant='outlined'>
          {options.map((option) => {
            const active = value === option
            return (
              <>
                <Button
                  color='inherit'
                  variant='outlined'
                  size='small'
                  key={`Display:${option}`}
                  data-testid={`Display-${option}`}
                  // label={option}
                  onClick={() =>
                    active ? unselectOption(option) : selectOption(option)
                  }
                  // onDelete={undefined}
                  sx={{
                    // borderColor: 'transparent',
                    // borderRadius: 1.5,
                    // borderStyle: 'solid',
                    borderColor: (theme) => theme.palette.neutral[300],
                    ...(active && {
                      backgroundColor: (theme) => theme.palette.neutral[300]
                      // borderColor: (theme) => theme.palette.neutral[400]
                    })
                  }}
                >
                  {option}
                </Button>
              </>
            )
          })}
        </ButtonGroup>
      </Stack>
    </Stack>
  )
}

export { HexagonSize }
