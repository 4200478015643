import { Box, Container, Paper, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'

import { CTAButtons } from './CTAButtons'
import image1 from './section-2-1.png'
import image2 from './section-2-2.png'

export function Section2() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <Stack
        component={Paper}
        spacing={6}
        alignItems='center'
        elevation={8}
        sx={{
          p: {
            xs: 4,
            sm: 6
          },
          borderRadius: 4
        }}
      >
        <Typography
          component='h2'
          variant='next:h2'
          fontWeight={700}
          textAlign='center'
        >
          From ecommerce sales data to better targeting insights,{' '}
          <Typography
            component='i'
            variant='inherit'
            fontStyle='italic'
            fontWeight={200}
          >
            faster!
          </Typography>
        </Typography>

        <Typography
          component='p'
          variant='next:h6'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Remove wasted time of manual analysis of ad and customer sales data.
          Seamlessly get your data from platforms like Shopify, Adobe Commerce
          and Google Sheets and get AI enhanced insights quickly without needing
          cumbersome and costly enterprise software.
        </Typography>

        <HighlightCard
          image={image1}
          alt='Simplify your workflow'
          title='Simplify your workflow'
          description='Mapstack instantly cleans your address data, applies AI to highlight top-performing regions, and turns everything into interactive maps—saving you time and simplifying sales analysis.'
        />

        <HighlightCard
          direction='row-reverse'
          image={image2}
          alt='No development skills needed'
          title='No development skills needed'
          description={
            <>
              Create your maps with ease with no development knowledge, API
              integrations or mapping experts required.
              <Box py={1} />
              Simply upload or connect your data and see it instantly on an
              interactive map.
            </>
          }
        />

        <HighlightCard
          image={image2}
          alt='Target with Precision'
          title='Target with Precision'
          description={
            <>
              Uncover geographic trends, refine audience segmentation, and
              create high-impact campaigns. Visualize customer hotspots and
              maximize your ROI with location-based insights.
            </>
          }
        />

        <Typography
          component='h3'
          variant='next:h3'
          fontWeight={700}
          textAlign='center'
        >
          Fixed price and unlimited geocodes
        </Typography>

        <Typography
          component='p'
          variant='next:h6'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Low cost, predictable pricing that suits your budget—making it easy to
          get signoff, whether you’re a mid-sized e-commerce team or a large
          enterprise
        </Typography>

        <CTAButtons />
      </Stack>
    </Box>
  )
}

function HighlightCard(props) {
  const { direction = 'row', image, alt, title, description } = props
  return (
    <Stack
      direction={direction}
      flexWrap='wrap'
      gap={8}
      justifyContent='center'
    >
      <Box
        sx={{
          position: 'relative',
          aspectRatio: '1/1',
          width: '100%',
          flex: 1,
          maxWidth: 400,
          maxHeight: 400,
          flexBasis: 'clamp(100%, 400px, 100%)'
        }}
      >
        <NextImage
          src={image}
          fill
          alt={alt}
        />
      </Box>
      <Stack
        flex={1}
        flexBasis='30ch'
        spacing={2}
        justifyContent='center'
      >
        <Typography variant='h2'>{title}</Typography>
        <Typography
          color='text.secondary'
          variant='next:h6'
          letterSpacing='tight'
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  )
}
