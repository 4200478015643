import { Badge, Link, Stack, Typography } from '@mui/material'
import NextLink from 'next/link'
import { getPrivacyLink, getTermsLink } from 'utils'

import { AuthButton } from '~ui-components/components/molecules/AuthButton'
import { AuthFormHeader } from '../FormHeader'

const defaultSlots = {
  Header: AuthFormHeader,
  GoogleButton: AuthButton,
  FacebookButton: AuthButton,
  EmailButton: AuthButton
}

export function LoginForm(props) {
  const { dev, hideEmail = true, slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <>
      <Slots.Header
        title='Log in or sign up in seconds'
        subtitle="Use your email or another service to continue with Mapstack (it's free)!"
      />

      <Stack
        direction='column'
        gap={3}
      >
        <Slots.GoogleButton
          method='google'
          {...slotProps?.GoogleButton}
        />
        <Slots.FacebookButton
          method='facebook'
          {...slotProps?.FacebookButton}
        />
        {!hideEmail ? (
          <Slots.EmailButton
            method='email'
            {...slotProps?.EmailButton}
          />
        ) : null}
        {dev ? (
          <>
            <Badge
              badgeContent='Developer'
              color='primary'
            >
              <Slots.EmailButton
                fullWidth
                method='signInEmailPassword'
                {...slotProps?.SignInEmailPasswordButton}
              />
            </Badge>
            <Badge
              badgeContent='Developer'
              color='primary'
            >
              <Slots.EmailButton
                fullWidth
                method='signUpEmailPassword'
                {...slotProps?.SignUpEmailPasswordButton}
              />
            </Badge>
          </>
        ) : null}
      </Stack>

      <Typography variant='caption'>
        By continuing, you agree to Mapstack&apos;s&nbsp;
        <Link
          color='secondary'
          component={NextLink}
          href={getTermsLink()}
        >
          Terms of Use
        </Link>
        . Read our{' '}
        <Link
          color='secondary'
          component={NextLink}
          href={getPrivacyLink()}
        >
          Privacy Policy.
        </Link>
      </Typography>
    </>
  )
}
