import { LoadingButton } from '@mui/lab'
import { Box, SvgIcon } from '@mui/material'
import { Mail01 } from '@untitled-ui/icons-react'
import React from 'react'

interface AuthButtonProps extends React.ComponentProps<typeof LoadingButton> {
  method: 'default' | 'google' | 'facebook' | 'email'
  text?: string
}

const methods = {
  google: {
    icon: GoogleIcon,
    text: 'Sign in with Google'
  },
  facebook: {
    icon: FacebookIcon,
    text: 'Sign in with Facebook'
  },
  email: {
    icon: Mail01,
    text: 'Continue with Email'
  },
  signInEmailPassword: {
    icon: Mail01,
    text: 'Sign in with Email and Password'
  },
  signUpEmailPassword: {
    icon: Mail01,
    text: 'Sign up with Email and Password'
  },
  default: {
    icon: null,
    text: 'Sign in'
  }
}

const AuthButton = React.forwardRef((props: AuthButtonProps, ref: any) => {
  const { method = 'default', text: textProp, ...rest } = props

  const { icon: Icon, text } = methods[method] || methods.default

  return (
    <LoadingButton
      ref={ref}
      size='large'
      variant='outlined'
      color='inherit'
      sx={{
        fontWeight: 500,
        justifyContent: 'flex-start',
        textAlign: 'center',
        minWidth: 240,
        borderRadius: 99,
        borderColor: (theme) => theme.palette.neutral[300]
      }}
      startIcon={
        Icon ? (
          <SvgIcon>
            <Icon />
          </SvgIcon>
        ) : null
      }
      {...rest}
    >
      <Box flex={1}>{textProp || text}</Box>
    </LoadingButton>
  )
})

export { AuthButton }

function FacebookIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      {...props}
    >
      <g id='surface1'>
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(3.137255%,40%,100%)',
            fillOpacity: 1
          }}
          d='M 47.976562 23.488281 C 47.976562 10.515625 37.234375 0 23.988281 0 C 10.738281 0 0 10.515625 0 23.488281 C 0 34.503906 7.746094 43.746094 18.191406 46.285156 L 18.191406 30.664062 L 13.246094 30.664062 L 13.246094 23.488281 L 18.191406 23.488281 L 18.191406 20.394531 C 18.191406 12.402344 21.886719 8.695312 29.902344 8.695312 C 31.421875 8.695312 34.046875 8.988281 35.117188 9.277344 L 35.117188 15.785156 C 34.550781 15.726562 33.570312 15.699219 32.347656 15.699219 C 28.414062 15.699219 26.894531 17.15625 26.894531 20.949219 L 26.894531 23.488281 L 34.730469 23.488281 L 33.382812 30.664062 L 26.894531 30.664062 L 26.894531 46.804688 C 38.773438 45.398438 47.976562 35.496094 47.976562 23.488281 '
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1
          }}
          d='M 33.382812 30.664062 L 34.730469 23.488281 L 26.894531 23.488281 L 26.894531 20.949219 C 26.894531 17.15625 28.414062 15.699219 32.347656 15.699219 C 33.570312 15.699219 34.550781 15.726562 35.117188 15.785156 L 35.117188 9.277344 C 34.046875 8.988281 31.421875 8.695312 29.902344 8.695312 C 21.886719 8.695312 18.191406 12.402344 18.191406 20.394531 L 18.191406 23.488281 L 13.246094 23.488281 L 13.246094 30.664062 L 18.191406 30.664062 L 18.191406 46.285156 C 20.050781 46.734375 21.988281 46.976562 23.988281 46.976562 C 24.972656 46.976562 25.941406 46.917969 26.894531 46.804688 L 26.894531 30.664062 Z M 33.382812 30.664062 '
        />
      </g>
    </svg>
  )
}

function GoogleIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      {...props}
    >
      <title>Google Logo</title>
      <clipPath id='g'>
        <path d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z' />
      </clipPath>
      <g
        className='colors'
        clipPath='url(#g)'
      >
        <path
          fill='#FBBC05'
          d='M0 37V11l17 13z'
        />
        <path
          fill='#EA4335'
          d='M0 11l17 13 7-6.1L48 14V0H0z'
        />
        <path
          fill='#34A853'
          d='M0 37l30-23 7.9 1L48 0v48H0z'
        />
        <path
          fill='#4285F4'
          d='M48 48L17 24l-4-3 35-10z'
        />
      </g>
    </svg>
  )
}
