import { FC } from 'react'

import { PageLayout as Layout } from '../PageLayout'
import { Section1 } from './Section.1'
import { Section1_2 } from './Section.1.2'
import { Section2 } from './Section.2'
import { Section3 } from './Section.3'
import { Section4 } from './Section.4'
import { Section5 } from './Section.5'
import { Section6 } from './Section.6'

type Props = {
  slots?: any
  slotProps?: any
}

export const SolutionAddressMapping: FC<Props> = (props) => {
  const { slots, slotProps } = props

  return (
    <Layout
      slots={slots}
      slotProps={slotProps}
    >
      <Section1 />
      <Section1_2 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </Layout>
  )
}
