import { Box, Container, Stack, Typography } from '@mui/material'
import { CTAButtons } from './CTAButtons'

export function Section7() {
  return (
    <Container
      component={Box}
      py={8}
    >
      <Stack
        spacing={6}
        alignItems='center'
      >
        <Typography
          component='h2'
          variant='next:h2'
          fontWeight={700}
          textAlign='center'
        >
          Mapstack: effortless territory insights
        </Typography>
        <Typography
          component='p'
          variant='next:h6'
          color='text.secondary'
          textAlign='center'
          maxWidth='80ch'
        >
          Mapstack transforms your manual territory processes into an intuitive,
          data-driven workflow—no technical skills required. Quickly define or
          adjust boundaries, analyze performance, and keep your entire team
          aligned in one streamlined platform.
        </Typography>

        <CTAButtons />
      </Stack>
    </Container>
  )
}
