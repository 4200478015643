import {
  createStore as createJotaiStore,
  useAtomValue as useAtomValue_,
  useAtom as useAtom_
} from 'jotai'
import { createIsolation } from 'jotai-scope'

export let store: ReturnType<typeof createJotaiStore>

export function createStore(): ReturnType<typeof createJotaiStore> {
  store = createJotaiStore()
  return store
}

export const { Provider, useAtomValue, useAtom } = createIsolation() as {
  Provider: any
  useAtomValue: typeof useAtomValue_
  useAtom: typeof useAtom_
}
