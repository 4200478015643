import { Paper, Stack, Typography, IconButton, SvgIcon } from '@mui/material'
import Notification from '~ui-components/components/molecules/Notification'

import RefreshCcw01 from '@untitled-ui/icons-react/build/esm/RefreshCcw01'
import Share07 from '@untitled-ui/icons-react/build/esm/Share07'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'

export const MapFooter = ({
  mapAttributionText = ''
}: {
  mapAttributionText?: string
}) => {
  return (
    <Paper
      component='footer'
      variant='outlined'
      sx={{ px: 2, py: 1 }}
    >
      <Stack
        direction='row'
        spacing={1}
        sx={{ alignItems: 'center' }}
      >
        <Typography
          color='text.secondary'
          variant='next:caption'
          fontWeight={500}
          letterSpacing='tight'
        >
          {mapAttributionText}
        </Typography>
      </Stack>
      {/* <Stack sx={{ position: 'fixed', right: 0, bottom: 0 }}>
        <Notification
          variant='filled'
          severity={'warning'}
          open={true}
          // onClose={handleClose}
          action={
            <NotificationActions
              status={'failed'}
              // onRetryClick={handleRetry}
              // onCloseClick={handleClose}
            />
          }
        >
          Oops, save failed, please try again.
        </Notification>
      </Stack> */}
    </Paper>
  )
}

const NotificationActions = (props) => {
  const { status, onRetryClick, onCloseClick } = props

  switch (status) {
    case 'pending': {
      return (
        <IconButton
          id='ButtonCloseMapSave'
          color='inherit'
          size='small'
          onClick={onCloseClick}
          sx={{
            '.MuiSvgIcon-root': {
              fontSize: '1.25rem'
            }
          }}
        >
          <SvgIcon>
            <XClose />
          </SvgIcon>
        </IconButton>
      )
    }

    case 'failed': {
      return (
        <IconButton
          color='inherit'
          size='small'
          onClick={onRetryClick}
          sx={{
            '.MuiSvgIcon-root': {
              fontSize: '1.25rem'
            }
          }}
        >
          <SvgIcon>
            <RefreshCcw01 />
          </SvgIcon>
        </IconButton>
      )
    }

    case 'ready': {
      return (
        <IconButton
          id='ButtonCloseMapSave'
          color='inherit'
          size='small'
          onClick={onCloseClick}
        >
          <SvgIcon
            sx={{
              fontSize: '1.25rem !important'
            }}
          >
            <XClose />
          </SvgIcon>
        </IconButton>
      )
    }

    default: {
      return null
    }
  }
}
