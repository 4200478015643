import { SvgIcon } from '@mui/material'
import MarkerPin01 from '@untitled-ui/icons-react/build/esm/MarkerPin01'
import { Map01 } from '@untitled-ui/icons-react'
import { NavItem } from '~ui-components/components/molecules/NavItem'

type AggregateMapsProps = {
  value: string
  onChange: (value: any) => void
  properties: any[]
}

export const getSelectedAggregateCategory = (value) => {
  return {
    label: value.name,
    title: value.name,
    value: value.name,
    icon: (
      <SvgIcon>
        <Map01 />
      </SvgIcon>
    )
  }
}

export function AggregateMapsSecondaryCombo(props: AggregateMapsProps) {
  const { properties } = props
  return (
    <NavItem
      title='Aggregate by'
      {...props}
    >
      {properties.map((option) => {
        return (
          <NavItem
            onClick={() => props.onChange(option)}
            key={option.name}
            title={option.name}
            icon={
              <SvgIcon>
                <Map01 />
              </SvgIcon>
            }
          />
        )
      })}
    </NavItem>
  )
}
