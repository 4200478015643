import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material'

interface AccessLevelSettingProps {
  value?: boolean
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => void
}

function AccessLevelSetting(props: AccessLevelSettingProps) {
  const { value = false, onChange } = props

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    await onChange?.(event, newValue)
  }

  return (
    <>
      <Stack
        sx={{ width: '100%' }}
        spacing={2}
      >
        <Typography
          color='text.secondary'
          variant='overline'
        >
          Access type
        </Typography>
        <Box>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={value}
                onChange={handleChange}
              />
            }
            label='Community map (read-only)'
          />
          <Typography variant='body2'>
            Anyone can find and access this map in the community portal.
          </Typography>
        </Box>
      </Stack>
    </>
  )
}

export { AccessLevelSetting }
