import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { MapstackLoadingBackdrop } from 'ui-components'
import { getOnboardLink } from 'utils'

import { useAuth } from 'user-auth/hooks/useAuth'
import { useFederatedAuth } from 'user-auth/hooks/useFederatedAuth'
import { useGetCurrentWorkspace } from 'user-auth/hooks/useGetCurrentWorkspace'

export function RedirectOnboard() {
  const router = useRouter()
  const { gotoSignInPage } = useFederatedAuth()
  const { user, ready, authenticated } = useAuth()
  const { isFetched, data: currentWorkspace } = useGetCurrentWorkspace()

  const go = (pathname: string) => {
    window.location.href = pathname
  }

  useEffect(() => {
    if (!ready) return
    if (!authenticated) return
    if (!user) return
    if (!isFetched) return

    if (!authenticated) {
      gotoSignInPage({ redirect: '/redirect/onboard' })
      return
    }

    go(getOnboardLink(user?.id, currentWorkspace?.id))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, isFetched, user, authenticated])

  return <MapstackLoadingBackdrop text='Redirecting...' />
}
