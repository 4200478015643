import { Stack, Typography } from '@mui/material'
import { Fragment } from 'react'

import { ApplyFiltersInput } from '~ui-components/components/molecules/ApplyFiltersInput'
import { IncludePropertiesInput } from '~ui-components/components/molecules/IncludePropertiesInput'
import { RemixButton } from '~ui-components/components/molecules/RemixButton'
import { WorkspaceSaveToInput } from '~ui-components/components/molecules/WorkspaceSaveToInput'

interface MapRemixDrawerProps {
  slots?: {
    Form?: any
    ApplyFiltersInput?: any
    IncludePropertiesInput?: any
    WorkspaceSaveToInput?: any
    RemixButton?: any
  }
  slotProps?: {
    Form?: any
    ApplyFiltersInput?: any
    IncludePropertiesInput?: any
    WorkspaceSaveToInput?: any
    RemixButton?: any
  }
}

const defaultSlots = {
  Form: Fragment,
  ApplyFiltersInput,
  IncludePropertiesInput,
  WorkspaceSaveToInput,
  RemixButton
}

function MapRemixDrawer(props: MapRemixDrawerProps): JSX.Element {
  const { slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <div>
      <Slots.Form {...slotProps?.Form}>
        <Stack
          spacing={4}
          p={2}
        >
          <Typography variant='subtitle2'>
            Import a copy of this map to your account.
          </Typography>

          <Slots.ApplyFiltersInput {...slotProps?.ApplyFiltersInput} />

          <Slots.IncludePropertiesInput
            {...slotProps?.IncludePropertiesInput}
          />

          <Slots.WorkspaceSaveToInput {...slotProps?.WorkspaceSaveToInput} />

          <Stack
            direction='row'
            justifyContent='flex-end'
          >
            <Slots.RemixButton {...slotProps?.RemixButton} />
          </Stack>
        </Stack>
      </Slots.Form>
    </div>
  )
}

export { MapRemixDrawer }
