import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Portal,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import NextImage from 'next/image'
import { important } from 'polished'
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use'

import { BrandLogo } from '~ui-components/components/atoms'
import freeTrial from './free-trial.png'

export function FreeTrialDialog(props) {
  const { slotProps, ...rest } = props

  const { width, height } = useWindowSize()
  const theme = useTheme()

  return (
    <>
      <Dialog
        {...rest}
        slotProps={{
          backdrop: {
            sx: (theme) => ({
              ...important({
                background: alpha(theme.palette.common.black, 0.3)
              })
            })
          }
        }}
        PaperProps={{
          sx: {
            width: '100%'
          }
        }}
      >
        <Portal>
          <Confetti
            width={width}
            height={height}
            style={{ zIndex: theme.zIndex.drawer }}
          />
        </Portal>

        <Stack
          p={3}
          alignItems='center'
        >
          <BrandLogo variant='logoCopy' />
        </Stack>
        <Divider />
        <Stack
          component={DialogContent}
          sx={{ p: 3 }}
          spacing={3}
        >
          <Typography
            variant='next:h4'
            fontWeight={700}
          >
            Experience all of Mapstack with a 14-day free trail of Team.
          </Typography>

          <Typography
            component='p'
            variant='next:subtitle2'
          >
            We've upgraded you to a free 14-day free trial of the Team plan. Try
            the full range of Mapstack fatures before deciding with plan is
            right for you.
          </Typography>

          <Typography
            component='p'
            variant='next:subtitle2'
          >
            At the end of the free trial, we'll automatically move you to the
            Free plan unless you choose to upgrade.
          </Typography>

          <Box sx={{ textAlign: 'center' }}>
            <NextImage
              src={freeTrial}
              alt='Experience all of Mapstack with a 14-day free trail of Team.'
              width={300}
              height={300}
            />
          </Box>

          <Stack>
            <Button
              variant='contained'
              color='primary'
              size='large'
              {...slotProps?.GetStartedButton}
            >
              Get started
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}
