import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google'

interface GTagProps {
  gtmId: string
  gaId: string
}

export function TagManager(props: GTagProps): JSX.Element {
  const { gtmId, gaId } = props

  return (
    <>
      <GoogleTagManager gtmId={gtmId} />
      <GoogleAnalytics gaId={gaId} />
    </>
  )
}
