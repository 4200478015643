import { atom } from 'jotai'

type Form =
  | 'login'
  //
  | 'register'
  | 'email'
  | 'verify'
  //
  | 'signInEmailPassword'
  | 'signUpEmailPassword'
  | 'confirmSignUp'

export const atoms = {
  form: atom<Form>('login'),
  email: atom<string>(''),
  password: atom<string>(''),
  firstName: atom<string>(''),
  lastName: atom<string>(''),
  code: atom<string>('')
}
