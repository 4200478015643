import { VerifyForm as Form } from 'ui-components'

import { useAtom } from 'user-auth/libs/jotai'
import { atoms } from 'user-auth/states/form'

export function VerifyForm() {
  const [email] = useAtom(atoms.email)

  const handleButtonClick = () => {}

  const handleResendClick = (event) => {
    event.preventDefault()
  }

  return (
    <Form
      email={email}
      slotProps={{
        Button: {
          onClick: handleButtonClick
        },
        ResendLink: {
          onClick: handleResendClick
        }
      }}
    />
  )
}
