import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove
} from '@dnd-kit/sortable'
import { SortableItem } from './SortableItem'
import { PropertyItem } from './PropertyItem'
import { FC, useMemo, useState } from 'react'
import type { Active, UniqueIdentifier } from '@dnd-kit/core'
import { SortableOverlay } from './SortableOverlay'

type SortableListProps = {
  items: any[]
  setItems: (items: any[]) => void
  onClickItemSettings: (item: any) => void
}

export const SortableList: FC<SortableListProps> = ({
  items,
  setItems,
  onClickItemSettings
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const [active, setActive] = useState<Active | null>(null)
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  )

  const handleSetVisible = (id: UniqueIdentifier) => {
    const newItems = items.map((item) =>
      item.id === id ? { ...item, visible: !item?.visible } : item
    )
    setItems(newItems)
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active)
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id)
          const overIndex = items.findIndex(({ id }) => id === over.id)

          setItems(arrayMove(items, activeIndex, overIndex))
        }
        setActive(null)
      }}
      onDragCancel={() => {
        setActive(null)
      }}
    >
      <SortableContext items={items}>
        {items.map((item, index) => (
          <SortableItem
            key={item.id}
            id={index}
            item={item}
            handleSetVisible={handleSetVisible}
            handleSettingClick={onClickItemSettings}
          />
        ))}
        <SortableOverlay>
          {activeItem ? <PropertyItem property={activeItem} /> : null}
        </SortableOverlay>
      </SortableContext>
    </DndContext>
  )
}
