import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  buttonClasses,
  CardHeader,
  Chip,
  Divider,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { Sparkle } from '@phosphor-icons/react'

import 'maplibre-gl/dist/maplibre-gl.css'

import { ellipsis } from 'polished'
import { useState } from 'react'
import MapGl from 'react-map-gl/maplibre'

import { BrandLogo } from '~ui-components/components/atoms/BrandLogo'
import { MapFileInput } from '~ui-components/components/molecules/MapFileInput'

const purposes = [
  {
    label: 'Customer distribution',
    value: 'Customer distribution'
  },
  {
    label: 'Sales performance',
    value: 'Sales performance'
  },
  {
    label: 'Store locations',
    value: 'Store locations'
  },
  {
    label: 'Competitor analysis',
    value: 'Competitor analysis'
  },
  {
    label: 'Project planning',
    value: 'Project planning'
  },
  {
    label: 'Marketing campaign',
    value: 'Marketing campaign'
  },
  {
    label: 'Demograhic insights',
    value: 'Demograhic insights'
  },
  {
    label: 'Asset tracking & logistics',
    value: 'Asset tracking & logistics'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

export function CobuilderMapPurpose(props) {
  const { slots } = props

  const Slots = {
    Footer,
    MapPurposeInput,
    MapFileInput,
    ...slots
  }

  return (
    <>
      <Head heading='Tell us about the purpose of this map' />

      <Body>
        <Slots.MapPurposeInput />
        <MapFileInputForm>
          <Slots.MapFileInput />
        </MapFileInputForm>
      </Body>

      <Slots.Footer />
    </>
  )
}

export function Layout(props) {
  return (
    <Box
      key='CobuilderLayout'
      sx={[
        {
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          backgroundColor: (theme) => theme.palette.primary.light,
          display: 'flex',
          flexDirection: 'row',
          p: 8,
          gap: 8,
          '> :first-child': {
            flex: 1,
            flexBasis: '240px'
          },
          '> :nth-child(2)': {
            flex: 3,
            flexBasis: '600px'
          }
        },
        (theme) => ({
          [theme.breakpoints.down(1200)]: {
            flexDirection: 'column'
          }
        })
      ]}
      {...props}
    />
  )
}

export function Left(props) {
  const { children, ...rest } = props
  return (
    <Stack
      key='CobuilderLeft'
      spacing={4}
      {...rest}
    >
      {children}
    </Stack>
  )
}

export function Right(props) {
  const { children, ...rest } = props
  return (
    <Stack
      key='CobuilderRight'
      sx={{ position: 'relative' }}
      {...rest}
    >
      {children}
    </Stack>
  )
}

export function Head(props) {
  return (
    <Stack spacing={4}>
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
      >
        <SvgIcon
          component={Sparkle}
          weight='duotone'
          inheritViewBox
          sx={{
            color: (theme) => theme.palette.primary.main,
            width: 32,
            height: 32
          }}
        />
        <Typography
          variant='next:subtitle1'
          letterSpacing='tight'
          color='primary'
          fontWeight={600}
          pt={1}
        >
          Mapstack Cobuilder
        </Typography>
      </Stack>

      {props.heading && (
        <Typography
          variant='next:h3'
          fontWeight={700}
          letterSpacing='tight'
        >
          {props.heading}
        </Typography>
      )}

      {props.subheading && (
        <Typography
          variant='next:h6'
          letterSpacing='tight'
        >
          {props.subheading}
        </Typography>
      )}
    </Stack>
  )
}

export function Body(props) {
  return (
    <Stack
      {...props}
      spacing={4}
    />
  )
}

export function Footer(props) {
  const { slots, slotProps } = props

  const Slots = {
    SkipButton: Button,
    ...slots
  }

  return (
    <Stack
      gap={2}
      width='100%'
      direction='row'
      flexWrap='wrap'
      justifyContent='flex-end'
      sx={(theme) => ({
        [theme.breakpoints.down(780)]: {
          flexDirection: 'column-reverse',
          [`.${buttonClasses.root}`]: {
            flex: 1
          }
        }
      })}
    >
      {Slots?.SkipButton && (
        <Slots.SkipButton
          variant='text'
          {...slotProps?.SkipButton}
        >
          Skip
        </Slots.SkipButton>
      )}
      <LoadingButton
        variant='contained'
        color='primary'
        loadingPosition='start'
        sx={[
          slotProps?.ContinueButton?.loading && {
            pl: 5
          }
        ]}
        {...slotProps?.ContinueButton}
      >
        {slotProps?.ContinueButton?.loading
          ? 'Processing'
          : slotProps?.ContinueButton?.text || 'Continue'}
      </LoadingButton>
    </Stack>
  )
}

export function MapPurposeInput(props) {
  const { value: valueProp, onChange } = props
  const [value, setValue] = useState(valueProp)

  return (
    <Stack
      direction='row'
      gap={2}
      flexWrap='wrap'
      component='ul'
      pl={0}
    >
      {purposes.map((purpose) => (
        <Chip
          key={purpose.value}
          label={purpose.label}
          component='li'
          sx={{
            px: 1,
            height: 42,
            borderRadius: 99,
            typography: 'next:subtitle1',
            letterSpacing: 'tight'
          }}
          color={value === purpose.value ? 'secondary' : 'default'}
          onClick={(event) => {
            setValue(purpose.value)
            onChange?.(event, purpose.value)
          }}
        />
      ))}
    </Stack>
  )
}

function MapFileInputForm(props) {
  return (
    <Stack spacing={4}>
      <Typography
        variant='next:subtitle1'
        letterSpacing='tight'
      >
        Import your data containing addresses, postcodes, or other location
        details to get started.
      </Typography>

      {props.children}
    </Stack>
  )
}

export function Map(props) {
  const { slots, children } = props

  const Slots = {
    MapTitle: MapName,
    MapCanvas,
    ...slots
  }

  return (
    <Paper
      key='CobuilderMap'
      className='Map'
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: 2
      }}
      elevation={8}
    >
      <Stack
        direction='column'
        height='100%'
        overflow='hidden'
        borderRadius={2}
      >
        <CardHeader
          sx={{ p: 2 }}
          title={
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              width='fit-content'
            >
              <BrandLogo />
              <Slots.MapName />
            </Stack>
          }
          titleTypographyProps={{
            variant: 'next:h6',
            fontWeight: 600,
            letterSpacing: 'tight'
          }}
        />
        <Divider />
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}
        >
          <Slots.MapCanvas />
          {children}
        </Box>
      </Stack>
    </Paper>
  )
}

export function MapName(props) {
  return (
    <Box
      className='MapTitle'
      component='div'
      sx={[{ width: '100%', minWidth: 100, maxWidth: 100 }]}
      title={props.name}
    >
      <Box
        component='div'
        sx={[ellipsis('80ch')]}
      >
        {props.name || 'Untitled map'}
      </Box>
    </Box>
  )
}

export function MapCanvas() {
  return (
    <MapGl
      reuseMaps
      style={{
        width: '100%',
        height: '100%'
      }}
      mapStyle='https://api.maptiler.com/maps/267bea5c-db1b-4ae2-bf49-b3a20792a2c0/style.json?key=6dkE4jp3UXbczwUW17h4'
    />
  )
}
