import { CreateMapButton } from 'create-map-button'
import { NavMapHeader as Layout } from 'ui-components'
import { useAuth } from 'user-auth'

import { Logo } from '../Logo'
import { NavAvatar } from '../NavAvatar'
import { NavCommunity } from '../NavCommunity'
import { SignInButton } from '../SignInButton'
import { WorkspaceButtonList } from './WorkspaceButtonList'

export function NavMapHeader(props) {
  const { slotProps, ...rest } = props
  const { authenticated } = useAuth()

  return (
    <Layout
      {...rest}
      authenticated={authenticated}
      slots={{
        Logo,
        NavCommunity,
        SignInButton,
        CreateMapButton,
        NavAvatar,
        WorkspaceButtonList
      }}
      slotProps={slotProps}
    />
  )
}
