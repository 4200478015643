import { Masonry } from '@mui/lab'
import { Container, Skeleton } from '@mui/material'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { getMapLink, getMapThumbnailLink } from 'utils'
import { Card, CardProps } from '~ui-components/components/molecules/Card'
import EmptyMapResultFallback from '~ui-components/components/molecules/EmptyMapResultFallback'
import { Map } from '~ui-components/types/__generated/gql/graphql'

export type MapListStackProps = {
  maps: any[]
  loading?: boolean
  getMapAvatar: (map: Map) => CardProps['avatar']
}

export const MapListStack: FC<MapListStackProps> = ({
  maps,
  loading = false,
  getMapAvatar
}) => {
  return (
    <Container maxWidth={false}>
      {loading ? (
        <Masonry
          columns={{ xs: 1, md: 2, lg: 3 }}
          spacing={4}
          sx={{ margin: 'unset' }}
        >
          {new Array(3).fill(null).map((_, i) => (
            <Skeleton
              key={i}
              sx={{ maxWidth: 'unset' }}
            >
              <Card
                href='https://dummy-url'
                cover='https://dummy-url'
                title='Dummy title'
              />
            </Skeleton>
          ))}
        </Masonry>
      ) : !isEmpty(maps) ? (
        <Masonry
          columns={{ xs: 1, md: 2, lg: 3 }}
          spacing={4}
          sx={{ margin: 'unset' }}
          defaultHeight={450}
          defaultColumns={3}
          defaultSpacing={4}
        >
          {maps.map((map) => (
            <Card
              key={map.id}
              href={
                getMapLink({
                  mapId: map.id,
                  mapName: map.name
                })!
              }
              cover={getMapThumbnailLink(map)}
              title={map.name}
              {...(getMapAvatar ? { avatar: getMapAvatar(map) } : {})}
              footer={{
                likes: map.likes ?? 0,
                views: map.views ?? 0
              }}
            />
          ))}
        </Masonry>
      ) : (
        <EmptyMapResultFallback />
      )}
    </Container>
  )
}
