import { Stack, Typography } from '@mui/material'
import { Copy as CopyIcon } from '@phosphor-icons/react'

export const MapRemixHeader = (props) => {
  return (
    <Stack
      alignItems='center'
      direction='row'
      spacing={1}
    >
      <CopyIcon fontSize={28} />
      <Typography variant='h6'>Copy map</Typography>
    </Stack>
  )
}
