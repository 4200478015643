import { SvgIcon } from '@mui/material'
import { LayersThree01 } from '@untitled-ui/icons-react'
import { NavItem } from '~ui-components/components/molecules/NavItem'

type Option = {
  label: string
  value: string
}

type AggregateMapsProps = {
  value: string
  onChange: (value) => void
  options: Option[]
}

export const getSelectedMarkerCategory = (option: Option) => {
  return {
    title: option.label,
    value: option.value,
    icon: <LayersThree01 />
  }
}

export function MarkerCategoryCombo(props: AggregateMapsProps) {
  const { options } = props
  return (
    <NavItem
      title='Group by'
      {...props}
    >
      {options.map((option) => {
        return (
          <NavItem
            onClick={() => props.onChange(getSelectedMarkerCategory(option))}
            key={option.value}
            title={option.label}
            icon={
              <SvgIcon>
                <LayersThree01 />
              </SvgIcon>
            }
          />
        )
      })}
    </NavItem>
  )
}
