import { useRouter } from 'next/router'
import { UserMenuList as View } from 'ui-components'
import { useAuth, useFederatedAuth } from 'user-auth'

export function UserMenuList() {
  const { route } = useRouter()
  const { user } = useAuth()
  const { signOut } = useFederatedAuth()

  const preventRedirectExact = [
    '/workspace/[workspaceId]/[workspaceName]',
    '/user/[userId]/[username]',
    '/map/[mapId]/[...rest]'
  ].some((value) => route === value)

  // required asynchronous function for sign out to work properly
  const handleClick = async () => {
    await signOut({ preventRedirect: preventRedirectExact })
  }

  return (
    <View
      user={user}
      slotProps={{
        SignOutMenuItem: {
          onClick: handleClick
        }
      }}
    />
  )
}
