import bbox from '@turf/bbox'
import centroid from '@turf/centroid'
import { FeatureCollection } from 'geojson'
import { values } from 'lodash'
import { getNanoId } from '~utils/functions/getNanoId'
import { getAllGeojsonFeatureProperties } from '~utils/geojson/getAllGeojsonFeatureProperties'
import { getTypeOfEachProperties } from '~utils/geojson/getTypeOfEachProperties'
import { VISUALISATION } from '~utils/types/VisualisationType'
import { MapData } from '~utils/types/map'

const getType = (type: string) => {
  if (type === 'hidden') return 'text'
  return type
}

const getProperties = (properties, primaryField?, propertyTypes?) => {
  // TODO: Refactor this from user-onboarding to use correct property options
  return properties.map((property) => {
    let options = {}
    if (propertyTypes?.[property.name]) {
      options = {
        options: {
          fieldType: getType(propertyTypes?.[property.name] || property.type)
        }
      }
    }
    return {
      isTitle: primaryField === property.name,
      isDefaultVisualisation: false,
      name: property.name,
      isAggregate: false,
      type: property.type,
      isPercentage: false,
      visualisation: VISUALISATION.NONE,
      visible: propertyTypes?.[property.name] !== 'hidden',
      ...options
    }
  })
}

type InputParameters = {
  id: string
  dataId: string
  workspaceId: string
  name?: string
}

type MappingParameters = {
  primaryField?: string
  propertyTypes?: Record<string, string>
}

export const constructMapData = (
  geojson: FeatureCollection & { ms_metadata?: Record<string, any> },
  input: InputParameters,
  mappings?: MappingParameters
): MapData => {
  const { id, name, workspaceId, dataId } = input
  const { primaryField, propertyTypes } = mappings || {}

  // map geojson features' properties to structured map properties
  const arrayOfProperties = getAllGeojsonFeatureProperties(geojson)
  const geojsonProperties = values(getTypeOfEachProperties(arrayOfProperties))
  const properties = getProperties(
    geojsonProperties,
    primaryField,
    propertyTypes
  )

  return {
    id: id || getNanoId(),
    name: name || 'Default Map Name',
    dataId,
    workspaceId,
    bbox: bbox(geojson),
    centroid: centroid(geojson).geometry.coordinates,
    properties
  }
}
