import { get } from 'lodash'
import { formatFeaturePropertyValue } from 'utils'

const isNotTitle = (title) => (property) => {
  return property.name !== title
}

const isVisible = (property) => {
  return get(property, 'visible', true)
}

const getProperties = (properties, title) => {
  if (typeof properties === 'undefined') return []

  return properties
    .filter(isNotTitle(title))
    .filter(isVisible)
    .map((property) => {
      const value = formatFeaturePropertyValue(property)
      return { name: property.name, value }
    })
}

export { getProperties }
export default getProperties
