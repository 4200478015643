import { useEffect, useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useIsMobile } from '~ui-components/hooks'

const options = ['2D', '3D']

export function DimensionalDisplay({ value, onChange }) {
  const isMobile = useIsMobile()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  useEffect(() => {
    let timer
    if (value === '3D') {
      setTooltipOpen(true)
      timer = setTimeout(() => setTooltipOpen(false), 3000)
    } else {
      setTooltipOpen(false)
    }
    return () => clearTimeout(timer)
  }, [value])

  return (
    <Tooltip
      title={
        isMobile
          ? 'Turn two fingers to rotate, move two fingers up/down to tilt'
          : 'Right-click + drag to tilt and rotate'
      }
      placement='bottom'
      open={tooltipOpen}
    >
      <ButtonGroup variant='outlined'>
        {options.map((option) => {
          const active = value === option
          return (
            <Button
              color='inherit'
              size='small'
              key={option}
              onClick={() => onChange({ type: 'change' }, option)}
              sx={{
                '&:hover': {
                  bgcolor: (theme) => theme.palette.neutral[200]
                },
                borderColor: (theme) => theme.palette.neutral[300],
                ...(active && {
                  backgroundColor: (theme) => theme.palette.neutral[300]
                })
              }}
            >
              {option}
            </Button>
          )
        })}
      </ButtonGroup>
    </Tooltip>
  )
}
