type FormatIntegerOptions = {
  fallback?: string
}

const formatInteger = (value: number, options: FormatIntegerOptions) => {
  const { fallback = '~' } = options || {}

  if (typeof value !== 'number') return fallback

  return Intl.NumberFormat('en-US').format(value)
}

export { formatInteger }
