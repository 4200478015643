import { Box, DrawerProps, Paper, SxProps } from '@mui/material'

export interface MapFilterProps {
  isEmbed?: boolean
  filters: any[]
  filterResults: any[]
  sx?: SxProps
  open: boolean
  onClose: () => void
  slots: Record<string, any>
  slotProps: Record<string, any>
  drawerProps?: DrawerProps
}

const defaultSlots = {
  Selector: () => null
}

const MapFilter = (props: MapFilterProps) => {
  const {
    sx = {},
    isEmbed = false,
    open,
    slots = {},
    slotProps = {},
    onClose,
    filters = [],
    filterResults = [],
    drawerProps,
    ...rest
  } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }
  return (
    <Box>
      <Box
        component={Paper}
        variant='outlined'
        sx={[
          (theme) => ({
            display: 'flex',
            flexDirection: 'column',
            minHeight: '158px',
            border: 0
          }),
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        {...rest}
      >
        <Box
          flex={1}
          sx={[
            (theme) => ({
              p: 1,
              [theme.breakpoints.down('mobile')]: {
                p: 1
              }
            })
          ]}
        >
          <Slots.Selector {...slotProps?.Selector} />
        </Box>
      </Box>
    </Box>
  )
}

export { MapFilter }
