import { useQueryClient } from '@tanstack/react-query'
import jscookie from 'js-cookie'
import { useRouter } from 'next/router'

import { WorkspaceInput as Input } from 'ui-components'
import {
  getCurrentWorkspaceKey,
  getWorkspace,
  useAuth,
  useAuthCurrentWorkspace,
  useAuthWorkspaces
} from 'user-auth'
import { base64, getOnboardLink, getWorkspaceLink } from 'utils'

export function WorkspaceInput() {
  const client = useQueryClient()
  const router = useRouter()

  const { userId } = useAuth()
  const workspaces = useAuthWorkspaces()
  const currentWorkspace = useAuthCurrentWorkspace()

  const handleItemClick = async (event, { props: { value: workspaceId } }) => {
    const workspace = workspaces.find(
      (workspace) => workspace.id === workspaceId
    )

    if (!workspace) return

    const payload = getWorkspace(workspace)
    jscookie.set('workspace_current', base64(payload))

    await client.invalidateQueries({
      queryKey: getCurrentWorkspaceKey(userId)
    })

    router.push(
      getWorkspaceLink({
        workspaceId: workspace?.id,
        workspaceName: workspace?.name
      })
    )
  }

  const handleClick = () => {
    router.push(getOnboardLink(userId!))
  }

  if (workspaces.length <= 0) return null

  return (
    <Input
      value={currentWorkspace?.id}
      options={workspaces}
      slotProps={{
        WorkspaceItem: {
          onClick: handleItemClick
        },
        CreateWorkspaceButton: {
          onClick: handleClick
        }
      }}
    />
  )
}
