import {
  InputLabel,
  OutlinedInput,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { Option } from '~ui-components/components/atoms/Option'
import { PrimaryFieldOptions } from '~ui-components/components/atoms/PrimaryFieldOptions'

export const MapFieldSettingDetail = (props) => {
  const { property, options, onChange } = props

  const propertyOptions = property?.options || {}
  const { fieldType } = propertyOptions
  return (
    <Stack
      spacing={1}
      padding={2}
    >
      <InputLabel>Field name</InputLabel>
      <OutlinedInput value={property?.name} />
      <PrimaryFieldOptions
        title={'Field type'}
        value={fieldType}
        handleOnchange={(value) => {
          const options = { ...property?.options, fieldType: value }
          onChange({ ...property, options })
        }}
        availableOptions={options}
        defaultValue={fieldType}
      />

      <Stack
        spacing={1}
        pt={2}
      >
        <FieldInputDescription
          type={fieldType}
          propertyOptions={propertyOptions}
          onChange={(event) => {
            const { name, value } = event.target
            onChange({
              ...property,
              options: { ...property?.options, [name]: value }
            })
          }}
        />
      </Stack>
    </Stack>
  )
}

const FieldInputDescription = (props) => {
  const { type, onChange, propertyOptions } = props
  switch (type) {
    case 'text':
      return (
        <Typography
          variant='body2'
          color='text.secondary'
        >
          A single line of text.
        </Typography>
      )
    case 'longText':
      return (
        <Typography
          variant='body2'
          color='text.secondary'
        >
          Multiple lines of text.
        </Typography>
      )
    case 'date':
      return (
        <Typography
          variant='body2'
          color='text.secondary'
        >
          A date (e.g. 1 January 2025).
        </Typography>
      )
    case 'select':
      return (
        <Typography
          variant='body2'
          color='text.secondary'
        >
          A predefined option from the list.
        </Typography>
      )
    case 'email':
      return (
        <Typography
          variant='body2'
          color='text.secondary'
        >
          An email address (e.g. sales@mapstack.io).
        </Typography>
      )
    case 'url':
      return (
        <Typography
          variant='body2'
          color='text.secondary'
        >
          A URL (e.g. mapstack.io or https://mapstack.io/pricing).
        </Typography>
      )
    case 'phoneNumber':
      return (
        <Typography
          variant='body2'
          color='text.secondary'
        >
          A phone number (e.g. (415) 333-5555).
        </Typography>
      )
    case 'number':
      return (
        <FieldInputNumber
          onChange={onChange}
          propertyOptions={propertyOptions}
        />
      )
    case 'currency':
      return (
        <FieldInputCurrency
          onChange={onChange}
          propertyOptions={propertyOptions}
        />
      )
    case 'percentage':
      return (
        <FieldInputPercentage
          onChange={onChange}
          propertyOptions={propertyOptions}
        />
      )
    default:
      return ''
  }
}

const FieldInputNumber = ({ onChange, propertyOptions }) => {
  return (
    <>
      <Typography
        variant='body2'
        color='text.secondary'
      >
        A regular number
      </Typography>
      <InputLabel>Formatting</InputLabel>
      <Select
        defaultValue={propertyOptions?.format || 'none'}
        name='format'
        onChange={onChange}
      >
        <Option value={'none'}>None (e.g. 123456)</Option>
        <Option value={'pretty'}>Pretty (e.g. 123,456)</Option>
        <Option value={'compactShort'}>Compact short (e.g. 12M)</Option>
        <Option value={'compactLong'}>Compact long (e.g. 12 million)</Option>
      </Select>
      <InputLabel>Decimal places</InputLabel>
      <Select
        defaultValue={propertyOptions?.decimalPlace || '0'}
        name='decimalPlace'
        onChange={onChange}
      >
        <Option value={'0'}>1 (1)</Option>
        <Option value={'1'}>1 (1.0)</Option>
        <Option value={'2'}>2 (1.00)</Option>
        <Option value={'3'}>3 (1.000)</Option>
        <Option value={'4'}>4 (1.0000)</Option>
        <Option value={'5'}>5 (1.00000)</Option>
      </Select>
      <InputLabel>Aggregate</InputLabel>
      <Select
        defaultValue={propertyOptions?.aggregate || 'sum'}
        name='aggregate'
        onChange={onChange}
      >
        <Option value={'sum'}>Sum</Option>
        <Option value={'average'}>Average</Option>
      </Select>
    </>
  )
}

const FieldInputCurrency = ({ onChange, propertyOptions }) => {
  return (
    <>
      <Typography
        variant='body2'
        color='text.secondary'
      >
        A monetary value
      </Typography>
      <InputLabel>Currency value</InputLabel>
      <OutlinedInput
        defaultValue={propertyOptions?.currencyValue || '$'}
        name='currencyValue'
        onChange={onChange}
      />
      <InputLabel>Formatting</InputLabel>
      <Select
        defaultValue={propertyOptions?.format || 'none'}
        name='format'
        onChange={onChange}
      >
        <Option value={'none'}>None (e.g. 123456)</Option>
        <Option value={'pretty'}>Pretty (e.g. 123,456)</Option>
        <Option value={'compactShort'}>Compact short (e.g. 12M)</Option>
        <Option value={'compactLong'}>Compact long (e.g. 12 million)</Option>
      </Select>
      <InputLabel>Decimal places</InputLabel>
      <Select
        defaultValue={propertyOptions?.decimalPlace || '0'}
        name='decimalPlace'
        onChange={onChange}
      >
        <Option value={'0'}>1 (1)</Option>
        <Option value={'1'}>1 (1.0)</Option>
        <Option value={'2'}>2 (1.00)</Option>
        <Option value={'3'}>3 (1.000)</Option>
        <Option value={'4'}>4 (1.0000)</Option>
        <Option value={'5'}>5 (1.00000)</Option>
      </Select>
      <InputLabel>Aggregate</InputLabel>
      <Select
        defaultValue={propertyOptions?.aggregate || 'sum'}
        name='aggregate'
        onChange={onChange}
      >
        <Option value={'sum'}>Sum</Option>
        <Option value={'average'}>Average</Option>
      </Select>
    </>
  )
}

const FieldInputPercentage = ({ onChange, propertyOptions }) => {
  return (
    <>
      <Typography
        variant='body2'
        color='text.secondary'
      >
        A percentage value
      </Typography>
      <InputLabel>Formatting</InputLabel>
      <Select
        defaultValue={propertyOptions?.format || 'none'}
        name='format'
        onChange={onChange}
      >
        <Option value={'none'}>None (e.g. 123456)</Option>
        <Option value={'pretty'}>Pretty (e.g. 123,456)</Option>
        <Option value={'compactShort'}>Compact short (e.g. 12M)</Option>
        <Option value={'compactLong'}>Compact long (e.g. 12 million)</Option>
      </Select>
      <InputLabel>Decimal places</InputLabel>
      <Select
        defaultValue={propertyOptions?.decimalPlace || '0'}
        name='decimalPlace'
        onChange={onChange}
      >
        <Option value={'0'}>1 (1)</Option>
        <Option value={'1'}>1 (1.0)</Option>
        <Option value={'2'}>2 (1.00)</Option>
        <Option value={'3'}>3 (1.000)</Option>
        <Option value={'4'}>4 (1.0000)</Option>
        <Option value={'5'}>5 (1.00000)</Option>
      </Select>
      <InputLabel>Aggregate</InputLabel>
      <Select
        defaultValue={propertyOptions?.aggregate || 'sum'}
        name='aggregate'
        onChange={onChange}
      >
        <Option value={'sum'}>Sum</Option>
        <Option value={'average'}>Average</Option>
      </Select>
    </>
  )
}
