import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import type { FC } from 'react'
import error404 from './error-404.png'

interface PageNotFoundProps {
  slot?: {
    GoToResource: React.ComponentType
  }
}

const PageNotFound: FC<PageNotFoundProps> = (props) => {
  const { slot } = props
  return (
    <>
      <Box
        component='main'
        sx={{
          position: 'absolute',
          width: '99%',
          height: '99%',
          padding: 0,
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1
        }}
      >
        <Container maxWidth='lg'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6
            }}
          >
            <Box
              alt='Not found'
              component='img'
              src={error404.src}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400
              }}
            />
          </Box>
          <Typography
            align='center'
            variant={'h4'}
            sx={{ fontWeight: 700 }}
          >
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align='center'
            color='text.secondary'
            sx={{ mt: 0.5 }}
          >
            You either arrived here by mistake or the resource has been deleted.
          </Typography>
          {slot?.GoToResource && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 6
              }}
            >
              <slot.GoToResource />
            </Box>
          )}
        </Container>
      </Box>
    </>
  )
}

export { PageNotFound }
export default PageNotFound
