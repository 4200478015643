import { StateProvider as FeatureInfoProvider } from 'map-feature-info'
import { useRouter } from 'next/router'
import { MapViewer as Layout } from 'ui-components'
import { MapError } from '~map-viewer/constants/MapError'
import { useError } from '~map-viewer/states/error'
import { useAreLayerSourcesLoaded } from '~map-viewer/states/layer'
import { StateProvider } from '~map-viewer/states/provider'

import { useIsFullscreenActive, useVisibility } from '~map-viewer/states/ui'
import MapGl from './Map'
import { MapActions } from './Tools/MapActions'
import { MapDescription } from './Tools/MapDescription'
import { MapFeatureInfo } from './Tools/MapFeatureInfo'
import { MapFilter } from './Tools/MapFilter'

import { MapFilterChips } from './Tools/MapFilterChips'
import { MapHeader } from './Tools/MapHeader'
import { MapNav } from './Tools/MapNav'
import { MapSearchModal } from './Tools/MapSearchModal'
import { MapTable } from './Tools/MapTable'
import { MapVisualisation } from './Tools/MapVisualisation'
import { MapVisualisationChips } from './Tools/MapVisualisationChips'
import { useInitAuthUserMap } from './hooks/useInitAuthUserMap'
import { useInitMapData } from './hooks/useInitMapData'

const providers = (Component) => (props) =>
  (
    <StateProvider initialState={props}>
      <FeatureInfoProvider>
        <Component {...props} />
      </FeatureInfoProvider>
    </StateProvider>
  )

const App = providers((props) => {
  const router = useRouter()

  const visibility = useVisibility()
  const isFullscreenActive = useIsFullscreenActive()
  const areLayerSourcesLoaded = useAreLayerSourcesLoaded()
  const error = useError()

  if (error === MapError.MAP_NOT_FOUND) router.push('/map/404')

  const { mapId, map, visualisation, shareState } = props

  const { checkIfDataExistLocally } = useInitMapData(mapId, map)

  useInitAuthUserMap(mapId)

  return (
    <Layout
      visibility={visibility}
      isFullscreenActive={isFullscreenActive}
      ready={areLayerSourcesLoaded}
      error={error}
      slots={{
        MapGl,
        MapNav,
        MapHeader,
        MapTable,
        MapFilter,
        MapActions,
        MapVisualisation,
        MapVisualisationChips,
        MapFilterChips,
        MapFeatureInfo,
        MapDescription,
        MapSearchModal
      }}
      slotProps={{
        MapGl: {
          visualisation,
          shareState,
          checkIfDataExistLocally
        }
      }}
    />
  )
})

export { App }
export default App
