import { Button, Stack } from '@mui/material'
import { FC } from 'react'

export type WorkspacesTabToolsProps = {
  canCreateWorkspace?: boolean
  onCreateWorkspaceClick?: () => void
}

const WorkspacesTabTools: FC<WorkspacesTabToolsProps> = ({
  canCreateWorkspace = false,
  onCreateWorkspaceClick
}) => {
  if (!canCreateWorkspace) return null

  return (
    <Stack
      data-testid='WorkspacesTab-Tools'
      justifyContent='flex-end'
      direction='row'
      gap={3}
      p={3}
    >
      <Button
        data-testid='CreateWorkspaceButton'
        onClick={onCreateWorkspaceClick}
      >
        Create workspace
      </Button>
    </Stack>
  )
}

export { WorkspacesTabTools }
