import { Divider, Stack, StackProps } from '@mui/material'
import { FC } from 'react'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'

type MapSettingsFormProps = StackProps & {
  slots?: any
}

const MapSettingsForm: FC<MapSettingsFormProps> = (props) => {
  const { slots, ...rest } = props

  return (
    <Scrollbar>
      <Stack
        spacing={2}
        {...rest}
      >
        {slots?.MapName && <slots.MapName />}
        <Divider />
        {slots?.PrimaryField && <slots.PrimaryField />}
        {slots?.FieldSettings && <slots.FieldSettings />}
        <Divider />
        {slots?.DefaultVisualization && <slots.DefaultVisualization />}
        {slots?.DeleteMap && <slots.DeleteMap />}
      </Stack>
    </Scrollbar>
  )
}

export { MapSettingsForm }
