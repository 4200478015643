import {
  Button,
  Link,
  Paper as MuiPaper,
  Snackbar,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import Fade from '@mui/material/Fade'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import { Cookie } from '@phosphor-icons/react'
import NextLink from 'next/link'
import { getPrivacyLink } from 'utils'

interface CookiesSnackProps {
  open?: boolean
  onAccept: () => void
  onClose: () => void
}

const Paper = motion(MuiPaper)

const useIsScrolling = () => {
  const [scrolling, setScrolling] = useState(false)

  const handleScroll = () => {
    setScrolling(true)
  }

  const handleScrollEnd = () => {
    setScrolling(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('scrollend', handleScrollEnd)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('scrollend', handleScrollEnd)
    }
  }, [])

  return scrolling
}

export function CookiesSnack(props: CookiesSnackProps) {
  const { open = true, onAccept, onClose } = props

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      TransitionComponent={Fade}
    >
      <Paper sx={{ borderRadius: 2, p: 2 }}>
        <Stack direction='column'>
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
          >
            <SvgIcon
              component={Cookie}
              weight='duotone'
              inheritViewBox
              sx={{ width: 32, height: 32 }}
            />
            <Typography
              component='h6'
              variant='next:subtitle1'
            >
              We use cookies
            </Typography>
          </Stack>
          <Stack
            ml={5}
            gap={1}
          >
            <Typography
              component='p'
              variant='next:subtitle2'
              fontWeight={400}
            >
              To ensure that we give you the best experience on our website.{' '}
              <br />
              Read our{' '}
              <Link
                component={NextLink}
                color='secondary'
                href={getPrivacyLink()}
                underline='always'
              >
                Privacy Policy
              </Link>
            </Typography>

            <Stack
              direction='row'
              gap={1}
            >
              <Button
                sx={{ flex: 1 }}
                variant='contained'
                onClick={onClose}
                color='inherit'
                disableElevation
              >
                Reject All
              </Button>
              <Button
                sx={{ flex: 1 }}
                variant='contained'
                color={'primary'}
                onClick={onAccept}
                disableElevation
              >
                Accept All
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Snackbar>
  )
}
