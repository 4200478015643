import { Stack, Typography } from '@mui/material'
import { FilterFunnel01 } from '@untitled-ui/icons-react'

type MapFilterHeaderProps = {
  filterResults: any[]
}

export const MapFilterHeader = (props: MapFilterHeaderProps) => {
  const { filterResults } = props

  const title = `Filters ${
    filterResults.length > 0 ? `(${filterResults.length})` : ''
  }`

  return (
    <Stack
      alignItems='inherit'
      direction='row'
      spacing={1}
    >
      <FilterFunnel01 />
      <Typography variant='h6'>{title}</Typography>
    </Stack>
  )
}
