import { useAtomValue } from 'user-auth/libs/jotai'

import { workspaces } from 'user-auth/states/auth'
import { Workspace } from 'user-auth/types/__generated/gql/graphql'

export type AccountWorkspace = Pick<
  Workspace,
  | 'id'
  | 'description'
  | 'createdAt'
  | 'name'
  | 'logo'
  | 'memberCollection'
  | 'guestCollection'
>

export const useAuthWorkspaces = (): AccountWorkspace[] =>
  useAtomValue(workspaces)
