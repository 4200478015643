import { Stack, Typography } from '@mui/material'
import { Info as InfoIcon } from '@phosphor-icons/react'
import { FC } from 'react'

type MapFeatureInfoHeaderProps = {
  title: string
  showInfoIcon?: boolean
}

export const MapFeatureInfoHeader: FC<MapFeatureInfoHeaderProps> = (props) => {
  const { title = 'Location details', showInfoIcon = false } = props
  return (
    <Stack
      alignItems='center'
      direction='row'
      spacing={1}
    >
      {showInfoIcon && <InfoIcon fontSize={28} />}
      <Typography variant='h6'>{title}</Typography>
    </Stack>
  )
}
