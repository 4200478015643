import { Feature } from 'geojson'
import { isEmpty } from 'lodash'
import { DEFAULT_JENKS_CLASS_BREAKS, MS_UUID } from '../constants'
import { BoundaryClassBreaks, BoundaryDataBreaks, Jenks } from '../types'
import { generateJenks } from './jenks'

function getBoundaryaggregate(
  geojsonFeatures: Feature[],
  aggregateBoundary: string,
  aggregateProperty: string,
  aggregateType: string
) {
  const records: Record<string, number> = {}

  for (const feature of geojsonFeatures) {
    // assert(feature.properties, `${feature} has no properties`)

    const boundaryId: string = feature.properties![aggregateBoundary]
    if (!boundaryId) continue

    // hacky fix to deal with leading 0s in numbers
    const possValue = feature.properties![aggregateProperty]
      ? parseFloat(
          `${feature.properties![aggregateProperty]}`.replace(/^0+/, '')
        )
      : 0
    const currentValue: number = aggregateProperty == 'count' ? 1 : possValue
    switch (aggregateType) {
      default: // defaults to sum
        if (!records[boundaryId]) {
          records[boundaryId] = currentValue
        } else records[boundaryId] += currentValue
    }
  }

  return records
}

function categoriseRecordIntoJenks(
  boundaryRecord: Record<string, number>,
  jenks: Jenks
): BoundaryClassBreaks {
  // early return for jenks.length = 1
  if (jenks.length == 1) {
    console.warn('Data only had single class')
    return [Object.keys(boundaryRecord)]
  }

  // needed for diff flows for under over DEFAULT breaks
  const isOverCatBreakDefault = jenks.length > DEFAULT_JENKS_CLASS_BREAKS
  const categoryNumber = isOverCatBreakDefault ? jenks.length - 1 : jenks.length

  // create empty array to populate with boundary ids split into jenks
  const categorisedBoundaryIds: BoundaryClassBreaks = new Array(categoryNumber)
    .fill(null)
    .map(() => [])

  Object.keys(boundaryRecord).forEach((key) => {
    const boundaryId: string = key
    const dataValue: number | undefined = boundaryRecord[key]
    if (!dataValue) return

    // find Jenks category that data fits into
    let categoryFound: boolean = false
    for (let i = 0; i < jenks.length - 1; i++) {
      const currentMinJenksValue: number | undefined = jenks[i]
      const currentMaxJenksValue: number | undefined = jenks[i + 1]
      if (!currentMinJenksValue || !currentMaxJenksValue) continue

      // under and over flows need different conditions
      const jenksCondition = isOverCatBreakDefault
        ? dataValue == currentMaxJenksValue ||
          (dataValue >= currentMinJenksValue &&
            dataValue < currentMaxJenksValue &&
            categorisedBoundaryIds[i])
        : dataValue == currentMinJenksValue ||
          (dataValue >= currentMinJenksValue &&
            dataValue < currentMaxJenksValue)

      if (jenksCondition) {
        categorisedBoundaryIds[i]?.push(boundaryId)
        categoryFound = true
        break
      }
    }

    // Handle edge case where dataValue is the last jenk's value
    if (!categoryFound && dataValue == jenks[jenks.length - 1]) {
      categorisedBoundaryIds[categorisedBoundaryIds.length - 1]!.push(
        boundaryId
      )
    }
  })

  return categorisedBoundaryIds
}

function generateClasses(
  geojsonFeatures: Feature[],
  aggregateOnBoundary: string,
  aggregateProperty: string,
  aggregateType: string
): BoundaryDataBreaks {
  const records = getBoundaryaggregate(
    geojsonFeatures,
    aggregateOnBoundary,
    aggregateProperty,
    aggregateType
  )
  if (isEmpty(records)) return { boundaryClassBreaks: [], jenksBreaks: [] }

  const jenksBreaks: Jenks = generateJenks(Object.values(records))

  const boundaryClassBreaks: BoundaryClassBreaks = categoriseRecordIntoJenks(
    records,
    jenksBreaks
  )

  const dataBreaks: BoundaryDataBreaks = {
    boundaryClassBreaks: boundaryClassBreaks,
    jenksBreaks: jenksBreaks
  }

  return dataBreaks
}

function generateCategories(
  geojsonFeatures: Feature[],
  aggregateOnProperty: string
): Record<string, any[]> {
  const propertyValues: Record<string, any[]> = {}
  geojsonFeatures.map((feature) => {
    if (feature.properties![aggregateOnProperty] !== undefined) {
      const category: string =
        feature.properties![aggregateOnProperty].toString()
      const boundaryId: string | number = feature.properties![MS_UUID]
      if (propertyValues[category]) {
        const currentVal = propertyValues[category]
        propertyValues[category] = [...currentVal!, boundaryId]
      } else {
        propertyValues[category] = [boundaryId]
      }
    }
  })

  // if (Object.keys(propertyValues).length > DEFAULT_CATEGORY_MAX)
  //   throw new Error('Set has too many classes')

  // Sort keys by their corresponding values from biggest to smallest
  const sortedKeys = Object.keys(propertyValues).sort((a, b) => {
    const valueA = propertyValues[a]?.length!
    const valueB = propertyValues[b]?.length!
    return valueB - valueA
  })

  const sortedRecord: Record<string, any[]> = {}
  sortedKeys.forEach((key) => {
    sortedRecord[key] = propertyValues[key]!
  })

  return sortedRecord
}

export {
  categoriseRecordIntoJenks,
  generateCategories,
  generateClasses,
  getBoundaryaggregate
}
