import { Stack } from '@mui/material'
import { useMemo } from 'react'

import getProperties from './getProperties'

import { PropertyList } from '~ui-components/components/molecules/PropertyList'
import { PropertyListItem } from '~ui-components/components/molecules/PropertyListItem'

interface MapFeatureInfoProps {
  isEmbed?: boolean
  open: boolean
  title: string
  feature: any
}

function MapFeatureInfo(props: MapFeatureInfoProps) {
  const { title = '', feature = {} } = props

  const properties = useProperties(feature?.properties, title)

  return (
    <Stack p={2}>
      <PropertyList
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: 1
        }}
      >
        {properties.map((property, index) => (
          <PropertyListItem
            key={`property:${index}`}
            divider={index < properties.length - 1}
            label={property.name}
            value={property.value}
          />
        ))}
      </PropertyList>
    </Stack>
  )
}

const useProperties = (properties, title) => {
  return useMemo(() => getProperties(properties, title), [properties, title])
}

export { MapFeatureInfo }
export default MapFeatureInfo
export { MapFeatureInfoHeader } from './MapFeatureInfoHeader'
