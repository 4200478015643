import {
  Box,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
  TextField
} from '@mui/material'
import { SearchMd } from '@untitled-ui/icons-react'

// reason `.keyword` is used:
// https://opensearch.org/docs/latest/search-plugins/searching-data/sort/#sort-results
type SortValue = 'name.keyword|asc' | 'createdAt|desc' | 'createdAt|asc'

interface SortOption {
  label: string
  value: SortValue
}

export const mapsSortOptions: SortOption[] = [
  {
    label: 'Name',
    value: 'name.keyword|asc'
  },
  {
    label: 'Newest',
    value: 'createdAt|desc'
  },
  {
    label: 'Oldest',
    value: 'createdAt|asc'
  }
]

const Empty = () => null

const MapsTabTools = (props) => {
  const { canCreateMap = false, slots, slotProps } = props

  const Slots = {
    CreateMapButton: Empty,
    ...slots
  }

  return (
    <Stack
      alignItems='center'
      direction='row'
      flexWrap='wrap'
      gap={3}
      sx={{ p: 3 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <OutlinedInput
          defaultValue=''
          fullWidth
          autoComplete='off'
          placeholder='Search maps'
          startAdornment={
            <InputAdornment position='start'>
              <SvgIcon>
                <SearchMd />
              </SvgIcon>
            </InputAdornment>
          }
          {...slotProps?.SearchMapInput}
        />
      </Box>

      <TextField
        select
        label='Sort By'
        name='sort'
        SelectProps={{ native: true }}
        {...slotProps?.MapSortInput}
      >
        {mapsSortOptions.map((option) => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </TextField>

      {canCreateMap ? (
        <Slots.CreateMapButton {...slotProps?.CreateMapButton} />
      ) : null}
    </Stack>
  )
}

export { MapsTabTools }
