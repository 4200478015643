import {
  Box,
  Container,
  Divider,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import NextImage from 'next/image'
import { MapstackUserCases } from '~ui-components/components/organisms/MapstackUseCases'

import { CTAButtons } from './CTAButtons'
import image1 from './section-4-1.png'
import image2 from './section-4-2.png'
import image3 from './section-4-3.png'

export function Section4() {
  return (
    <Container maxWidth='xl'>
      <Paper
        variant='elevation'
        elevation={8}
        sx={{
          p: 2,
          borderRadius: 4
        }}
      >
        <Stack
          direction='column'
          spacing={8}
          my={8}
          alignItems='center'
        >
          <Typography
            variant='next:h2'
            component='h2'
            textAlign='center'
            fontWeight={700}
            maxWidth='27ch'
          >
            Goodbye manual territory planning, hello instant maps
          </Typography>

          <Typography
            component='p'
            variant='next:h6'
            textAlign='center'
            color='text.secondary'
            maxWidth='96ch'
          >
            No more complicated workflows or static boundaries. Mapstack helps
            you define territories, track key metrics, and adjust on the fly—all
            from one streamlined platform.
          </Typography>

          <Stack
            direction='row'
            gap={4}
            flexWrap='wrap'
            justifyContent='center'
          >
            <HowItWorkCard
              image={image1}
              title='Upload your territory data'
              alt='Upload your territory data'
              description={
                <>
                  Bring in your sales, customer, or regional data from
                  spreadsheets or CRM exports. No need for
                  reorganization—Mapstack does the heavy lifting so you can
                  focus on the bigger picture.
                </>
              }
            />

            <HowItWorkCard
              image={image2}
              title='Define & refine instantly'
              alt='Define & refine instantly'
              description={
                <>
                  In seconds, see your territory boundaries on an interactive
                  map. Easily draw new areas or refine existing ones, then watch
                  coverage and performance metrics update in real time.
                </>
              }
            />

            <HowItWorkCard
              image={image3}
              title='Analyze and act'
              alt='Analyze and act'
              description={
                <>
                  Apply filters—like sales targets or population density—to spot
                  imbalances or hidden opportunities. Real-time insights let you
                  make smarter decisions fast, without digging through
                  spreadsheets.
                </>
              }
            />
          </Stack>

          <Stack
            spacing={8}
            alignItems='center'
          >
            <Typography
              component='h2'
              variant='next:h3'
              fontWeight={700}
              textAlign='center'
            >
              Powerful territory visualizations
            </Typography>

            <Typography
              component='p'
              variant='next:h6'
              textAlign='center'
              color='text.secondary'
              maxWidth='88%'
            >
              Easily switch between boundary views, heat maps, or cluster
              insights. Share interactive dashboards with your team, so everyone
              stays aligned on territory performance.
            </Typography>

            <MapstackUserCases />
          </Stack>

          <CTAButtons />
        </Stack>
      </Paper>
    </Container>
  )
}

function HowItWorkCard(props) {
  const { title, description, image, alt } = props
  return (
    <Box
      width='100%'
      maxWidth={420}
      component={Paper}
      variant='elevation'
      elevation={8}
      borderRadius={3}
    >
      <Typography
        variant='next:h5'
        component='h3'
        textAlign='left'
        fontWeight={700}
        p={3}
      >
        {title}
      </Typography>
      <Divider />
      <Stack
        p={3}
        spacing={3}
      >
        <Box
          component={Paper}
          variant='outlined'
          sx={{
            position: 'relative',
            aspectRatio: '16/9',
            width: '100%',
            fontStyle: 'italic',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <NextImage
            src={image}
            alt={alt}
            fill
          />
        </Box>
        <Typography
          component='p'
          variant='next:subtitle1'
          textAlign='left'
        >
          {description}
        </Typography>
      </Stack>
    </Box>
  )
}
