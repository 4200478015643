import jscookie from 'js-cookie'
import { useRouter } from 'next/router'
import { LoginForm as Form } from 'ui-components'

import { useAuthMetadata } from 'user-auth/hooks/useAuthMetadata'
import { useFederatedAuth } from 'user-auth/hooks/useFederatedAuth'
import { useAtom } from 'user-auth/libs/jotai'
import { atoms } from 'user-auth/states/form'

import { Header } from '../Header'

const validate = (domain) => /^(?!staging\.)(.+)\.mapstack\.io$/.test(domain)

export function LoginForm() {
  const router = useRouter()

  const [, setForm] = useAtom(atoms.form)

  const { signIn } = useFederatedAuth()
  const { redirect } = useAuthMetadata()

  const callback = router.query?.callback
  const disabled = callback === 'true'

  const clearAuthCookie = () => {
    jscookie.remove('auth_metadata')
  }

  const handleGoogleMethod = () => {
    clearAuthCookie()
    signIn({ provider: 'Google', redirect })
  }

  const handleFacebookMethod = () => {
    clearAuthCookie()
    signIn({ provider: 'Facebook', redirect })
  }

  const handleEmailMethod = () => {
    clearAuthCookie()
    setForm('email')
  }

  const handleSignInEmailPasswordMethod = () => {
    clearAuthCookie()
    setForm('signInEmailPassword')
  }

  const handleSignUpEmailPasswordMethod = () => {
    clearAuthCookie()
    setForm('signUpEmailPassword')
  }

  return (
    <Form
      dev={
        process.env.NODE_ENV === 'development' ||
        validate(process.env.NEXT_PUBLIC_DOMAIN_NAME)
      }
      slots={{ Header }}
      slotProps={{
        GoogleButton: {
          disabled,
          onClick: handleGoogleMethod
        },
        FacebookButton: {
          disabled,
          onClick: handleFacebookMethod
        },
        EmailButton: {
          disabled,
          onClick: handleEmailMethod
        },
        SignInEmailPasswordButton: {
          disabled,
          onClick: handleSignInEmailPasswordMethod
        },
        SignUpEmailPasswordButton: {
          disabled,
          onClick: handleSignUpEmailPasswordMethod
        }
      }}
    />
  )
}
