import { DrawerProps, Paper, Stack, SvgIconProps } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { CaretLeft } from '@phosphor-icons/react'
import * as React from 'react'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'

interface Slots {
  Title?: React.ReactNode
  Content?: React.ReactNode
}

type SidebarProps = DrawerProps & {
  isEmbed?: boolean
  open: boolean
  onOpenChange?: (open: boolean) => void
  slots?: Slots
  slotProps?: SlotProps
  IconProps?: SvgIconProps
}

export const drawerWidth = 390

type SlotProps = {
  open?: boolean
  onOpenChange?: (open: boolean) => void
}
const defaultSlots: Slots = {
  Title: null,
  Content: null
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

function SideBar(props: SidebarProps) {
  const {
    slots = defaultSlots,
    open,
    isEmbed = false,
    title,
    onClose,
    slotProps,
    ModalProps,
    onOpenChange,
    PaperProps,
    IconProps,
    children,
    ...rest
  } = props
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant='persistent'
      anchor='left'
      open={open}
      PaperProps={{
        sx: {
          position: 'relative'
        }
      }}
    >
      <DrawerHeader>
        <Stack
          width='100%'
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
        >
          <IconButton onClick={() => onOpenChange?.(!open)}>
            <CaretLeft />
          </IconButton>
          <Typography
            sx={{ flex: 1 }}
            variant='h6'
          >
            {slots?.Title}
          </Typography>
        </Stack>
      </DrawerHeader>

      <Paper
        sx={[
          {
            display: 'flex',
            flexGrow: 1,
            overflow: 'hidden',
            width: 390,
            maxWidth: '100%',
            left: 'var(--sidebar-offset-left)'
            // height: '100%'
          },
          (theme) => ({
            [theme.breakpoints.down('mobile')]: {
              // width: isEmbed ? 'calc(100% - 80px)' : '100%',
              height: 'calc(100dvh - var(--sidebar-offset-bottom, 0px))'
            }
          })
        ]}
      >
        <Scrollbar>
          <Box sx={{ flexGrow: 1 }}>{slots.Content}</Box>
        </Scrollbar>
      </Paper>
    </Drawer>
  )
}

export { SideBar }
