import { LoadingButton } from '@mui/lab'
import { UsersThree as ShareIcon } from '@phosphor-icons/react'
import {
  Box,
  ListSubheader,
  FormControl,
  Typography,
  Button,
  Select,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  TextFieldProps,
  MenuItem
} from '@mui/material'
import { useFormik } from 'formik'
import { Mail01 } from '@untitled-ui/icons-react'
import { FC } from 'react'
import { Option } from '~ui-components/components/atoms/Option'

type Form = {
  email: string
  accessLevel: string
}

type EmailShareProps = TextFieldProps & {
  form: ReturnType<typeof useFormik<Form>>
  onManageAccess: () => void
}

const EmailShare: FC<EmailShareProps> = (props) => {
  const { form, onManageAccess = () => {} } = props

  return (
    <form
      data-testid='EmailForm'
      onSubmit={form.handleSubmit}
      style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
    >
      <Stack
        style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
        direction='column'
        spacing={3}
      >
        <Stack spacing={2}>
          <TextField
            autoComplete='off'
            color='secondary'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon>
                    <Mail01 />
                  </SvgIcon>
                </InputAdornment>
              ),
              sx: { fontSize: 'body2.fontSize' }
            }}
            label='Email address'
            name='email'
            type='email'
            sx={{ flexGrow: 1 }}
            value={form.values.email}
            onChange={form.handleChange('email')}
            disabled={form.isSubmitting}
          />
          <FormControl>
            <Select
              name='accessLevel'
              value={form.values.accessLevel}
              onChange={form.handleChange}
              renderValue={(selected) => (
                <Typography variant='body2'>
                  {selected === 'guest' ? 'Guest' : 'Team member'}
                </Typography>
              )}
            >
              <Option
                key='guest'
                value='guest'
              >
                <Stack
                  direction='column'
                  gap={1}
                >
                  <Typography
                    variant='body2'
                    fontWeight='bold'
                  >
                    Guest
                  </Typography>
                  <Typography variant='body2'>
                    Can view this map only, access is read-only can not edit or
                    configure
                  </Typography>
                </Stack>
              </Option>
              <Option
                key='member'
                value='member'
              >
                <Stack
                  direction='column'
                  gap={1}
                >
                  <Typography
                    variant='body2'
                    fontWeight='bold'
                  >
                    Team member
                  </Typography>
                  <Typography variant='body2'>
                    Can fully access, edit and configure all maps in this
                    workspace
                  </Typography>
                </Stack>
              </Option>
            </Select>
          </FormControl>
          <LoadingButton
            sx={{ width: '160px' }}
            type='submit'
            color='secondary'
            variant='contained'
            loading={form.isSubmitting}
            disabled={!form.isValid || !form.dirty}
          >
            Invite
          </LoadingButton>
        </Stack>
        <Stack
          sx={{
            flexGrow: 1,
            justifyContent: 'flex-end',
            display: 'flex'
          }}
        >
          <Button
            sx={{ width: 120, alignSelf: 'flex-end' }}
            color='inherit'
            startIcon={<ShareIcon />}
            onClick={onManageAccess}
          >
            Manage access
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
export { EmailShare }
