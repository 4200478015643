import { Stack } from '@mui/material'

interface Slots {
  pointsMap?: any
  markerMap?: any
  categoryMap?: any
  heatmap?: any
  aggregateMaps?: any
}

const Empty = () => null

const defaultSlots: Slots = {
  pointsMap: Empty,
  markerMap: Empty,
  categoryMap: Empty,
  heatmap: Empty,
  aggregateMaps: Empty
}

interface MapStyleComponentProps {
  slots?: Slots
}

export function MapStyleComponent(props: MapStyleComponentProps) {
  const { slots } = props
  return (
    <Stack>
      {slots?.pointsMap}
      {slots?.aggregateMaps}
    </Stack>
  )
}
