import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import { getWorkspaceLink } from 'utils'

import Check from '@untitled-ui/icons-react/build/esm/Check'

import { RouterLink } from '~ui-components/components/atoms/RouterLink'
import { Workspace } from '~ui-components/types/__generated/gql/graphql'

import { Avatar } from '@mui/material'
import type { FC } from 'react'

type NavAvatarDropdownWorkspace = Pick<
  Workspace,
  'id' | 'name' | 'logo' | 'memberCollection' | 'plan' | 'guestCollection'
>

export interface WorkspacesListMenuProps {
  workspaces?: NavAvatarDropdownWorkspace[]
  currentWorkspace?: {
    id: string
    name: string
  }
  onClick?: (event, workspace) => void
}

export const WorkspacesListMenu: FC<WorkspacesListMenuProps> = (props) => {
  const { workspaces, currentWorkspace, onClick } = props

  return (
    <>
      {!!workspaces?.length && (
        <>
          <Stack spacing={1}>
            {workspaces.map((workspace) => {
              const memberCount =
                workspace?.memberCollection?.items.length +
                workspace?.guestCollection?.items.length
              return (
                <ListItemButton
                  key={workspace.id}
                  component={RouterLink}
                  href={getWorkspaceLink({
                    workspaceId: workspace.id,
                    workspaceName: workspace.name
                  })}
                  sx={{
                    borderRadius: 1,
                    px: 1,
                    py: 0.5
                  }}
                  onClick={(event) => {
                    onClick?.(event, workspace)
                  }}
                >
                  <Avatar
                    alt={workspace.name ?? ''}
                    src={workspace.logo ?? ''}
                    sx={{ mr: 1 }}
                  />
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant='body1'>
                          {workspace.name}
                        </Typography>
                        {currentWorkspace &&
                          workspace.id === currentWorkspace.id && (
                            <SvgIcon
                              fontSize='small'
                              sx={{ ml: 1 }}
                            >
                              <Check />
                            </SvgIcon>
                          )}
                      </Box>
                    }
                    secondary={
                      <Typography
                        color='text.secondary'
                        variant='subtitle2'
                      >
                        {`${workspace?.plan} plan - ${memberCount} ${
                          memberCount > 1 ? 'members' : 'member'
                        }`}
                      </Typography>
                    }
                  />
                </ListItemButton>
              )
            })}
          </Stack>
        </>
      )}
    </>
  )
}
