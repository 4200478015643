import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  getFormContext,
  isContainVisualisationProperties
} from '~map-creator/core/functions'
import { useKeypressHandler } from '~map-creator/core/hooks/useKeypressHandler'
import { useRefreshHandler } from '~map-creator/core/hooks/useRefreshHandler'
import { useWebWorker } from '~map-creator/core/hooks/useWebWorker'
import { useWorkspaceId } from '~map-creator/core/hooks/useWorkspaceId'
import { useGetWorkspaceByIdQuery } from '~map-creator/core/services/workspace'
import { StoreContext } from '~map-creator/core/states/context'
import { reset as resetGeojson } from '~map-creator/core/states/geojson'
import {
  popWizardStep,
  pushWizardStep,
  reset as resetMap,
  useCurrentWizardStep
} from '~map-creator/core/states/map'
import { reset as resetProperties } from '~map-creator/core/states/properties'
import { useStepIndexes } from '~map-creator/core/states/ui'

import { useAuth } from 'user-auth'
import { useIsPartOfWorkspace } from '~map-creator/core/hooks/useIsPartOfWorkspace'
import { View } from './view'

export const CreateMap = (props) => {
  const { steps, onSubmit, ...rest } = props

  const store = useContext(StoreContext)
  const dispatch = useDispatch()
  const worker = useWebWorker()

  useEffect(() => {
    dispatch(resetMap())
    dispatch(resetGeojson())
    dispatch(resetProperties())
  }, [dispatch])

  useKeypressHandler()
  useRefreshHandler()

  const workspaceId = useWorkspaceId()
  const isPartOfWorkspace = useIsPartOfWorkspace()

  const { loading: isUserLoading } = useAuth()
  const { isLoading: isWorkspaceLoading } =
    useGetWorkspaceByIdQuery(workspaceId)

  const isLoading = isUserLoading || isWorkspaceLoading

  const [isSubmitting, setIsSubmitting] = useState(false)
  const currentWizardStep = useCurrentWizardStep()
  const stepIndexes = useStepIndexes()

  const handleBackClick = () => {
    if (currentWizardStep > 0) dispatch(popWizardStep())
  }

  const handleNextClick = () => {
    if (
      currentWizardStep === stepIndexes.INCLUDE_PROPERTIES &&
      !isContainVisualisationProperties(store)
    ) {
      return
    }

    if (currentWizardStep < steps.length - 1)
      dispatch(pushWizardStep(currentWizardStep + 1))
  }

  const handleSubmitClick = async () => {
    try {
      setIsSubmitting(true)
      const context = await getFormContext(store)
      worker.background(() => {
        onSubmit(context)
      })
    } catch (error) {
      setIsSubmitting(false)
      throw error
    }
  }

  return (
    <View
      loading={isLoading}
      isWorkspaceMember={!isLoading && isPartOfWorkspace}
      steps={steps}
      currentStep={currentWizardStep}
      isSubmitting={isSubmitting}
      onBackClick={handleBackClick}
      onNextClick={handleNextClick}
      onSubmitClick={handleSubmitClick}
      displayStep={{ current: currentWizardStep, total: steps.length - 1 }}
      {...rest}
    />
  )
}

CreateMap.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  isError: PropTypes.bool
}
