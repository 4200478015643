import { NavAvatarUser as View } from 'ui-components'
import { useAuth } from 'user-auth'

export function NavAvatarUser() {
  const { user } = useAuth()
  return (
    <View
      userId={user?.id}
      firstName={user?.firstName}
      lastName={user?.lastName}
      email={user?.email}
    />
  )
}
