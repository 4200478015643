import {
  // sendGAEvent,
  sendGTMEvent
} from '@next/third-parties/google'

export const sendEvent = function (
  type: string,
  event: string,
  options: object = {}
) {
  sendGTMEvent({ event, ...options })
  // sendGAEvent(type, event, options)
}
