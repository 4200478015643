import jscookie from 'js-cookie'
import { useEffect } from 'react'
import { MapstackLoadingBackdrop, WORKSPACE_TAB } from 'ui-components'
import {
  dayjs,
  fromBase64,
  getOnboardLink,
  getSearchLink,
  getWorkspaceLink
} from 'utils'

import assert from 'assert'
import { signInEvent } from 'tag-manager/sign_in'
import { signUpEvent } from 'tag-manager/sign_up'
import { useAuth } from 'user-auth/hooks/useAuth'
import { postSignInRedirect } from 'user-auth/states/postSignInRedirect'

class UndefinedRedirect extends Error {
  constructor(params?) {
    super(params)
    this.name = 'UndefinedRedirect'
    this.message = 'Could not determine where to redirect user'
  }
}

class UndefinedWorkspace extends Error {
  constructor(params?) {
    super(params)
    this.name = 'UndefinedWorkspace'
  }
}

class SkipOnboardFlow extends Error {
  constructor(params?) {
    super(params)
    this.name = 'SkipOnboardFlow'
    this.message = 'Skip onboarding flow'
  }
}

class SkipSignUpEvent extends Error {
  constructor(params?) {
    super(params)
    this.name = 'SkipSignUpEvent'
    this.message = 'Skip sign up event'
  }
}

class UserNotReady extends Error {
  constructor(params?) {
    super(params)
    this.name = 'UserNotReady'
    this.message = 'User is not ready'
  }
}

export function AuthCallback() {
  const auth = useAuth()
  const { ready, user, initialized } = auth

  const go = (path: string) => {
    window.location.href = path
  }

  /**
   * newly created user only have a single workspace which is their default workspace
   * we use this to redirect them to the onboarding flow
   */
  const gotoOnboard = () => {
    const total = user?.membershipCollection?.total || 0
    const hasWorkspaces = total >= 1
    if (hasWorkspaces) throw new SkipOnboardFlow()
    go(getOnboardLink(user!.id))
  }

  const gotoRedirect = () => {
    const redirect = postSignInRedirect.get()

    if (!redirect) throw new UndefinedRedirect()

    postSignInRedirect.remove()
    go(redirect)
  }

  const gotoCurrentWorkspace = () => {
    const workspaceCookie = jscookie.get('workspace_current') || undefined

    const currentWorkspace = fromBase64(workspaceCookie)

    if (!currentWorkspace) throw new UndefinedWorkspace()

    go(
      getWorkspaceLink({
        workspaceId: currentWorkspace.id,
        workspaceName: currentWorkspace.name,
        tab: WORKSPACE_TAB.MAPS
      })
    )
  }

  const gotoDefaultWorkspace = () => {
    if (!user?.defaultWorkspace) throw new UndefinedWorkspace()

    go(
      getWorkspaceLink({
        workspaceId: user.defaultWorkspace.id,
        workspaceName: user.defaultWorkspace.name,
        tab: WORKSPACE_TAB.MAPS
      })
    )
  }

  const gotoSearch = () => {
    go(getSearchLink())
  }

  const triggerSignUpEvent = () => {
    if (!user) throw new UserNotReady()

    const createdAt = dayjs(user.createdAt)
    const existedUser = dayjs().diff(createdAt, 'minute') >= 1

    if (existedUser) {
      throw new SkipSignUpEvent()
    }

    const postSignInUrl = postSignInRedirect.get() || undefined
    console.log('postSignInUrl', postSignInUrl)
    const isInvite = isJoinWorkspaceLink(postSignInUrl)
    console.log('isInvite', isInvite)
    assert.strict(typeof user.firstName === 'string')
    assert.strict(typeof user.lastName === 'string')
    assert.strict(typeof user.email === 'string')
    signUpEvent(user.id, user.firstName, user.lastName, user.email, isInvite)
  }

  const triggerSignInEvent = () => {
    if (!user) throw new UserNotReady()
    assert.strict(typeof user.firstName === 'string')
    assert.strict(typeof user.lastName === 'string')
    assert.strict(typeof user.email === 'string')
    signInEvent(user.id, user.firstName, user.lastName, user.email)
  }

  useEffect(() => {
    if (!initialized) return
    if (!ready) return
    if (!user) return

    const program = () => {
      try {
        triggerSignInEvent()
      } catch (error) {
        console.warn('triggerSignInEvent', error)
      }

      try {
        triggerSignUpEvent()
      } catch (error) {
        console.warn('triggerSignUpEvent', error)
      }

      try {
        gotoRedirect()
        return
      } catch (error) {
        console.warn('gotoRedirect', error)
      }

      try {
        gotoOnboard()
        return
      } catch (error) {
        console.warn('gotoOnBoard', error)
      }

      try {
        gotoCurrentWorkspace()
        return
      } catch (error) {
        console.warn('gotoCurrentWorkspace', error)
      }

      try {
        // legacy redirect, we will remove this after we decide to remove defaultWorkspaceId from user model
        gotoDefaultWorkspace()
        return
      } catch (error) {
        console.warn('gotoDefaultWorkspace', error)
      }

      gotoSearch()
    }

    program()
  }, [initialized, ready, user])

  return <MapstackLoadingBackdrop text='Redirecting...' />
}

const isJoinWorkspaceLink = (link?: string) => {
  if (!link) return false
  let path = link
  try {
    const urlObj = new URL(link)
    path = urlObj.pathname + urlObj.search
  } catch (error) {
    // If parsing fails, we assume it's a relative URL
  }
  const regex = /^\/workspace\/[^/]+\/[^/]+\/admin\/join(\?.*)?$/
  return regex.test(path)
}
