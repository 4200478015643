export const formatCurrency = (
  value: number,
  { currency = 'USD', locale = 'en', format = 'none', decimalPlace = '0' } = {}
) => {
  if (value === null || value === undefined) return '~'

  const options = {}
  switch (format) {
    case 'compactShort':
      options['notation'] = 'compact'
      options['compactDisplay'] = 'short'
      break
    case 'compactLong':
      options['notation'] = 'compact'
      options['compactDisplay'] = 'long'
      break
    case 'pretty':
      options['notation'] = 'standard'
      break
  }

  options['minimumFractionDigits'] = Number(decimalPlace)

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...options
  }).format(value)
}
