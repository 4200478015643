import PropTypes from 'prop-types'

import { User } from '~ui-components/types/__generated/gql/graphql'

import type { FC, ReactNode } from 'react'
import { WorkspacesListMenuProps } from '~ui-components/components/molecules/WorkspacesListMenu'
import { VerticalLayout } from './vertical-layout'

interface DashboardLayoutSectionItem {
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  items?: DashboardLayoutSectionItem[]
  label?: ReactNode
  path?: string
  title: string
}

interface DashboardLayoutSection {
  items: DashboardLayoutSectionItem[]
  subheader?: string
}

interface DashboardLayoutProps {
  sections: DashboardLayoutSection[]
  user?: User
  workspaces: WorkspacesListMenuProps['workspaces']
  children?: ReactNode
  slots?: any
}

const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  return <VerticalLayout {...props} />
}

// DashboardLayout.propTypes = {
//   children: PropTypes.node
// }

export { DashboardLayout }
export type {
  DashboardLayoutProps,
  DashboardLayoutSection,
  DashboardLayoutSectionItem
}
