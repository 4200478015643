import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  CircularProgress,
  circularProgressClasses,
  Divider,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import {
  CaretLeft,
  CaretRight,
  SealCheck,
  Sparkle
} from '@phosphor-icons/react'

import React, { useEffect } from 'react'
import { Body, Head } from './CobuilderMapPurpose'

import { Onboard } from '~ui-components/components/molecules/Onboard'

const scrollerStyles = {
  '--scrollbar-color-thumb': 'lightgray',
  '--scrollbar-color-track': 'transparent',
  '--scrollbar-width': 'thin',
  '--scrollbar-width-legacy': '0.5rem',
  '@supports (scrollbar-width: auto)': {
    scrollbarColor: 'transparent transparent', // Hide scrollbar by default
    scrollbarWidth: 'none', // Completely hides the scrollbar by default
    '&:hover': {
      scrollbarColor:
        'var(--scrollbar-color-thumb) var(--scrollbar-color-track)', // Show on hover
      scrollbarWidth: 'var(--scrollbar-width)'
    }
  },
  '@supports selector(::-webkit-scrollbar)': {
    '::-webkit-scrollbar': {
      width: '0', // Hide scrollbar completely by default
      height: '0'
    },
    '&:hover::-webkit-scrollbar': {
      width: 'var(--scrollbar-width-legacy)', // Show scrollbar on hover
      height: 'var(--scrollbar-width-legacy)'
    },
    '::-webkit-scrollbar-thumb': {
      background: 'transparent'
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: 'var(--scrollbar-color-thumb)' // Scrollbar thumb visible on hover
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&:hover::-webkit-scrollbar-track': {
      background: 'var(--scrollbar-color-track)' // Scrollbar track visible on hover
    }
  }
}

export function CobuilderMapProcess(props) {
  const { slots } = props

  const Slots = {
    StepForm,
    Footer,
    ...slots
  }

  return (
    <>
      <Head
        heading="Let's bring your map to life while we process your data"
        subheading='These quick details will make your map more useful and team-friendly.'
      />

      <Body>
        <Step>
          <Slots.StepForm />
        </Step>
      </Body>

      <Slots.Footer />
    </>
  )
}

export function Footer(props) {
  const { status, slotProps, ...rest } = props

  return (
    <Stack
      direction='row'
      spacing={2}
      alignSelf='flex-end'
    >
      {status !== 'completed' && (
        <LoadingButton
          loading
          variant='contained'
          loadingPosition='start'
          sx={{
            pl: 5
          }}
          {...rest}
        >
          Processing data
        </LoadingButton>
      )}
      {status === 'completed' && (
        <LoadingButton
          variant='contained'
          startIcon={
            <SvgIcon
              component={Sparkle}
              inheritViewBox
            />
          }
          loadingPosition='start'
          sx={[
            slotProps?.GenerateMapButton.loading && {
              pl: 2
            }
          ]}
          {...slotProps?.GenerateMapButton}
        >
          Generate this map
        </LoadingButton>
      )}
    </Stack>
  )
}

export function GeocoderProgress(props) {
  const { status = 'analyzing', success = 0, total = 0, error = 0 } = props

  let heading, subheading, variant

  if (status === 'idle') return null

  if (status === 'analyzing') {
    variant = 'indeterminate'
    heading = 'Analyzing your data...'
  }

  if (status === 'processing') {
    variant = 'determinate'
    heading = 'Processing your data...'
    subheading = (
      <>
        Located <strong>{success}</strong> of <strong>{total}</strong> records,
        with <strong>{error}</strong> misses
      </>
    )
  }

  if (status === 'completed') {
    variant = 'determinate'
    heading = 'Data import complete'
    subheading = (
      <>
        Located <strong>{success}</strong> of <strong>{total}</strong> records,
        with <strong>{error}</strong> misses
      </>
    )
  }

  const value = Math.round((success / total) * 100) || 0

  return (
    <Stack
      component={Paper}
      elevation={8}
      direction='row'
      alignItems='center'
      sx={{
        position: 'absolute',
        top: 16,
        right: 16,
        p: 2,
        borderRadius: 2,
        width: 400
      }}
      spacing={2}
    >
      {status === 'completed' && (
        <>
          <SvgIcon
            component={SealCheck}
            inheritViewBox
            sx={{
              width: 48,
              height: 48,
              color: (theme) => theme.palette.success.main
            }}
          />
        </>
      )}
      {status !== 'completed' && (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant={variant}
            size={54}
            thickness={4}
            {...(variant === 'determinate' && {
              value
            })}
            sx={(theme) => ({
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round'
              }
            })}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography
              variant='next:caption'
              component='div'
              sx={{ color: 'text.secondary' }}
              fontWeight={700}
            >{`${value}%`}</Typography>
          </Box>
        </Box>
      )}
      <Stack direction='column'>
        <Typography
          variant='next:subtitle1'
          letterSpacing='tight'
          fontWeight={600}
        >
          {heading}
        </Typography>
        {subheading && (
          <Typography
            variant='next:subtitle2'
            letterSpacing='tight'
            fontWeight={500}
          >
            {subheading}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export function Step(props) {
  return (
    <Stack
      component={Paper}
      sx={{ p: 3, borderRadius: 2 }}
      spacing={3}
      elevation={8}
      {...props}
    />
  )
}

export function StepHeader(props) {
  return (
    <>
      <Typography
        variant='next:h5'
        letterSpacing='tight'
        fontWeight={700}
      >
        {props.heading}
      </Typography>

      {props.subheading && (
        <Typography
          variant='next:subtitle1'
          letterSpacing='tight'
        >
          {props.subheading}
        </Typography>
      )}
    </>
  )
}

export function StepController(props) {
  const { slotProps } = props
  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={2}
    >
      <Button
        variant='text'
        startIcon={
          <SvgIcon
            component={CaretLeft}
            inheritViewBox
          />
        }
        {...slotProps?.BackButton}
      >
        Back
      </Button>
      <Box flex={1}>
        <LinearProgress
          variant='determinate'
          {...slotProps?.LinearProgress}
        />
      </Box>
      <Button
        variant='text'
        endIcon={
          <SvgIcon
            component={CaretRight}
            inheritViewBox
          />
        }
        {...slotProps?.NextButton}
      >
        Next
      </Button>
    </Stack>
  )
}

type PropertiesViewerProps = {
  title?: string
  items?: { name: string; value: string; type: string }[]
  stepIndex: number
}

export function PropertiesViewer(props: PropertiesViewerProps) {
  const { title, items = [], stepIndex } = props

  type Refs = Record<number, React.RefObject<HTMLDivElement>>

  const steps: any[] = [
    {
      target: `.Property-Title`,
      content: 'Record name',
      placement: 'right',
      offset: 0
    },
    ...items.map(
      (item, index) =>
        ({
          target: `.Property-${index}`,
          content: 'Record value',
          placement: 'right',
          offset: 40
        } as any)
    )
  ]

  const refs = Array.from({ length: items.length }).reduce(
    (acc: Refs, value, index) => {
      acc[index] = React.createRef<HTMLDivElement>()
      return acc
    },
    {}
  )

  useEffect(() => {
    const dom = refs[stepIndex]?.current
    if (!dom) return
    dom.scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    })
  }, [stepIndex])

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          bottom: 16,
          left: 16,
          overflow: 'hidden'
        }}
      >
        <Paper
          component={Stack}
          direction='column'
          sx={{
            width: 300,
            height: '100%',
            maxHeight: 400,
            borderRadius: 2,
            ...scrollerStyles
          }}
        >
          <Box
            className='Property-Title'
            p={2}
          >
            <Typography
              variant='next:subtitle1'
              fontWeight={600}
            >
              {title}
            </Typography>
          </Box>
          <Divider flexItem />
          <Stack
            sx={{ flex: 1, overflowY: 'scroll' }}
            p={2}
            spacing={1}
          >
            {items.map((item, index) => {
              if (item.type === 'hidden') return null
              return (
                <Stack
                  ref={refs[index]}
                  key={`Property-${index}`}
                  className={`Property-${index}`}
                  direction='column'
                  spacing={1}
                >
                  {index < stepIndex ? (
                    <>
                      <Typography
                        variant='next:subtitle2'
                        fontWeight={600}
                      >
                        {item.name}
                      </Typography>
                      <Typography variant='next:body2'>{item.value}</Typography>
                    </>
                  ) : (
                    <>
                      <Skeleton
                        width='30%'
                        height={24}
                        animation={false}
                      />
                      <Skeleton
                        width='50%'
                        height={24}
                        animation={false}
                      />
                    </>
                  )}
                </Stack>
              )
            })}
          </Stack>
        </Paper>
      </Box>

      <Onboard
        index={stepIndex}
        steps={steps}
      />
    </>
  )
}

export function StepForm(props) {
  const { heading, subheading, children } = props

  return (
    <>
      <StepHeader
        heading={heading}
        subheading={subheading}
      />

      {children}
    </>
  )
}
