import { createMap } from '~map-creator/core/api'
import {
  uploadDataIdentifiers,
  uploadGeojson
} from '~map-creator/core/functions'

const submitCreateMap = async (context, payload) => {
  const { dataId, geojson, dataIdentifiers } = context
  const input = { ...payload, dataId }
  await uploadGeojson(dataId, geojson)
  // await uploadDataIdentifiers(dataId, dataIdentifiers)
  return await createMap(input)
}

export { submitCreateMap }
