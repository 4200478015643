const domainName = process.env.NEXT_PUBLIC_DOMAIN_NAME

const isProduction = !domainName?.endsWith('.mapstack.io')

const team = {
  name: 'team',
  feeDescription: 'per month',
  currency: '$',
  highlight: 'Most popular',
  includesTitle: 'Everything in free, plus:',
  includes: [
    '10,000 records per map',
    '100 guest users',
    'Census data',
    'Point of interest data'
  ],
  recordsLimit: 25_000,
  guestsLimit: 100,
  description:
    'Designed for growing teams that need to collaborate and share insights with ease.'
}

const business = {
  name: 'business',
  feeDescription: 'per month',
  currency: '$',
  includesTitle: 'Everything in team, plus:',
  includes: [
    '100,000 records per map',
    '250 guest users',
    'Premium geocoding',
    'Satellite imagery'
  ],
  recordsLimit: 100_000,
  guestsLimit: 250,
  description:
    'Ideal for organizations managing larger datasets and requiring advanced tools.'
}

export const PLANS = {
  free: {
    name: 'free',
    id: '',
    recordsLimit: 1_000,
    guestsLimit: 10,
    description:
      'Perfect for individuals and small projects looking to explore Mapstack’s core features.',
    includesTitle: 'Free plan includes',
    includes: [
      'Unlimited maps',
      'Unlimited geocodes',
      '1,000 records per map',
      '10 guest users'
    ]
  },
  team,
  teamMonthly: {
    ...team,
    id: isProduction
      ? 'price_1QfNX3Dek6HyW6KPptkkxa6O'
      : 'price_1QRFO0Dek6HyW6KPH4RXhcuh',
    price: 24
  },
  teamYearly: {
    ...team,
    id: isProduction
      ? 'price_1QfNX3Dek6HyW6KPFbHsjwCu'
      : 'price_1QXn83Dek6HyW6KPZsHlKrNA',
    price: 228
  },
  business,
  businessMonthly: {
    ...business,
    id: isProduction
      ? 'price_1QfNWzDek6HyW6KPTxbeRvVU'
      : 'price_1QXn83Dek6HyW6KPZsHlKrNA',
    price: 49
  },
  businessYearly: {
    ...business,
    id: isProduction
      ? 'price_1QfNWzDek6HyW6KP3cfmFZQt'
      : 'price_1QXnLyDek6HyW6KPLuWCMQU5',
    price: 468.0
  },
  enterprise: {
    id: 'enterprise',
    name: 'enterprise',
    price: 'Custom pricing',
    feeDescription: '',
    currency: '',
    description:
      'Tailored for large-scale operations with custom needs, integrations, and dedicated support.',
    includesTitle: 'Everything in business, plus:',
    includes: [
      '500,000 records per map',
      'Invite unlimited guests',
      'Advanced security & controls',
      'SAML SSO',
      'Audit log'
    ],
    recordsLimit: Infinity,
    guestsLimit: Infinity
  }
}

export const getLimits = (plan) => {
  if (!plan) return PLANS.free.recordsLimit
  return PLANS[plan].recordsLimit || PLANS.free.recordsLimit
}
