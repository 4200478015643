import {
  InputLabel,
  ListSubheader,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { Option } from '~ui-components/components/atoms/Option'

export const DefaultVisualization = (props) => {
  const { value, onChange, options = [], slots = {} } = props

  const pointOptions = options.filter((item) => item.parent === 'point')
  const aggregateOptions = options.filter((item) => item.parent === 'aggregate')

  return (
    <Stack spacing={1}>
      <InputLabel>Default Visualization</InputLabel>
      <Select
        value={value}
        onChange={onChange}
      >
        <ListSubheader>Point maps</ListSubheader>
        {pointOptions.map((option) => (
          <Option
            key={option.id}
            value={option.id}
          >
            <Stack
              direction='row'
              flex={1}
              gap={2}
              alignItems='center'
            >
              {option.Icon && (
                <option.Icon
                  weight='bold'
                  size={24}
                />
              )}
              <Typography variant='body2'>{option.label}</Typography>
            </Stack>
          </Option>
        ))}
        <ListSubheader>Aggregate maps</ListSubheader>
        {aggregateOptions.map((option) => (
          <Option
            key={option.id}
            value={option.id}
          >
            <Stack
              direction='row'
              flex={1}
              gap={2}
              alignItems='center'
            >
              {option.Icon && (
                <option.Icon
                  weight='bold'
                  size={24}
                />
              )}
              <Typography variant='body2'>{option.label}</Typography>
            </Stack>
          </Option>
        ))}
      </Select>
      {slots.input && <Stack spacing={1}>{slots.input}</Stack>}
    </Stack>
  )
}
