import { InputLabel, MenuItem, Select, Stack } from '@mui/material'
import {
  Calendar,
  CaretCircleDown,
  CurrencyDollar,
  EnvelopeSimple,
  EyeSlash,
  Hash,
  Link,
  Percent,
  Phone,
  TextAlignLeft,
  TextT
} from '@phosphor-icons/react'

export const HIDDEN_FIELD_OPTION = {
  label: 'Hidden',
  value: 'hidden',
  icon: EyeSlash
}

export const DEFINED_FIELD_OPTIONS = [
  {
    label: 'Single line text',
    value: 'text',
    icon: TextT
  },
  {
    label: 'Long text',
    value: 'longText',
    icon: TextAlignLeft
  },
  {
    label: 'Date',
    value: 'date',
    icon: Calendar
  },
  {
    label: 'Single select',
    value: 'select',
    icon: CaretCircleDown
  },
  {
    label: 'Number',
    value: 'number',
    icon: Hash
  },
  {
    label: 'Currency',
    value: 'currency',
    icon: CurrencyDollar
  },
  {
    label: 'Percentage',
    value: 'percentage',
    icon: Percent
  },
  {
    label: 'Email',
    value: 'email',
    icon: EnvelopeSimple
  },
  {
    label: 'URL',
    value: 'url',
    icon: Link
  },
  {
    label: 'Phone number',
    value: 'phoneNumber',
    icon: Phone
  }
]

export const PrimaryFieldOptions = (props) => {
  const {
    title = 'Primary field',
    value = '',
    handleOnchange = () => {},
    availableOptions = undefined
  } = props

  const options = availableOptions?.length
    ? DEFINED_FIELD_OPTIONS.filter((option) =>
        availableOptions.includes(option.value)
      )
    : DEFINED_FIELD_OPTIONS

  return (
    <Stack spacing={1}>
      <InputLabel>{title}</InputLabel>
      <Stack
        direction='row'
        spacing={2}
        sx={{ alignItems: 'center', flexWrap: 'wrap' }}
      >
        <Select
          fullWidth
          value={value}
          onChange={(event) => handleOnchange(event.target.value)}
          name='type'
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              <FieldItem
                icon={option.icon}
                label={option.label}
              />
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  )
}

export function FieldItem(props) {
  const { icon: Icon, label } = props
  return (
    <Stack
      direction='row'
      flex={1}
      gap={2}
      alignItems='center'
    >
      <Icon size={24} />
      {label}
    </Stack>
  )
}
