import { Box, Container, Stack, Typography } from '@mui/material'
import { CTAButtons } from './CTAButtons'

export function Section7() {
  return (
    <Container
      component={Box}
      py={8}
    >
      <Stack
        spacing={6}
        alignItems='center'
      >
        <Typography
          component='h2'
          variant='next:h2'
          fontWeight={700}
          textAlign='center'
        >
          Mapstack: Unlock Growth with Unified Location Insights
        </Typography>
        <Typography
          component='p'
          variant='next:h6'
          color='text.secondary'
          textAlign='center'
          maxWidth='80ch'
        >
          Mapstack transforms fragmented data into a single source of geographic
          truth, helping you spot growth areas, streamline logistics, and make
          strategic decisions—no steep learning curve.
        </Typography>

        <CTAButtons />
      </Stack>
    </Container>
  )
}
