import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import type { FC } from 'react'
import { useMemo, useState } from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { XClose } from '@untitled-ui/icons-react'

import { Stack } from '@mui/material'
import { MapFilterChips, MapFilterChipsProps } from '../MapVisualisationChips'
import { Scrollbar } from './scrollbar'

type MapDataGridProps = {
  rows?: any[]
  columns?: any[]
  MapFilterChipsProps: MapFilterChipsProps
  shouldShowMapFilterChips: boolean
  onClose?: any
}

const MapDataGrid: FC<MapDataGridProps> = (props) => {
  const {
    shouldShowMapFilterChips,
    rows = [],
    columns = [],
    MapFilterChipsProps,
    onClose = () => {}
  } = props
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const [sortingField, setSortingField] = useState<string>(
    columns[0].headerName
  )
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(25)

  const startIndex = page * rowsPerPage
  const endIndex = startIndex + rowsPerPage

  const sortedRows = useMemo(() => {
    return rows
      .sort((a, b) => {
        if (typeof a[sortingField] === 'string') {
          const comparison = a[sortingField].localeCompare(b[sortingField])
          const stringComparison =
            sortDirection === 'asc' ? comparison : -comparison
          return stringComparison
        }
        const numericComarision =
          sortDirection === 'asc'
            ? a[sortingField] - b[sortingField]
            : b[sortingField] - a[sortingField]
        return numericComarision
      })
      .slice(startIndex, endIndex)
  }, [rows, startIndex, endIndex, sortingField, sortDirection])

  const handleChangePage = (_, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSort = (field: string) => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    setSortingField(field)
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100',
        p: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        maxHeight: '-webkit-fill-available',
        overflow: 'hidden',
        zIndex: (theme) => theme.zIndex.modal + 1,
        top: 0,
        left: 0
      }}
    >
      <Card sx={{ height: '100%' }}>
        <Box
          sx={{ position: 'absolute', top: '17px', right: '20px', zIndex: 2 }}
        >
          <IconButton
            sx={[styles.closeButton]}
            color='inherit'
            onClick={onClose}
          >
            <SvgIcon>
              <XClose />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box
          sx={{
            height: '100%',
            flexDirection: 'column',
            display: 'flex',
            p: 1,
            padding: 0,
            bgcolor: 'background.paper',
            borderRadius: 1
          }}
        >
          {shouldShowMapFilterChips && (
            <Stack
              alignItems='center'
              direction='row'
              flexWrap='wrap'
              gap={1}
              my='14px'
              alignSelf='center'
            >
              <MapFilterChips {...MapFilterChipsProps} />
            </Stack>
          )}
          <Divider />
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <Scrollbar
              sx={{
                height: '100%',
                maxHeight: '100%',
                position: 'absolute !important',
                width: '100%',
                maxWidth: '100%'
              }}
            >
              <Table sx={{ minWidth: 1150 }}>
                <TableHead>
                  <TableRow>
                    {columns.map((col) => {
                      return (
                        <TableCell
                          onClick={() => handleSort(col.field)}
                          sortDirection={sortDirection}
                          key={col.field}
                        >
                          <Tooltip
                            enterDelay={300}
                            title='Sort'
                          >
                            <TableSortLabel
                              // active
                              direction={sortDirection}
                            >
                              {col.headerName}
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRows.map((row) => {
                    return (
                      <TableRow
                        hover
                        key={row.id}
                      >
                        {columns.map((col, i) => {
                          return (
                            <TableCell key={`${row.id}-${i}`}>
                              {col.valueFormatter({ value: row[col.field] })}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Scrollbar>
          </Box>
          <Divider />
          <Box>
            <TablePagination
              component='div'
              count={rows.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[25, 50, 100]}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export { MapDataGrid }
export default MapDataGrid

const styles = {
  closeButton: {
    '&:hover': {
      backgroundColor: '#e7e7e7'
    },
    width: '25px',
    height: '25px',
    backgroundColor: '#ddd9d9',
    borderRadius: '4px'
  }
}
