import toast, { Toaster as HotToaster, ToasterProps } from 'react-hot-toast'
import Notification from '~ui-components/components/molecules/Notification'
import { FC } from 'react'

const Toaster: FC<ToasterProps> = (props) => {
  return (
    <HotToaster
      position='bottom-right'
      toastOptions={{
        duration: 500
      }}
      {...props}
    >
      {(t) => (
        <Notification
          variant='filled'
          severity={'warning'}
          open
          position='bottom-right'
        >
          Oops, save failed, please try again.
        </Notification>
      )}
    </HotToaster>
  )
}

export { toast, Toaster }
