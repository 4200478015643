import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
  Container,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'

import { atom, useAtomValue, useSetAtom } from 'jotai'
import { ScopeProvider } from 'jotai-scope'

import {
  CaretDown,
  CoinVertical,
  Fingerprint,
  HeadCircuit,
  MapPinArea,
  Palette,
  SealCheck,
  SmileySad
} from '@phosphor-icons/react'
import { FAQPageJsonLd } from 'next-seo'
import { useId, useMemo } from 'react'

const faqsAtom = atom<
  {
    id: string
    question: string
    answer: string
  }[]
>([])

export function Section5() {
  return (
    <Container
      component={Box}
      py={8}
    >
      <Stack
        direction='column'
        spacing={6}
      >
        <Typography
          variant='next:h2'
          component='h2'
          fontWeight={700}
        >
          Frequently asked questions
        </Typography>

        <Stack spacing={6}>
          <Faqs>
            <Faq
              startIcon={HeadCircuit}
              question='Do I need technical skills to use Mapstack?'
              answer='Not at all! Mapstack is designed for non-technical users. With an intuitive interface, you can upload data and generate interactive maps in seconds—no coding or GIS expertise required.'
            />

            <Faq
              startIcon={CoinVertical}
              question='How much does it cost?'
              answer='Quickly adjust the visual layout of your data to get greater insight. From clusters, head maps, aggregates and more.'
            />

            <Faq
              startIcon={Palette}
              question='Can I use data from my existing tools?'
              answer='Not at all! Mapstack is designed for non-technical users. With an intuitive interface, you can upload data and generate interactive maps in seconds—no coding or GIS expertise required.'
            />

            <Faq
              startIcon={SmileySad}
              question='What if my data is messy or incomplete?'
              answer='Mapstack handles messy data with ease, automatically geocoding and enriching it to ensure your maps are accurate and insightful—even with incomplete addresses.'
            />

            <Faq
              startIcon={SealCheck}
              question='Why choose Mapstack for field sales?'
              answer='Mapstack unifies your CRM data, route planning, and mapping in one intuitive platform—eliminating tool fragmentation and saving you precious time on the road.'
            />

            <Faq
              startIcon={Fingerprint}
              question='How secure is my data?'
              answer='Your maps are private by default, and you control who can access them. Mapstack uses industry-standard encryption and ensures that your data is processed and stored securely.'
            />

            <Faq
              startIcon={MapPinArea}
              question='Can I optimize routes directly with Mapstack?'
              answer='Absolutely! Mapstack not only maps your client locations but also suggests efficient routes based on your data—helping you plan your day with maximum productivity.'
            />
          </Faqs>
        </Stack>
      </Stack>
    </Container>
  )
}

export function Faqs({ children }) {
  return (
    <ScopeProvider atoms={[faqsAtom]}>
      {children}
      <FaqsJsonLd />
    </ScopeProvider>
  )
}

export function Faq(props) {
  const { startIcon: StartIcon, question, answer, startIconProps = {} } = props

  const id = useId()
  const set = useSetAtom(faqsAtom)

  useMemo(() => {
    const next = {
      id,
      question,
      answer
    }

    set((prev) => {
      if (prev.some((item) => item.id === id)) {
        return prev.map((item) => (item.id === id ? next : item))
      }

      return [...prev, next]
    })
  }, [id, set, question, answer])

  return (
    <Accordion
      defaultExpanded
      sx={{
        [`&.${accordionClasses.root}`]: {
          borderRadius: 3,
          py: 3
        },
        [`>.${accordionSummaryClasses.root}`]: {
          px: 3
        },

        [`.${accordionDetailsClasses.root}`]: {
          px: 3
        }
      }}
    >
      <AccordionSummary
        expandIcon={
          <SvgIcon>
            <CaretDown weight='bold' />
          </SvgIcon>
        }
      >
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
        >
          {StartIcon && (
            <SvgIcon
              inheritViewBox
              component={StartIcon}
              weight='duotone'
              sx={{
                width: 48,
                height: 48
              }}
              {...startIconProps}
            />
          )}
          <Typography
            gutterBottom={false}
            component='h3'
            variant='next:h4'
            letterSpacing='tight'
            fontWeight={600}
          >
            {question}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          component='p'
          variant='next:h6'
        >
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

function FaqsJsonLd() {
  const faqs = useAtomValue(faqsAtom)
  return (
    <FAQPageJsonLd
      mainEntity={faqs.map((faq) => ({
        questionName: faq.question,
        acceptedAnswerText: faq.answer
      }))}
    />
  )
}
