import { Popover } from '@mui/material'

import { User, Workspace } from '~ui-components/types/__generated/gql/graphql'

import type { FC } from 'react'

export type NavAvatarDropdownUser = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'email'
>

export type NavAvatarDropdownWorkspace = Pick<Workspace, 'id' | 'name' | 'logo'>

interface NavAvatarDropdownProps {
  anchorEl: null | Element
  open?: boolean
  onClose?: () => void
}

export const NavAvatarDropdown: FC<NavAvatarDropdownProps> = (props) => {
  const { anchorEl, open, onClose } = props

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      sx={(theme) => ({
        zIndex: theme.zIndex.modal + 4
      })}
      PaperProps={{ sx: { minWidth: 200, maxWidth: 360 } }}
    ></Popover>
  )
}
