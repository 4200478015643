import { Typography } from '@mui/material'
import { Fragment } from 'react'

import { MapstackLoading } from '~ui-components/components/atoms/MapstackLoading'
import { MapFileInput } from '~ui-components/components/molecules/MapFileInput'
import { MapSampleData } from '~ui-components/components/molecules/MapSampleData'

interface MapCreateFormProps {
  variant: 'loading' | 'form'
  slots?: {
    MapFileAlert?: any
    MapFileInput?: any
  }
  slotProps?: {
    MapFileAlert?: any
    MapFileInput?: any
  }
}

const defaultSlots = {
  MapFileAlert: Fragment,
  MapFileInput
}

function MapCreateForm(props: MapCreateFormProps) {
  const { variant = 'form', slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <>
      {variant === 'form' ? (
        <>
          <Slots.MapFileAlert {...slotProps?.MapFileAlert} />

          <Typography
            variant='subtitle2'
            color='text.secondary'
          >
            Upload any data containing addresses, the addresses can be in any
            format.
          </Typography>

          <Slots.MapFileInput {...slotProps?.MapFileInput} />

          <MapSampleData />
        </>
      ) : null}

      {variant === 'loading' ? <MapstackLoading sx={{ py: 4 }} /> : null}
    </>
  )
}

export { MapCreateForm }
