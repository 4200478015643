import { Stack, InputLabel, Typography } from '@mui/material'
import { SortableList } from './SortableList'
import { MapProperty } from '~ui-components/types/__generated/gql/graphql'
import { FC } from 'react'

type MapFieldSettingListProps = {
  properties: MapProperty[]
  onChange: (properties: MapProperty[]) => void
  onPropertySettingsClick: (property: MapProperty) => void
}

export const MapFieldSettingList: FC<MapFieldSettingListProps> = ({
  properties = [],
  onChange,
  onPropertySettingsClick
}) => {
  return (
    <Stack spacing={1}>
      <InputLabel>Map Field Settings</InputLabel>
      {properties.length === 0 && (
        <Typography color='text.secondary'>No data</Typography>
      )}
      <SortableList
        items={properties}
        setItems={onChange}
        onClickItemSettings={onPropertySettingsClick}
      />
    </Stack>
  )
}
