import { Box, Chip, Container, Paper, Stack, Typography } from '@mui/material'

import { Video } from '~ui-components/components/atoms/Video'
import ringEffect from '~ui-components/utilities/ringEffect'
import { CTAButtons } from './CTAButtons'

export function Section1() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <Stack
        spacing={6}
        alignItems='center'
      >
        <Stack
          spacing={4}
          alignItems='center'
        >
          <Chip
            label='Territory management & analysis'
            size='medium'
          />
          <Typography
            component='h1'
            variant='next:h2'
            fontWeight={900}
            textAlign='center'
          >
            Create & optimize your territories in minutes
          </Typography>
        </Stack>

        <Typography
          component='p'
          variant='next:h5'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Stop guessing coverage. Instantly define or refine territories, track
          performance in real time, and make data-driven decisions—no
          specialized software needed.
        </Typography>

        <CTAButtons />

        <Box
          component={Paper}
          variant='elevation'
          elevation={8}
          sx={(theme) => ({
            width: '100%',
            height: '100%',
            maxHeight: '600px',
            aspectRatio: '16/9',
            borderRadius: 2,
            overflow: 'hidden',
            '&:hover': ringEffect({
              ringColor: theme.palette.primary.main,
              ringWidth: '4px',
              borderRadius: 2
            }),
            '--plyr-color-main': theme.palette.primary.main
          })}
        >
          <Video
            muted
            autoplay
            loop={{
              active: true
            }}
            source={{
              type: 'video',
              sources: [
                {
                  src: `${process.env.NEXT_PUBLIC_MS_ASSETS_URL}/public/videos/sales-demo-2.mp4`,
                  type: 'video/mp4'
                }
              ]
            }}
          />
        </Box>
      </Stack>
    </Box>
  )
}
