import {
  Avatar,
  Button,
  Dialog,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { Info } from '@phosphor-icons/react'

export const MapCreateDesktopDialog = (props) => {
  const { open, onClose, slotProps, ...rest } = props

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      onClose={onClose}
      {...rest}
      sx={{
        p: 2
      }}
    >
      <Paper
        component={Stack}
        spacing={2}
        elevation={12}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          sx={{
            backgroundColor: 'secondary.lightest',
            color: 'secondary.main',
            width: 60,
            height: 60
          }}
        >
          <SvgIcon
            fontSize='large'
            component={Info}
            inheritViewBox
          ></SvgIcon>
        </Avatar>

        <Typography
          variant='h5'
          textAlign='center'
        >
          Maps can only be created on desktop
        </Typography>

        <Typography
          align='center'
          color='text.secondary'
          sx={{ mt: 1 }}
          variant='body2'
        >
          To build a new map, please switch to a desktop browser. You can still
          view and interact with maps on mobile.
        </Typography>

        <Button
          fullWidth
          color='primary'
          size='large'
          variant='contained'
          {...slotProps?.Button}
        >
          Got it, I'll use desktop
        </Button>
      </Paper>
    </Dialog>
  )
}
