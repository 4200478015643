import { CreateMapButton } from 'create-map-button'
import { NavDrawer as Drawer } from 'ui-components'
import { useAuth } from 'user-auth'

import { Logo } from '../Logo'
import { NavCommunity } from '../NavCommunity'

export function NavDrawer(props) {
  const { authenticated } = useAuth()
  return (
    <Drawer
      {...props}
      authenticated={authenticated}
      slots={{
        Logo,
        NavCommunity,
        CreateMapButton
      }}
    />
  )
}
