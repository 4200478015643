import { useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { Toaster } from '~ui-components/components/atoms'

const drawerWidth = 390

export interface Props {
  slots?: any
  slotProps?: any
}

export function MapLayout(props: Props) {
  const { slots: Slots, slotProps } = props

  const theme = useTheme()
  const hideNav = useMediaQuery(theme.breakpoints.up(1280))

  return (
    <>
      <Toaster />
      {Slots?.Header && <Slots.Header {...slotProps?.Header} />}
      {hideNav && <>{Slots?.Nav && <Slots.Nav />}</>}
      <Box
        component='main'
        sx={{
          overflow: 'hidden',
          display: 'flex',
          height: '100vh',
          width: '100vw',
          flexDirection: 'column'
        }}
      >
        {Slots?.FreeTrialDialog && <Slots.FreeTrialDialog />}
        {Slots?.MapSearch && <Slots.MapSearch />}
        <Box sx={{ display: 'flex', flex: '1 1 auto', overflow: 'hidden' }}>
          {Slots?.SideBar && <Slots.SideBar />}
          <Box
            sx={{
              flexGrow: 1,
              padding: 3,
              marginLeft: `-${drawerWidth}px`,
              p: 0,
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              overflow: 'hidden'
            }}
          >
            <Box
              sx={{
                p: 0,
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                overflow: 'hidden'
              }}
            >
              <Box
                sx={{ display: 'flex', flex: '1 1 auto', overflow: 'hidden' }}
              >
                <Box
                  sx={{
                    zIndex: 2,
                    position: 'absolute',
                    width: '100%',
                    maxWidth: '600px',
                    bottom: '0',
                    marginBottom: '66px',
                    ml: 2
                  }}
                >
                  {Slots?.Legend && <Slots.Legend />}
                </Box>
                {Slots?.Main && <Slots.Main />}
              </Box>
              {Slots.MapToolbar && <Slots.MapToolbar />}
            </Box>
          </Box>
        </Box>
      </Box>
      {hideNav && <>{Slots?.Footer && <Slots.Footer />}</>}
    </>
  )
}
