import { Box, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'
import { Fragment } from 'react'
import createMapTrans from './image.png'

interface AuthProps {
  slots?: {
    Form?: React.ElementType
  }
  slotProps?: {
    Form?: any
  }
}

function Auth(props: AuthProps) {
  const { slots = {}, slotProps = {} } = props

  const Slots = {
    Form: Fragment,
    ...slots
  }

  return (
    <Box
      sx={{
        minHeight: '100dvh',
        backgroundColor: 'background.default',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: {
          xs: 'column-reverse',
          md: 'row'
        }
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'neutral.800',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          color: 'common.white',
          display: 'flex',
          flex: {
            xs: '0 0 auto',
            md: '1 1 auto'
          },
          justifyContent: 'center',
          p: {
            xs: 4,
            md: 8
          }
        }}
      >
        <Box
          maxWidth='lg'
          width='100%'
        >
          <Box
            position='relative'
            width='300px'
            height='300px'
            sx={{ mb: 4 }}
          >
            <NextImage
              alt='Sign up today!'
              src={createMapTrans}
              fill
            />
          </Box>

          <Typography
            sx={{ mb: 1 }}
            variant='h3'
          >
            What will you map today?
          </Typography>

          <Typography
            variant='h4'
            color='text.secondary'
          >
            Create amazing maps, share important stories, <br />
            discover new insights.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flex: {
            xs: '1 1 auto',
            md: '0 0 auto'
          },
          flexDirection: 'column',
          justifyContent: {
            md: 'center'
          },
          maxWidth: '100%',
          p: {
            xs: 4,
            md: 8
          },
          width: {
            md: 600
          }
        }}
      >
        <Stack
          spacing={3}
          sx={{ justifyContent: 'center' }}
        >
          <Slots.Form {...slotProps?.Form} />
        </Stack>
      </Box>
    </Box>
  )
}

export { Auth }
