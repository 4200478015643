import { sendEvent } from './sendEvent'

export function signUpEvent(
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  isInvite: boolean
) {
  sendEvent('event', 'sign_up', {
    user_id: id,
    user_first_name: firstName,
    user_last_name: lastName,
    user_email: email,
    is_invite: isInvite
  })
}
