import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { PropertyItem } from './PropertyItem'

export const SortableItem = (props) => {
  const { id, item, handleSetVisible, handleSettingClick } = props
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef
  } = useSortable({
    id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.2 : undefined
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <PropertyItem
        property={item}
        listeners={listeners}
        setActivatorNodeRef={setActivatorNodeRef}
        handleSetVisible={handleSetVisible}
        handleSettingClick={handleSettingClick}
      />
    </div>
  )
}
