import { Stack, Typography } from '@mui/material'
import { Info as InfoIcon } from '@phosphor-icons/react'

export const MapSearchHeader = (props) => {
  return (
    <Stack
      alignItems='center'
      direction='row'
      spacing={1}
    >
      <InfoIcon fontSize={28} />
      <Typography variant='h6'>Location details</Typography>
    </Stack>
  )
}
