import { signOut as amplifySignOut, signInWithRedirect } from 'aws-amplify/auth'
import { useRouter } from 'next/router'
import { baseUrl, getSearchLink } from 'utils'

import { setAuth } from 'user-auth/states/auth'
import { postSignInRedirect } from 'user-auth/states/postSignInRedirect'
import { postSignOutRedirect } from 'user-auth/states/postSignOutRedirect'

type GoogleMethod = 'Google'
type FacebookMethod = 'Facebook'

type IdentifyProvider = GoogleMethod | FacebookMethod

type AuthOptions = {
  provider?: IdentifyProvider
  redirect?: string
}

const useFederatedAuth = () => {
  const router = useRouter()

  const gotoSignInPage = ({
    redirect
  }: { redirect?: string | boolean } = {}) => {
    if (redirect) postSignInRedirect.save(redirect || window.location.href)
    router.push('/sign-in')
  }

  const signIn = async (options?: AuthOptions) => {
    const { provider = 'Google', redirect: redirectOpt } = options || {}

    if (redirectOpt) {
      const toUrl = `${baseUrl()}${
        redirectOpt.startsWith('/') ? redirectOpt : `/${redirectOpt}`
      }`

      postSignInRedirect.save(toUrl)
    }

    setAuth({ loading: true })

    try {
      await signInWithRedirect({ provider })
    } catch (error: any) {
      console.error(error)
      window.location.href = redirectOpt || getSearchLink()
    }
  }

  const signOut = async (options?) => {
    const { preventRedirect = false } = options || {}

    if (preventRedirect) {
      postSignOutRedirect.save(window.location.href)
    }

    try {
      await amplifySignOut()
    } catch (error) {
      console.error(error)
    }
  }

  return { signIn, signOut, gotoSignInPage }
}

export { useFederatedAuth }
export default useFederatedAuth
