import {
  MapFeatureInfo,
  PolygonMapFeatureInfo,
  useLayoutEffect
} from 'ui-components'
import { useDispatch } from '~map-feature-info/states'
import {
  clearSelectedFeature,
  setMapData,
  useFeatureInfo
} from '~map-feature-info/states/map'

import { Map } from '~map-feature-info/types/__generated/gql/graphql'

type Props = {
  isEmbed: boolean
  map: Map
  title: string
  open: boolean
  isPolygonLayout?: boolean
}

const App = (props: Props) => {
  const {
    map,
    open,
    title,
    isEmbed = false,
    isPolygonLayout = false,
    ...rest
  } = props

  const dispatch = useDispatch()
  const feature = useFeatureInfo()

  useLayoutEffect(() => {
    dispatch(setMapData(map))
  }, [dispatch, map])

  const handleCloseEnd = () => {
    dispatch(clearSelectedFeature())
  }

  if (isPolygonLayout)
    return (
      <PolygonMapFeatureInfo
        {...rest}
        isEmbed={isEmbed}
        title={title}
        feature={feature}
        open={open}
        onCloseEnd={handleCloseEnd}
      />
    )

  return (
    <MapFeatureInfo
      {...rest}
      isEmbed={isEmbed}
      title={title}
      feature={feature}
      open={open}
    />
  )
}

export { App }
