import {
  Avatar,
  Button,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  menuClasses,
  MenuList,
  MenuItem as MuiMenuItem,
  Popover,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import {
  CreditCard,
  Gear,
  Mailbox,
  MapTrifold,
  SignOut,
  Trophy,
  UserCircle,
  UsersThree
} from '@phosphor-icons/react'
import NextLink from 'next/link'
import { ellipsis } from 'polished'
import type { FC } from 'react'
import React from 'react'
import SimpleBar from 'simplebar-react'
import { getUserLink, getWorkspaceLink } from 'utils'

import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'
import { Workspace } from '~ui-components/types/__generated/gql/graphql'
import { stringAvatar } from '~ui-components/utilities/stringAvatar'

export type NavAvatarDropdownWorkspace = Pick<Workspace, 'id' | 'name' | 'logo'>

export interface NavAvatarDropdownProps {
  anchorEl: null | Element
  open: boolean
  slots?: any
  slotProps?: any
  onClose?: () => void
}

export const NavAvatarDropdown: FC<NavAvatarDropdownProps> = (props) => {
  const { open, anchorEl, onClose, slots, slotProps } = props

  const Slots = {
    NavAvatarUser,
    WorkspaceInput,
    WorkspaceMenuList,
    UserMenuList,
    ...slots
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disableScrollLock
      onClose={onClose}
      slotProps={{
        root: {},
        paper: {
          sx: {
            width: 280,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            overflow: 'hidden',
            height: 'auto',
            borderRadius: 2
          }
        }
      }}
    >
      <Stack
        component={Scrollbar}
        flex={1}
        sx={{
          height: '100%',
          maxHeight: 'calc(100dvh - 64px)'
        }}
      >
        <Slots.NavAvatarUser {...slotProps?.NavAvatarUser} />
        <Divider flexItem />
        <Stack
          p={2}
          spacing={2}
        >
          <Slots.WorkspaceInput {...slotProps?.WorkspaceInput} />

          <Stack>
            <Slots.WorkspaceMenuList />
          </Stack>
        </Stack>

        <Divider flexItem />

        <Stack p={2}>
          <Slots.UserMenuList />
        </Stack>
      </Stack>
    </Popover>
  )
}

function MenuItem(props) {
  const { label, icon, href, ...rest } = props
  return (
    <Button
      {...(href ? { component: NextLink, href } : {})}
      variant='text'
      color='inherit'
      size='small'
      sx={{
        justifyContent: 'flex-start'
      }}
      startIcon={
        <SvgIcon
          component={icon}
          inheritViewBox
          sx={{
            width: 24,
            height: 24
          }}
        />
      }
      {...rest}
    >
      {label}
    </Button>
  )
}

export function WorkspaceMenuList(props) {
  const { workspace = {} } = props
  const { id, name, plan } = workspace

  return (
    <>
      <MenuItem
        href={getWorkspaceLink({
          workspaceId: id,
          workspaceName: name,
          tab: 'maps'
        })}
        label='Maps'
        icon={MapTrifold}
      />

      <MenuItem
        href={getWorkspaceLink({
          workspaceId: id,
          workspaceName: name,
          tab: 'members'
        })}
        label='Manage team'
        icon={UsersThree}
      />

      <MenuItem
        href={getWorkspaceLink({
          workspaceId: id,
          workspaceName: name,
          tab: 'settings'
        })}
        label='Manage workspace'
        icon={Gear}
      />

      {plan !== 'free' && (
        <MenuItem
          href={getWorkspaceLink({
            workspaceId: id,
            workspaceName: name,
            tab: 'billing'
          })}
          label='Plans and billing'
          icon={CreditCard}
        />
      )}

      {plan === 'free' && (
        <MenuItem
          href={getWorkspaceLink({
            workspaceId: id,
            workspaceName: name,
            tab: 'upgrade'
          })}
          label='Upgrade'
          icon={Trophy}
        />
      )}
    </>
  )
}

export function UserMenuList(props) {
  const { user, slotProps } = props

  return (
    <>
      <MenuItem
        href={getUserLink({
          userId: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName
        })}
        label='Account'
        icon={UserCircle}
      />

      <MenuItem
        href='mailto: sales@mapstack.io'
        label='Contact sales'
        icon={Mailbox}
      />

      <MenuItem
        label='Log out'
        icon={SignOut}
        {...slotProps?.SignOutMenuItem}
      />
    </>
  )
}

export function NavAvatarUser(props) {
  const { userId, firstName, lastName, email } = props

  return (
    <Button
      component={NextLink}
      href={getUserLink({ userId, firstName, lastName })}
      variant='text'
      color='inherit'
      sx={{
        p: 2,
        width: '100%',
        borderRadius: 0,
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      <Typography variant='next:subtitle1'>
        {firstName} {lastName}
      </Typography>
      <Typography
        variant='next:subtitle2'
        color='text.secondary'
      >
        {email}
      </Typography>
    </Button>
  )
}

export function WorkspaceInput(props) {
  const { options, slots, slotProps, ...rest } = props

  const theme = useTheme()

  const { onClick, ...workspaceItemRest } = slotProps?.WorkspaceItem

  return (
    <FormControl>
      <FormLabel
        sx={{
          color: 'text.primary'
        }}
      >
        Workspace
      </FormLabel>
      <TextField
        select
        size='small'
        sx={{
          mt: 1
        }}
        InputLabelProps={{
          shrink: false
        }}
        inputProps={{
          sx: {
            py: 1.5
          }
        }}
        SelectProps={{
          MenuProps: {
            MenuListProps: {
              component: React.forwardRef((props, ref) => (
                <SimpleBar
                  style={
                    theme.unstable_sx({
                      p: 1,
                      height: '100%',
                      maxHeight: 340,
                      overflowX: 'hidden'
                    }) as any
                  }
                >
                  <MenuList
                    {...props}
                    ref={ref}
                  />
                </SimpleBar>
              ))
            },
            sx: {
              [`.${menuClasses.list}`]: {
                py: 0
              }
            }
          },
          renderValue: (value) => {
            const item = options.find((option) => option.id === value)

            return (
              <Stack
                spacing={1}
                direction='row'
                alignItems='center'
                {...rest}
              >
                <Avatar
                  src={item?.logo}
                  sx={{
                    width: 26,
                    height: 26
                  }}
                  {...stringAvatar(item?.name)}
                />
                <Typography
                  sx={[{ flex: 1 }, ellipsis('20ch')]}
                  variant='next:subtitle2'
                >
                  {item?.name}
                </Typography>
                <Chip
                  label={item?.plan}
                  size='small'
                  sx={{
                    textTransform: 'capitalize'
                  }}
                />
              </Stack>
            )
          }
        }}
        {...rest}
      >
        {options.map((option) => {
          return (
            <MuiMenuItem
              key={option?.id}
              value={option?.id}
              sx={{
                display: 'flex',
                direction: 'row',
                gap: 1,
                px: 1,
                borderRadius: 1,
                justifyContent: 'flex-start',
                fontWeight: 500
              }}
              onClick={(event) => {
                onClick?.(event, { props: { value: option?.id } })
              }}
              {...workspaceItemRest}
            >
              <Avatar
                component='div'
                src={option?.logo}
                sx={{
                  width: 26,
                  height: 26
                }}
                {...stringAvatar(option?.name)}
              />
              <Typography
                sx={[{ flex: 1 }, ellipsis('20ch')]}
                variant='next:subtitle2'
              >
                {option?.name}
              </Typography>
              <Chip
                label={option?.plan}
                size='small'
                sx={{
                  textTransform: 'capitalize'
                }}
              />
            </MuiMenuItem>
          )
        })}
        <Stack
          sx={{
            pt: 1,
            position: 'relative',
            bottom: 0,
            left: 0,
            width: '100%'
          }}
        >
          <Button
            color='primary'
            variant='contained'
            size='small'
            sx={{ borderRadius: 1 }}
            {...slotProps?.CreateWorkspaceButton}
          >
            Create workspace
          </Button>
        </Stack>
      </TextField>
    </FormControl>
  )
}
