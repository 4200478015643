import { Box, Button, MenuItem, Select } from '@mui/material'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { getCreateMapLink } from 'utils'

import Dialog, {
  DialogActions
} from '~ui-components/components/molecules/Dialog'

const SelectWorkspace = (props) => {
  const { workspaces, value, onChange } = props

  const handleChange = (event) => {
    const id = event.target.value
    const workspace = workspaces.find((workspace) => workspace?.id === id)
    onChange(workspace)
  }

  return (
    <Select
      value={value?.id}
      onChange={handleChange}
      defaultValue={workspaces[0]?.id}
      fullWidth
      MenuProps={{ disablePortal: true }}
    >
      {workspaces?.map((workspace) => (
        <MenuItem
          data-testid={workspace.id}
          key={workspace.id}
          value={workspace.id}
          sx={{ zIndex: 9999 }}
        >
          {workspace.name}
        </MenuItem>
      ))}
    </Select>
  )
}

const AddMapDialog = (props) => {
  const {
    workspaces: workspacesProps = [],
    open,
    onClose = () => {},
    ...rest
  } = props
  const router = useRouter()

  const [selectedWorkspace, setSelectedWorkspace] = useState(
    () => workspacesProps[0]
  )

  useEffect(() => {
    setSelectedWorkspace(workspacesProps[0])
  }, [workspacesProps])

  const handleWorkspaceChange = (workspace) => {
    setSelectedWorkspace(workspace)
  }

  const handleCreateMapClick = () => {
    if (!selectedWorkspace) return

    router.push(
      getCreateMapLink({
        workspaceId: selectedWorkspace.id,
        workspaceName: selectedWorkspace.name
      })
    )
  }

  if (!workspacesProps.length) return null

  return (
    <Dialog
      data-testid='AddMapDialog'
      open={open}
      cancelText='Cancel'
      confirmText='Confirm'
      fullWidth
      noConfirm
      noCancel
      title='Select workspace'
      onClose={onClose}
      dialogActionsComponent={
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2
          }}
        >
          <NextLink
            href='/workspace/create'
            passHref
            legacyBehavior
          >
            <Button
              color='primary'
              variant='outlined'
            >
              Create workspace
            </Button>
          </NextLink>

          <Button
            data-testid='CreateMapButton'
            color='primary'
            variant='contained'
            onClick={handleCreateMapClick}
          >
            Create map
          </Button>
        </DialogActions>
      }
      {...rest}
    >
      <Box mb={2}>In which workspace should we place the new map?</Box>
      <SelectWorkspace
        workspaces={workspacesProps}
        value={selectedWorkspace}
        onChange={handleWorkspaceChange}
      />
    </Dialog>
  )
}

export { AddMapDialog }
