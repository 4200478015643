import { AppHeaderLogo as View } from 'ui-components'
import { useAuth } from 'user-auth'

export const Logo = (props) => {
  const { authenticated } = useAuth()

  return (
    <View
      {...props}
      href={authenticated ? '/search' : '/'}
    />
  )
}
