import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Unstable_Grid2 as Grid,
  InputBase,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  alpha
} from '@mui/material'
import { Camera01, Image01 } from '@untitled-ui/icons-react/build/esm'
import { useRef } from 'react'

const WorkspaceSettingsTab = ({
  form,
  warningMessage,
  deleteWorkspaceLoading,
  onImageChange,
  onDeleteWorkspace
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleDeleteClick = () => {
    setIsConfirmOpen(true)
  }

  const confirmDelete = () => {
    onDeleteWorkspace()
    setIsConfirmOpen(false)
  }

  return (
    <Stack spacing={4}>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
            mb={1}
          >
            <Grid
              xs={12}
              md={4}
            >
              <Typography variant='h6'>Basic details</Typography>
            </Grid>
            <Grid
              xs={12}
              md={8}
            >
              <Stack spacing={3}>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <Box
                    sx={{
                      borderColor: 'neutral.300',
                      borderRadius: '50%',
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      p: '4px'
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '50%',
                        height: '100%',
                        width: '100%',
                        position: 'relative'
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          backgroundColor: (theme) =>
                            alpha(theme.palette.neutral[700], 0.5),
                          borderRadius: '50%',
                          color: 'common.white',
                          cursor: 'pointer',
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                          left: 0,
                          opacity: 0,
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          zIndex: 1,
                          '&:hover': {
                            opacity: 1
                          }
                        }}
                      >
                        <InputBase
                          inputRef={fileInputRef}
                          inputProps={{ accept: 'image/*', multiple: false }}
                          type='file'
                          value=''
                          onChange={(e) => {
                            if (e.target['files']?.length > 0) {
                              onImageChange(e.target['files'][0])
                            }
                          }}
                          hidden
                          sx={{ display: 'none' }}
                        />
                        <Stack
                          alignItems='center'
                          direction='row'
                          spacing={1}
                          onClick={() => fileInputRef.current!.click()}
                        >
                          <SvgIcon color='inherit'>
                            <Camera01 />
                          </SvgIcon>
                          <Typography
                            color='inherit'
                            variant='subtitle2'
                            sx={{ fontWeight: 700 }}
                          >
                            Select
                          </Typography>
                        </Stack>
                      </Box>
                      <Avatar
                        src={form.status.logo}
                        sx={{ height: 100, width: 100 }}
                      >
                        <SvgIcon>
                          <Image01 />
                        </SvgIcon>
                      </Avatar>
                    </Box>
                  </Box>
                  <Button
                    variant='text'
                    color='inherit'
                    size='small'
                    onClick={() => fileInputRef.current!.click()}
                  >
                    Change
                  </Button>
                </Stack>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <TextField
                    required
                    label='Workspace Name'
                    sx={{ flexGrow: 1 }}
                    name='name'
                    value={form.values.name}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={form.touched.name && Boolean(form.errors.name)}
                    helperText={form.touched.name && form.errors.name}
                  />
                </Stack>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <TextField
                    label='Workspace Description'
                    sx={{
                      flexGrow: 1,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'dashed'
                      }
                    }}
                    name='description'
                    value={form.values.description}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={
                      form.touched.description &&
                      Boolean(form.errors.description)
                    }
                    helperText={
                      form.touched.description && form.errors.description
                    }
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              xs={12}
              display='flex'
              justifyContent='flex-end'
            >
              <Stack
                alignItems='center'
                direction='row'
              >
                <LoadingButton
                  loading={form.isSubmitting}
                  disabled={!form.isValid}
                  variant='contained'
                  onClick={() => form.handleSubmit()}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={3}
            >
              <Typography variant='h6'>Delete Workspace</Typography>
            </Grid>
            <Grid
              xs={12}
              md={8}
            >
              <Stack
                alignItems='flex-start'
                spacing={3}
              >
                <Typography variant='subtitle1'>
                  This action is irreversible
                </Typography>
                <Box>
                  <LoadingButton
                    loading={deleteWorkspaceLoading}
                    disabled={!!warningMessage}
                    onClick={handleDeleteClick}
                    color='error'
                    variant='outlined'
                  >
                    Delete this workspace
                  </LoadingButton>
                  {!!warningMessage && (
                    <FormHelperText error>{warningMessage}</FormHelperText>
                  )}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        open={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this workspace? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setIsConfirmOpen(false)}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            color='error'
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export { WorkspaceSettingsTab }
