import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import {
  Avatar,
  Button,
  ButtonProps,
  Dialog,
  DialogProps,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { ArrowUp } from '@untitled-ui/icons-react'

interface GeocoderDialogProps extends DialogProps {
  records: number
  limits: number
  onPrimaryClick?: () => void
  onSecondaryClick?: () => void
  PrimaryActionProps?: ButtonProps
  SecondaryActionProps?: LoadingButtonProps
}

export function GeocoderDialog(props: GeocoderDialogProps) {
  const {
    records,
    limits,
    open,
    onClose,
    onPrimaryClick,
    onSecondaryClick,
    PrimaryActionProps,
    SecondaryActionProps,
    ...rest
  } = props

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      onClose={onClose}
      {...rest}
      sx={(theme) => ({
        p: 3,
        [theme.breakpoints.down(528)]: {
          p: 0
        }
      })}
    >
      <Paper
        component={Stack}
        spacing={2}
        elevation={12}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          sx={{
            backgroundColor: 'success.lightest',
            color: 'success.main',
            width: 60,
            height: 60
          }}
        >
          <SvgIcon fontSize='large'>
            <ArrowUp />
          </SvgIcon>
        </Avatar>

        <Typography variant='h5'>Dataset record limit reached</Typography>

        <Typography
          align='center'
          color='text.secondary'
          variant='body2'
        >
          The number of records in the current dataset ({records}) exceeds the
          limit of your current plan ({limits}), upgrade to continue with this
          upload.
        </Typography>

        <Button
          fullWidth
          size='large'
          variant='contained'
          onClick={onPrimaryClick}
          {...PrimaryActionProps}
        >
          View plans and pricing
        </Button>

        <LoadingButton
          fullWidth
          size='large'
          variant='text'
          color='secondary'
          onClick={onSecondaryClick}
          {...SecondaryActionProps}
        >
          Continue with {limits} addresses only
        </LoadingButton>
      </Paper>
    </Dialog>
  )
}
