import { Box, Link, Paper, SvgIcon, Typography } from '@mui/material'
import Map01 from '@untitled-ui/icons-react/build/esm/Map01'
import NextLink from 'next/link'
import { FC, Fragment } from 'react'

import { AlertRobot } from '~ui-components/components/molecules/AlertRobot'

export type EmptyMapListFallbackProps = {
  isWorkspaceGuest?: boolean
  noResultsText?: string
  defaultMapUrl?: string
  canCreateMap?: boolean
  slots?: any
  slotProps?: any
}

const Empty = () => null

export const EmptyMapListFallback: FC<EmptyMapListFallbackProps> = (props) => {
  const {
    isWorkspaceGuest = false,
    noResultsText: _noResultsText,
    defaultMapUrl,
    canCreateMap = false,
    slots,
    slotProps
  } = props

  const Slots = {
    CreateMapButton: Empty,
    ...slots
  }

  const noResultsText = _noResultsText || 'This workspace has no maps!'

  return (
    <Fragment>
      <Box
        data-testid='BoxHasNoMap'
        component={Paper}
        variant='outlined'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        minHeight={300}
        sx={{
          borderStyle: 'dashed'
        }}
      >
        <SvgIcon
          fontSize='large'
          sx={{ opacity: 0.4 }}
        >
          <Map01 />
        </SvgIcon>
        <Box
          component={Typography}
          variant='h5'
          fontWeight={700}
          mt={2}
          sx={{
            opacity: 0.4
          }}
        >
          {noResultsText}
        </Box>
        {canCreateMap && (
          <Slots.CreateMapButton
            sx={{ mt: 3 }}
            {...slotProps?.CreateMapButton}
          />
        )}
      </Box>
      {isWorkspaceGuest && (
        <AlertRobot m={3}>
          You don&apos;t have permission to create a map on this workspace. You
          can however create a map on your personal workspace.
          <Link
            component={NextLink}
            sx={{
              color: 'inherit'
            }}
            href={defaultMapUrl || '#'}
            data-testid='SampleMapLink'
            download
            target='_blank'
          >
            Create map
          </Link>
          !
        </AlertRobot>
      )}
      {canCreateMap ? (
        <AlertRobot m={3}>
          Don&apos;t have any data right now? You can borrow{' '}
          <Link
            sx={{
              color: 'inherit'
            }}
            href={defaultMapUrl || '#'}
            data-testid='SampleMapLink'
            download
            target='_blank'
          >
            some of mine
          </Link>
          !
        </AlertRobot>
      ) : null}
    </Fragment>
  )
}
