import { Box, Container, Stack, Typography } from '@mui/material'
import { CTAButtons } from './CTAButtons'

export function Section6() {
  return (
    <Container
      component={Box}
      py={8}
    >
      <Stack
        spacing={6}
        alignItems='center'
      >
        <Typography
          component='h2'
          variant='next:h2'
          fontWeight={700}
          textAlign='center'
        >
          Mapstack: Effortless Sales Mapping
        </Typography>
        <Typography
          component='p'
          variant='next:h6'
          color='text.secondary'
          textAlign='center'
          maxWidth='80ch'
        >
          Mapstack transforms your CRM and spreadsheet data into interactive
          maps—so you can quickly see client clusters, plan optimal routes, and
          boost your sales productivity without the hassle of multiple tools.
        </Typography>

        <CTAButtons />
      </Stack>
    </Container>
  )
}
