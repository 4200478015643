import {
  Breadcrumbs,
  breadcrumbsClasses,
  Chip,
  Link,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { CaretRight } from '@phosphor-icons/react'
import NextLink from 'next/link'
import * as React from 'react'
import { getWorkspaceLink } from 'utils'

interface Props {
  onCommunityClick?: () => void
  map?: any
}

export function MapBreadcrumbs(props: Props): React.JSX.Element | null {
  const { map = {}, onCommunityClick } = props

  return (
    <>
      <Breadcrumbs
        separator={
          <SvgIcon
            component={CaretRight}
            inheritViewBox
          />
        }
        color='text.primary'
        sx={{
          [`.${breadcrumbsClasses.ol}`]: {
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap',
            height: '100%'
          }
        }}
      >
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
        >
          <Link
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2
            }}
            color='text.primary'
            component={NextLink}
            href={getWorkspaceLink({
              workspaceId: map?.workspace?.id,
              workspaceName: map?.workspace?.name
            })}
          >
            <Typography
              variant='next:subtitle1'
              letterSpacing='tight'
              fontWeight={600}
              variantMapping={{ 'next:h6': 'h1' }}
            >
              {map?.workspace?.name}
            </Typography>
          </Link>
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <Link
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
            color='text.primary'
            underline='none'
          >
            <Typography
              variant='next:subtitle1'
              letterSpacing='tight'
              fontWeight={600}
              variantMapping={{ 'next:h6': 'h1' }}
              sx={{
                maxWidth: 300,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {map?.name}
            </Typography>
          </Link>
          {map?.isPublic && (
            <Chip
              label='Community map'
              color='primary'
              clickable
              onClick={onCommunityClick}
            />
          )}
        </Stack>
      </Breadcrumbs>
    </>
  )
}
