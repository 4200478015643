import { Button, FormControl, OutlinedInput, Stack } from '@mui/material'
import { UsersThree as ShareIcon } from '@phosphor-icons/react'
import { FC } from 'react'
import { CopyLinkButton } from '~ui-components/components/molecules'

const ShareMap: FC<{
  onClick: () => void
  url: string
  isOwner: boolean
  onManageAccess: () => void
}> = ({ onClick, url, isOwner, onManageAccess }) => {
  return (
    <Stack
      spacing={2}
      sx={{ flexGrow: 1, height: '100%' }}
    >
      <Stack>
        <FormControl>
          <OutlinedInput
            defaultValue={url}
            readOnly
          />
        </FormControl>
      </Stack>
      <Stack sx={{ alignSelf: 'flex-end', display: 'flex' }}>
        <CopyLinkButton onClick={onClick} />
      </Stack>
      {isOwner && (
        <Stack
          sx={{
            flexGrow: 1,
            justifyContent: 'flex-end',
            display: 'flex'
          }}
        >
          <Button
            sx={{ width: 120, alignSelf: 'flex-end' }}
            color='inherit'
            startIcon={<ShareIcon />}
            onClick={onManageAccess}
          >
            Manage access
          </Button>
        </Stack>
      )}
    </Stack>
  )
}

export { ShareMap }
