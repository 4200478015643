import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { List } from '@untitled-ui/icons-react'

import { Sidebar } from '~ui-components/components/molecules/Sidebar'

function MapLegendDrawer(props) {
  const { title, legend, open, onClose } = props

  return (
    // <Sidebar
    //   anchor='left'
    //   icon={List}
    //   title='Map key'
    //   open={open}
    //   onClose={onClose}
    //   ModalProps={{
    //     disablePortal: true,
    //     hideBackdrop: true,
    //     sx: {
    //       top: 'unset',
    //       right: 'unset',
    //       bottom: 'unset',
    //       left: 'unset'
    //     }
    //   }}
    // >
    <Stack p={2}>
      <TableContainer
        sx={{
          borderRadius: 1,
          border: (theme) => `solid 1px ${theme.palette.neutral[200]}`,
          overflow: 'hidden'
        }}
      >
        <Table>
          <TableHead
            sx={{
              borderBottom: (theme) => `solid 1px ${theme.palette.neutral[200]}`
            }}
          >
            <TableRow>
              <TableCell>{title}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {legend.map((item, index) => {
              return (
                <TableRow
                  key={`Legend:${index}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Stack
                      direction='row'
                      alignItems='center'
                      gap={2}
                    >
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          bgcolor: item.value,
                          borderRadius: '100%',
                          border: (theme) =>
                            `solid 2px ${theme.palette.neutral[900]}`
                        }}
                      ></Box>
                      <Typography variant='overline'>{item.label}</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
    // </Sidebar>
  )
}

export { MapLegendDrawer }
