import { Paper, Stack } from '@mui/material'

interface Slots {
  ZoomControl?: any
  DimensionalDisplay?: any
  MapStyleSecondaryCombo?: any
  Visualization?: any
}

const Empty = () => null

const defaultSlots: Slots = {
  MapStyleSecondaryCombo: Empty,
  ZoomControl: Empty,
  DimensionalDisplay: Empty,
  Visualization: Empty
}

interface MapToolbarProps {
  slots?: Slots
}

const MapToolbar = (props: MapToolbarProps) => {
  const { slots = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Stack
      component={Paper}
      variant='outlined'
      direction='row'
      alignItems='center'
      sx={{
        flexWrap: 'wrap',
        position: 'absolute',
        right: 0,
        marginTop: 2,
        borderRadius: 2,
        p: 1,
        m: 2,
        gap: 1
      }}
    >
      <Slots.Visualization />
      <Slots.MapStyleSecondaryCombo />
      <Slots.ZoomControl />
    </Stack>
  )
}

export { MapToolbar }
