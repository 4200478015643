import { Box, Container, Paper, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'

import { CTAButtons } from './CTAButtons'
import image1 from './section-2-1.png'
import image2 from './section-2-2.png'

export function Section2() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <Stack
        component={Paper}
        spacing={6}
        alignItems='center'
        elevation={8}
        sx={{
          p: {
            xs: 4,
            sm: 6
          },
          borderRadius: 4
        }}
      >
        <Typography
          component='h2'
          variant='next:h2'
          fontWeight={700}
          textAlign='center'
        >
          From Sales Data to Field-Ready Insights—{' '}
          <Typography
            component='i'
            variant='inherit'
            fontStyle='italic'
            fontWeight={200}
          >
            faster!
          </Typography>
        </Typography>

        <Typography
          component='p'
          variant='next:h6'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Stop wasting time on manual data crunching. Quickly visualize client
          clusters, plan optimal routes, and uncover new opportunities.
        </Typography>

        <HighlightCard
          image={image1}
          alt='Simplify your field workflow'
          title='Simplify your field workflow'
          description='Eliminate the hassle of switching between CRMs, spreadsheets, and route planners. Mapstack unifies client mapping and route optimization in one platform.'
        />

        <HighlightCard
          direction='row-reverse'
          image={image2}
          alt='Collaborate and align your sales team'
          title='Collaborate and align your sales team'
          description={
            <>
              Easily share interactive maps and optimized routes with your team
              to ensure everyone knows where to focus for maximum sales impact.
            </>
          }
        />

        <Typography
          component='h3'
          variant='next:h3'
          fontWeight={700}
          textAlign='center'
        >
          Fixed price and unlimited usage
        </Typography>

        <Typography
          component='p'
          variant='next:h6'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Low cost and predictable pricing to suit your needs.
        </Typography>

        <CTAButtons />
      </Stack>
    </Box>
  )
}

function HighlightCard(props) {
  const { direction = 'row', image, alt, title, description } = props
  return (
    <Stack
      direction={direction}
      flexWrap='wrap'
      gap={8}
      justifyContent='center'
    >
      <Box
        sx={{
          position: 'relative',
          aspectRatio: '1/1',
          width: '100%',
          flex: 1,
          maxWidth: 400,
          maxHeight: 400,
          flexBasis: 'clamp(100%, 400px, 100%)'
        }}
      >
        <NextImage
          src={image}
          fill
          alt={alt}
        />
      </Box>
      <Stack
        flex={1}
        flexBasis='30ch'
        spacing={2}
        justifyContent='center'
      >
        <Typography variant='h2'>{title}</Typography>
        <Typography
          color='text.secondary'
          variant='next:h6'
          letterSpacing='tight'
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  )
}
