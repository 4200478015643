import { sendEvent } from './sendEvent'

// username = firstname + lastname

export function signInEvent(
  id: string,
  firstName: string,
  lastName: string,
  email: string
) {
  sendEvent('event', 'sign_in', {
    user_id: id,
    user_first_name: firstName,
    user_last_name: lastName,
    user_email: email
  })
}
