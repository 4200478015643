import { Divider as MuiDivider, Paper, Stack, SvgIcon } from '@mui/material'
import * as React from 'react'

import {
  BookmarkSimple,
  Copy,
  Funnel,
  GearSix,
  Info,
  MagnifyingGlass,
  MapTrifold,
  MonitorArrowUp,
  Printer,
  Users
} from '@phosphor-icons/react'

import { NavItem } from '~ui-components/components/molecules/NavItem'

import { useCssVars } from '../VerticalLayout/MobileNav'

export function MapNav2(props): React.JSX.Element {
  const { slots: Slots, isWorkspaceMember = false } = props

  const cssVars = useCssVars('blend-in')

  return (
    <Paper
      variant='outlined'
      sx={{
        ...cssVars,
        display: 'flex',
        flexDirection: 'row',
        px: 2,
        height: 64
      }}
    >
      {Slots?.Breadcrumbs && <Slots.Breadcrumbs />}
      <Stack
        ml='auto'
        direction='row'
        alignItems='center'
      >
        {Slots.Filter && <Slots.Filter />}
        {Slots.Search && <Slots.Search />}
        <Divider />
        {isWorkspaceMember && Slots.Share && <Slots.Share />}
        {Slots.Info && <Slots.Info />}
        <NavItem
          variant='dropdown'
          icon={
            <SvgIcon
              component={BookmarkSimple}
              inheritViewBox
            />
          }
          title='Export'
        >
          {Slots?.Duplicate && <Slots.Duplicate />}
          {Slots?.InfographicButton && <Slots.InfographicButton />}
          {Slots?.EmbedButton && <Slots.EmbedButton />}
        </NavItem>
        <Divider />
        {isWorkspaceMember && Slots.Settings && <Slots.Settings />}
      </Stack>
    </Paper>
  )
}

export function NavMapButton(props) {
  return (
    <NavItem
      icon={
        <SvgIcon
          component={MapTrifold}
          weight={props?.selected ? 'duotone' : 'regular'}
          inheritViewBox
        />
      }
      title='Map'
      {...props}
    />
  )
}

export function NavFilterButton(props) {
  return (
    <NavItem
      icon={
        <SvgIcon
          component={Funnel}
          weight={props?.selected ? 'duotone' : 'regular'}
          inheritViewBox
        />
      }
      title='Filter'
      {...props}
    />
  )
}

export function NavSearchButton(props) {
  return (
    <NavItem
      icon={
        <SvgIcon
          component={MagnifyingGlass}
          weight={props?.selected ? 'duotone' : 'regular'}
          inheritViewBox
        />
      }
      title='Search'
      {...props}
    />
  )
}

export function NavShareButton(props) {
  return (
    <NavItem
      icon={
        <SvgIcon
          component={Users}
          weight={props?.selected ? 'duotone' : 'regular'}
          inheritViewBox
        />
      }
      title='Share'
      {...props}
    />
  )
}

export function NavInfoButton(props) {
  return (
    <NavItem
      icon={
        <SvgIcon
          component={Info}
          weight={props?.selected ? 'duotone' : 'regular'}
          inheritViewBox
        />
      }
      title='Info'
      {...props}
    />
  )
}

export function NavInfographicButton(props) {
  return (
    <NavItem
      icon={
        <SvgIcon
          component={Printer}
          weight='regular'
          inheritViewBox
        />
      }
      title='Infographic'
      {...props}
    />
  )
}

export function NavEmbedButton(props) {
  return (
    <NavItem
      icon={
        <SvgIcon
          component={MonitorArrowUp}
          weight='regular'
          inheritViewBox
        />
      }
      title='Embed'
      {...props}
    />
  )
}

export function NavBookmarkButton(props) {
  return (
    <NavItem
      icon={
        <SvgIcon
          component={BookmarkSimple}
          weight='regular'
          inheritViewBox
        />
      }
      title='Bookmark'
      {...props}
    />
  )
}

export function NavDuplicateButton(props) {
  const { ButtonProps, ...rest } = props
  return (
    <NavItem
      icon={
        <SvgIcon
          component={Copy}
          weight='regular'
          inheritViewBox
        />
      }
      title='Copy map'
      {...rest}
    />
  )
}

export function NavSettingsButton(props) {
  return (
    <NavItem
      icon={
        <SvgIcon
          component={GearSix}
          weight={props?.selected ? 'duotone' : 'regular'}
          inheritViewBox
        />
      }
      title='Settings'
      {...props}
    />
  )
}

const Divider = () => {
  return (
    <MuiDivider
      orientation='vertical'
      variant='middle'
      sx={{ borderColor: (theme) => theme.palette.grey[300], height: '50%' }}
    />
  )
}
