import { SvgIcon } from '@mui/material'
import { Map01 } from '@untitled-ui/icons-react'
import { NavItem } from '~ui-components/components/molecules/NavItem'

type AggregateMapsProps = {
  value: string
  onChange: (value: string) => void
  onHexagonClick: () => void
  options: {
    label: string
    value: string
  }[]
}
export function AggregateMaps(props: AggregateMapsProps) {
  const { options } = props
  return (
    <NavItem
      title='Aggregate map'
      {...props}
    >
      {options.map((option) => {
        return (
          <NavItem
            onClick={() => props.onChange(option.value)}
            key={option.value}
            title={option.label}
            icon={
              <SvgIcon>
                <Map01 />
              </SvgIcon>
            }
          />
        )
      })}
      <NavItem
        title='Hexagon map'
        onClick={() => props.onHexagonClick()}
        icon={
          <SvgIcon>
            <Map01 />
          </SvgIcon>
        }
      />
    </NavItem>
  )
}
