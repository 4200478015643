import { LoadingButton } from '@mui/lab'
import { Stack, TextField } from '@mui/material'
import { Fragment } from 'react'
import { AuthFormHeader } from '../FormHeader'

interface SignUpEmailPasswordFormProps {
  slots?: {
    Form?: React.ElementType
    FirstnameInput?: React.ElementType
    LastnameInput?: React.ElementType
    EmailInput?: React.ElementType
    PasswordInput?: React.ElementType
    Button?: React.ElementType
  }
  slotProps?: {
    Form?: any
    FirstnameInput?: any
    LastnameInput?: any
    EmailInput?: any
    PasswordInput?: any
    Button?: any
  }
}

export function SignUpEmailPasswordForm(props: SignUpEmailPasswordFormProps) {
  const { slots, slotProps } = props

  const Slots = {
    Form: Fragment,
    FirstnameInput: TextField,
    LastnameInput: TextField,
    EmailInput: TextField,
    PasswordInput: TextField,
    Button: LoadingButton,
    ...slots
  }

  return (
    <>
      <AuthFormHeader
        title='Sign up with email'
        subtitle='Create your account by filling out the information below'
      />

      <Stack
        component={Slots.Form}
        direction='column'
        spacing={3}
        {...slotProps?.Form}
      >
        <Slots.FirstnameInput
          label='First name'
          {...slotProps?.FirstnameInput}
        />

        <Slots.LastnameInput
          label='Last name'
          {...slotProps?.LastnameInput}
        />

        <Slots.EmailInput
          label='Email'
          {...slotProps?.EmailInput}
        />

        <Slots.PasswordInput
          type='password'
          label='Password'
          {...slotProps?.PasswordInput}
        />

        <Slots.Button
          color='primary'
          variant='contained'
          type='submit'
          size='large'
          sx={{
            borderRadius: 99
          }}
          {...slotProps?.Button}
        >
          Sign up
        </Slots.Button>
      </Stack>
    </>
  )
}
