import { MapFilter, MapFilterProps, PolygonMapFilter } from 'ui-components'
import { useFilterResults } from '~map-filter-view/states/filter'
import { PropertiesList } from './PropertiesList'
interface Filter {
  id: string
  name: string
  type: 'text' | 'number' | 'date'
  value:
    | string[]
    | { minDate: string; maxDate: string }
    | { min: number; max: number }
}

type Props = {
  isPolygonLayout?: boolean
  isEmbed?: boolean
  filters: Filter[]
  open: boolean
  onClose: () => void
  onFilterRemove: (id: string) => void
  onFilterUpdate: (filter: Filter) => void
  onSetActiveFilters: (activeFitlers: number) => void
  drawerProps: MapFilterProps['drawerProps']
}

const App = (props: Props) => {
  const {
    isPolygonLayout = false,
    isEmbed = false,
    filters,
    open,
    onClose,
    onFilterRemove,
    onFilterUpdate,
    onSetActiveFilters,
    drawerProps
  } = props
  const filterResults = useFilterResults()

  if (isPolygonLayout)
    return (
      <PolygonMapFilter
        isEmbed={isEmbed}
        filters={filters}
        filterResults={filterResults}
        open={open}
        onClose={onClose}
        slots={{
          Selector: PropertiesList
        }}
        slotProps={{
          Selector: {
            filterResults,
            onFilterRemove: onFilterRemove,
            onFilterUpdate: onFilterUpdate,
            onSetActiveFilters: onSetActiveFilters
          }
        }}
        drawerProps={drawerProps}
      />
    )

  return (
    <MapFilter
      isEmbed={isEmbed}
      filters={filters}
      filterResults={filterResults}
      open={open}
      onClose={onClose}
      slots={{
        Selector: PropertiesList
      }}
      slotProps={{
        Selector: {
          filterResults,
          onFilterRemove: onFilterRemove,
          onFilterUpdate: onFilterUpdate,
          onSetActiveFilters: onSetActiveFilters
        }
      }}
      drawerProps={drawerProps}
    />
  )
}

export { App }
