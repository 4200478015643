import { useEffect } from 'react'
import { useAtom } from 'user-auth/libs/jotai'
import { authWorkspace } from 'user-auth/states/auth'
import { Workspace } from 'user-auth/types/__generated/gql/graphql'

export const useAuthWorkspace = (
  workspaceId?: string
): Workspace | undefined => {
  const [workspace, getWorkspaceById] = useAtom(authWorkspace)

  useEffect(() => {
    getWorkspaceById(workspaceId)
  }, [getWorkspaceById, workspaceId])

  return workspace
}
