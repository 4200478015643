import { isNaN } from 'lodash'

export const isPercentage = (value: number | string) => {
  let float
  if (typeof value === 'number') {
    float = value
  } else if (typeof value === 'string') {
    // Trim whitespace
    value = value.trim()
    // Remove trailing '%' if present
    if (value.endsWith('%')) {
      value = value.slice(0, -1)
    }
    float = parseFloat(value)
  }
  return !isNaN(float)
}
