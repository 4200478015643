import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { X } from '@phosphor-icons/react'
import { AvatarProfile } from '../AvatarProfile'

import { useEffect } from 'react'
import { getWorkspaceLink } from 'utils'

export function MapMenuDrawer(props) {
  const { map, open, onClose, slots: Slots, slotProps } = props

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down(1280))

  useEffect(() => {
    if (!mobile && open) onClose?.()
  }, [mobile])

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor='left'
      ModalProps={{
        sx: { zIndex: (theme) => theme.zIndex.modal + 3 }
      }}
      PaperProps={{
        elevation: 24,
        sx: [
          {
            overflow: 'hidden',
            width: 390,
            maxWidth: '100%',
            height: '100%'
          }
        ]
      }}
    >
      <Box sx={{ position: 'relative', p: 2 }}>
        <Stack
          spacing={2}
          sx={{
            marginRight: '40px'
          }}
        >
          <Typography
            variant='next:h5'
            fontWeight={700}
            letterSpacing='tight'
            variantMapping={{
              'next:h5': 'h1'
            }}
          >
            {map.name}
          </Typography>

          <AvatarProfile
            href={getWorkspaceLink({
              workspaceId: map.workspace?.id,
              workspaceName: map.workspace?.name
            })}
            src={map?.workspace?.logo}
            primaryText={map?.workspace?.name}
            alt={map?.workspace?.name}
          />
        </Stack>

        <IconButton
          {...slotProps?.CloseButton}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8
          }}
          onClick={onClose}
        >
          <SvgIcon
            component={X}
            inheritViewBox
          ></SvgIcon>
        </IconButton>
      </Box>
      <Divider />
      <Stack
        p={2}
        spacing={2}
      >
        <Stack
          direction='column'
          gap={1}
        >
          <Typography variant='next:subtitle2'>Tools</Typography>
          {Slots.Filter && <Slots.Filter />}
          {Slots.Search && <Slots.Search />}
          {Slots.Share && <Slots.Share />}
          {Slots.Info && <Slots.Info />}
          {Slots.Save && <Slots.Save />}
          {Slots.Settings && <Slots.Settings />}
        </Stack>
      </Stack>
    </Drawer>
  )
}
