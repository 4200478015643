export function stringAvatar(name?: string) {
  if (!name)
    return {
      children: ''
    }

  const parts = name.split(' ')
  const first = parts[0]?.[0] || ''
  const second = parts[1]?.[0] || ''
  return {
    children: `${first}${second}`
  }
}
