import { Box, Stack } from '@mui/material'
import NextLink from 'next/link'

import BrandLogo from '~ui-components/components/atoms/BrandLogo'

const AppHeaderLogo = (props) => {
  const { href = '/' } = props

  return (
    <Stack
      direction='row'
      alignItems='center'
    >
      <Box
        component={NextLink}
        href={href}
        data-testid='AppNavigationLogo'
        sx={{ all: 'unset', display: 'flex', cursor: 'pointer' }}
      >
        <BrandLogo
          variant='logoCopy'
          size='md'
        />
      </Box>
    </Stack>
  )
}

export { AppHeaderLogo }
export default AppHeaderLogo
