import { MapProperty } from '~ui-components/types/__generated/gql/graphql'
import { DEFINED_FIELD_OPTIONS } from '../PrimaryFieldOptions'
import { InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { FC } from 'react'

type PrimaryFieldInputProps = {
  properties: MapProperty[]
  value?: string
  onPropertiesChange: (properties: MapProperty[]) => void
}

export const PrimaryFieldInput: FC<PrimaryFieldInputProps> = (props) => {
  const { properties, onPropertiesChange } = props

  const [titleProperty] = properties.filter(
    (property) => property.isTitle === true
  )
  const value = titleProperty?.name

  const handleOnchange = (value) => {
    const newProperties = properties.map((property) => {
      const isTitle = property.name === value
      return {
        ...property,
        isTitle
      }
    })
    onPropertiesChange(newProperties)
  }

  return (
    <Stack spacing={1}>
      <InputLabel>Primary field</InputLabel>
      <Stack
        direction='row'
        spacing={2}
        sx={{ alignItems: 'center', flexWrap: 'wrap' }}
      >
        <Select
          fullWidth
          value={value}
          onChange={(event) => handleOnchange(event.target.value)}
          name='type'
        >
          {properties.map((option, key) => (
            <MenuItem
              key={key}
              value={option.name}
            >
              <FieldItem
                type={option.type}
                label={option.name}
              />
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  )
}

function FieldItem(props) {
  const { type, label } = props
  const [definedField] = DEFINED_FIELD_OPTIONS.filter(
    (item) => item.value === type
  )
  const { icon: Icon } = definedField || {}
  return (
    <Stack
      direction='row'
      flex={1}
      gap={2}
      alignItems='center'
    >
      {Icon && <Icon size={24} />}
      {label}
    </Stack>
  )
}
