import { LoadingButton } from '@mui/lab'
import { Stack, TextField } from '@mui/material'
import { Fragment } from 'react'
import { AuthFormHeader } from '../FormHeader'

interface SignInEmailPasswordFormProps {
  slots?: {
    Form?: React.ElementType
    EmailInput?: React.ElementType
    PasswordInput?: React.ElementType
    Button?: React.ElementType
  }
  slotProps?: {
    Form?: any
    EmailInput?: any
    PasswordInput?: any
    Button?: any
  }
}

export function SignInEmailPasswordForm(props: SignInEmailPasswordFormProps) {
  const { slots, slotProps } = props

  const Slots = {
    Form: Fragment,
    EmailInput: TextField,
    PasswordInput: TextField,
    Button: LoadingButton,
    ...slots
  }

  return (
    <>
      <AuthFormHeader
        title='Sign in with email'
        subtitle='Please enter your email and password to sign in'
      />

      <Stack
        component={Slots.Form}
        direction='column'
        spacing={3}
        {...slotProps?.Form}
      >
        <Slots.EmailInput
          label='Email'
          type='email'
          {...slotProps?.EmailInput}
        />

        <Slots.PasswordInput
          type='password'
          label='Password'
          {...slotProps?.PasswordInput}
        />

        <Slots.Button
          color='primary'
          variant='contained'
          type='submit'
          size='large'
          sx={{
            borderRadius: 99
          }}
          {...slotProps?.Button}
        >
          Sign in
        </Slots.Button>
      </Stack>
    </>
  )
}
