import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Button,
  Dialog,
  DialogProps,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { ArrowUp } from '@untitled-ui/icons-react'

interface GuestExceedDialogProps extends DialogProps {
  limits: number
  onPrimaryClick: () => void
  SecondaryActionProps?: React.ComponentProps<typeof LoadingButton>
}

function GuestExceedDialog(props: GuestExceedDialogProps) {
  const { limits, open, onClose, onPrimaryClick, ...rest } = props

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      onClose={onClose}
      {...rest}
      sx={(theme) => ({
        p: 3,
        [theme.breakpoints.down(528)]: {
          p: 0
        }
      })}
    >
      <Paper
        component={Stack}
        spacing={2}
        elevation={12}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          sx={{
            backgroundColor: 'success.lightest',
            color: 'success.main',
            width: 60,
            height: 60
          }}
        >
          <SvgIcon fontSize='large'>
            <ArrowUp />
          </SvgIcon>
        </Avatar>

        <Typography variant='h5'>Guest limit reached</Typography>

        <Typography
          align='center'
          color='text.secondary'
          variant='body2'
        >
          The number of guests in the current plan ({limits}) exceeds the limit
          of your current plan, upgrade to continue.
        </Typography>

        <Button
          fullWidth
          size='large'
          variant='contained'
          onClick={onPrimaryClick}
        >
          View plans and pricing
        </Button>
      </Paper>
    </Dialog>
  )
}

export { GuestExceedDialog }
