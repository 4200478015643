import { Save01 } from '@untitled-ui/icons-react/build/esm'
import { FC } from 'react'
import {
  PointMapForm,
  PointMapFormProps
} from '~ui-components/components/molecules/PointMapForm'
import { Sidebar } from '~ui-components/components/molecules/Sidebar'

export type MapSaveDrawerProps = PointMapFormProps & {
  open: boolean
  onClose: () => void
}

const MapSaveDrawer: FC<MapSaveDrawerProps> = (props) => {
  const { open, onClose, ...rest } = props

  return (
    // <Sidebar
    //   closeAfterTransition
    //   anchor='left'
    //   title='Save map'
    //   icon={Save01}
    //   open={open}
    //   onClose={onClose}
    //   ModalProps={{
    //     disablePortal: true,
    //     hideBackdrop: true,
    //     sx: {
    //       top: 'unset',
    //       right: 'unset',
    //       bottom: 'unset',
    //       left: 'unset'
    //     }
    //   }}
    //   PaperProps={{
    //     elevation: 24,
    //     sx: {
    //       maxWidth: '100%',
    //       width: 390,
    //       overflow: 'hidden'
    //     }
    //   }}
    // >
    <PointMapForm
      {...rest}
      p={2}
    />
    // </Sidebar>
  )
}

export { MapSaveDrawer }
