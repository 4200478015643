import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  useMediaQuery,
  useTheme
} from '@mui/material'
import type { Theme } from '@mui/material/styles/createTheme'
import NextLink from 'next/link'
import { useState, type FC } from 'react'
import { getPricingLink, getWorkspaceLink } from 'utils'

import ChevronLeftDoubleIcon from '@untitled-ui/icons-react/build/esm/ChevronLeftDouble'
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01'
import User01Icon from '@untitled-ui/icons-react/build/esm/User01'

import {
  ChartLine,
  CompassRose,
  GlobeStand,
  HouseSimple,
  MapTrifold,
  Shapes,
  ShoppingCart,
  Star,
  Tag
} from '@phosphor-icons/react'
import { SignInButton2 } from '~ui-components/components/molecules/SignInButton2'
import { SignUpButton } from '~ui-components/components/molecules/SignUpButton'
import { NavAvatarDropdown } from '~ui-components/components/organisms/NavAvatarDropdown'
import { NavItem } from '../NavItem'

const height = 64

interface HeaderProps {
  hideSearch?: boolean
  authenticated?: boolean
  style?: any
  slots?: any
  slotProps?: any
}

function Layout(props) {
  const { sx, ...rest } = props
  return (
    <Paper
      {...rest}
      variant='outlined'
      component='header'
      sx={[
        {
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: (theme) => theme.zIndex.appBar,
          boxShadow: (theme) => theme.shadows[8]
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    />
  )
}

function Root(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: height,
        px: 2
      }}
      {...props}
    ></Box>
  )
}

export const NavMapHeader = (props) => {
  const { menu = true, authenticated, slots, slotProps } = props

  const Slots = {
    WorkspaceButtonList,
    ...slots
  }

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down(1280))

  return (
    <Layout
      square
      sx={{
        position: 'relative',
        boxShadow: (theme) => theme.shadows[0]
      }}
    >
      <Root>
        <Stack
          alignItems='center'
          direction='row'
          spacing={2}
        >
          {menu ? (
            <>
              {!mobile ? (
                <Slots.Logo />
              ) : (
                <IconButton {...slotProps?.MenuButton}>
                  <SvgIcon>
                    <Menu01Icon />
                  </SvgIcon>
                </IconButton>
              )}
            </>
          ) : (
            <Slots.Logo />
          )}

          <Stack
            direction='row'
            sx={(theme) => ({
              [theme.breakpoints.down(1280)]: {
                display: 'none'
              }
            })}
          >
            {authenticated ? (
              <Slots.WorkspaceButtonList />
            ) : (
              <Slots.NavCommunity />
            )}
          </Stack>
        </Stack>

        <Stack
          direction='row'
          gap={1}
        >
          {authenticated ? (
            <Stack
              direction='row'
              spacing={2}
            >
              <Slots.CreateMapButton />
              <Slots.NavAvatar />
            </Stack>
          ) : (
            <>
              <Slots.SignInButton />
              <Slots.CreateMapButton />
            </>
          )}
        </Stack>
      </Root>
    </Layout>
  )
}

export function WorkspaceButtonList(props) {
  const { workspace } = props

  return (
    <>
      <Button
        variant='text'
        color='inherit'
        component={NextLink}
        href={getWorkspaceLink({
          workspaceId: workspace?.id,
          workspaceName: workspace?.name,
          tab: 'maps'
        })}
      >
        Maps
      </Button>

      <Button
        variant='text'
        color='inherit'
        component={NextLink}
        href={getWorkspaceLink({
          workspaceId: workspace?.id,
          workspaceName: workspace?.name,
          tab: 'members'
        })}
      >
        Team
      </Button>
    </>
  )
}

export const NavHeader: FC<HeaderProps> = (props) => {
  const { hideSearch, authenticated, slots = {}, slotProps, ...rest } = props

  const Slots = {
    SignInButton: SignInButton2,
    SignUpButton,
    ...slots
  }

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return (
    <>
      <Layout {...rest}>
        <Root>
          <Stack
            alignItems='center'
            direction='row'
            spacing={2}
          >
            <Slots.Logo />
          </Stack>

          {!hideSearch && (
            <Stack
              flex={1}
              maxWidth='540px'
              alignItems={lgUp ? 'center' : 'flex-end'}
            >
              <Slots.SearchBar />
            </Stack>
          )}

          <Stack
            direction='row'
            spacing={2}
            ml={1}
          >
            {!lgUp ? (
              <>
                {!authenticated ? (
                  <IconButton onClick={handleOpen}>
                    <SvgIcon>
                      <Menu01Icon />
                    </SvgIcon>
                  </IconButton>
                ) : (
                  <>
                    <Slots.CreateMapButton />
                    <Slots.NavAvatar />
                  </>
                )}
              </>
            ) : (
              <>
                <Stack direction='row'>
                  {!authenticated && <NavSolutions />}

                  {lgUp && !authenticated && (
                    <Slots.NavCommunity variant='dropdown' />
                  )}

                  {!authenticated && <PricingButton />}

                  {!authenticated && <Slots.SignInButton />}
                </Stack>

                {!authenticated ? (
                  <Slots.SignUpButton
                    color='primary'
                    variant='contained'
                  />
                ) : (
                  <Slots.CreateMapButton />
                )}

                {authenticated && <Slots.NavAvatar />}
              </>
            )}
          </Stack>
        </Root>
      </Layout>

      <Slots.NavDrawer
        authenticated={authenticated}
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export function NavDrawer(props) {
  const {
    authenticated,
    open,
    onClose,
    slots = {},
    sx,
    PaperProps = {},
    ...rest
  } = props

  const Slots = {
    SignInButton: SignInButton2,
    SignUpButton,
    ...slots
  }

  return (
    <Drawer
      anchor='left'
      sx={sx}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: [
          {
            width: '100%',
            maxWidth: '100%'
          },
          ...(Array.isArray(PaperProps.sx) ? PaperProps.sx : [PaperProps.sx])
        ]
      }}
      {...rest}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={3}
        sx={{
          px: 2,
          pt: 1
        }}
      >
        <Stack
          alignItems='inherit'
          direction='row'
          spacing={1}
        >
          <Slots.Logo />
        </Stack>

        <IconButton onClick={onClose}>
          <SvgIcon>
            <ChevronLeftDoubleIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <Divider
        orientation='horizontal'
        sx={{ mt: 1 }}
      />
      <Stack
        sx={{ flexGrow: 1, p: 2 }}
        alignItems='left'
        direction='column'
      >
        <Stack
          spacing={1}
          sx={{
            flexGrow: 1,
            listStyle: 'none',
            m: 0,
            p: 0
          }}
        >
          <Slots.NavCommunity variant='list' />
          <NavSolutions variant='list' />
        </Stack>

        <Stack gap={1}>
          {!authenticated && <PricingButton />}
          {!authenticated ? (
            <>
              <Slots.SignInButton />
              <Slots.SignUpButton
                color='primary'
                variant='contained'
              />
            </>
          ) : (
            <Slots.CreateMapButton />
          )}
        </Stack>
      </Stack>
    </Drawer>
  )
}

export function NavCommunity(props) {
  return (
    <NavItem
      variant='dropdown'
      title='Community maps'
      {...props}
    >
      <NavItem
        title='Map portal'
        path='/search'
        icon={
          <SvgIcon
            component={GlobeStand}
            inheritViewBox
            weight='duotone'
          />
        }
      />

      <NavItem
        title='Best of mapstack'
        path='/explore'
        icon={
          <SvgIcon
            component={Star}
            inheritViewBox
            weight='duotone'
          />
        }
      />

      <NavItem
        title='Tags'
        path='/tags'
        icon={
          <SvgIcon
            component={Tag}
            inheritViewBox
            weight='duotone'
          />
        }
      />
    </NavItem>
  )
}

export function NavAvatar(props) {
  const { slots } = props

  const Slots = {
    WorkspaceAvatar,
    ...slots
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Slots.WorkspaceAvatar onClick={handleClick} />
      <NavAvatarDropdown
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        slots={slots}
      />
    </>
  )
}

export function WorkspaceAvatar(props) {
  const { src, onClick } = props
  return (
    <Avatar
      sx={{ height: 40, width: 40, cursor: 'pointer' }}
      src={src || undefined}
      onClick={onClick}
    >
      <SvgIcon>
        <User01Icon />
      </SvgIcon>
    </Avatar>
  )
}

export function PricingButton() {
  return (
    <Button
      component={NextLink}
      variant='text'
      color='inherit'
      href={getPricingLink()}
    >
      Pricing
    </Button>
  )
}

function NavSolutions(props) {
  return (
    <NavItem
      variant='dropdown'
      title='Solutions'
      {...props}
    >
      <NavItem
        title='Geocoding'
        description='Turn your location data into full interactive maps in seconds.'
        path='/solutions/geocoding'
        icon={
          <SvgIcon
            component={MapTrifold}
            inheritViewBox
            weight='duotone'
          />
        }
      />

      <NavItem
        title='E-commerce marketing'
        description='Optimize Location Based Ad Spend with Instant Map Insights'
        path='/solutions/ecommerce-marketing'
        icon={
          <SvgIcon
            component={ShoppingCart}
            inheritViewBox
            weight='duotone'
          />
        }
      />

      <NavItem
        title='Mapping workflows'
        description='Instantly Filter & Export Your Location Data ⎯ No Coding Required'
        path='/solutions/mapping-workflows'
        icon={
          <SvgIcon
            component={CompassRose}
            inheritViewBox
            weight='duotone'
          />
        }
      />

      <NavItem
        title='Strategic planning'
        description='Empower Strategic Growth with Geographic Insights'
        path='/solutions/planning'
        icon={
          <SvgIcon
            component={ChartLine}
            inheritViewBox
            weight='duotone'
          />
        }
      />

      <NavItem
        title='Territory managment'
        description='Define & Manage Territories with Interactive Maps'
        path='/solutions/territory-management'
        icon={
          <SvgIcon
            component={Shapes}
            inheritViewBox
            weight='duotone'
          />
        }
      />

      <NavItem
        title='Address mapping'
        description='Discover Hidden Insights with Interactive Maps'
        path='/solutions/address-mapping'
        icon={
          <SvgIcon
            component={HouseSimple}
            inheritViewBox
            weight='duotone'
          />
        }
      />
    </NavItem>
  )
}
