import { FC, useState } from 'react'

import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
  Tooltip
} from '@mui/material'
import { DotsHorizontal, User01 } from '@untitled-ui/icons-react'

import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'
import { MapGuestAccess } from '~ui-components/types/__generated/gql/graphql'
import { Info } from '@phosphor-icons/react'

export type GuestAccessListProps = {
  accesses: MapGuestAccess[]
  onRemoveAccessClick: (accessId: MapGuestAccess) => Promise<void>
}

export const GuestAccessList: FC<GuestAccessListProps> = ({
  accesses,
  onRemoveAccessClick
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedAccess, setSelectedAccess] = useState<MapGuestAccess>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>()

  const handleClick = (event, access) => {
    setAnchorEl(event.target)
    setSelectedAccess(access)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedAccess(undefined)
  }

  const handleRemoveAccessClick = async () => {
    setLoading(true)
    if (selectedAccess) await onRemoveAccessClick(selectedAccess)
    handleClose()
    setLoading(false)
  }

  if (!accesses?.length) return null

  return (
    <>
      <GuestSettingPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && !loading}
        onClose={handleClose}
      >
        <GuestSesettingItem
          primaryText='Remove access'
          onClick={handleRemoveAccessClick}
        />
      </GuestSettingPopover>

      <Stack spacing={1}>
        <Stack
          spacing={1}
          direction='row'
          alignItems='center'
        >
          <Typography
            color='text.secondary'
            variant='overline'
          >
            Guest access
          </Typography>
          <Tooltip
            color='secondary'
            title="Guest has read-only access to specific maps they're invited to and won't see other workspace maps unless explicity granted access."
          >
            <SvgIcon>
              <Info />
            </SvgIcon>
          </Tooltip>
        </Stack>

        <Scrollbar>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableBody>
              {accesses.map((access) => (
                <TableRow key={access.id}>
                  <TableCell sx={{ px: 0 }}>
                    <Stack
                      alignItems='center'
                      direction='row'
                      spacing={1}
                    >
                      {access.invitedUser ? (
                        <>
                          <Avatar
                            sx={{
                              height: 40,
                              width: 40
                            }}
                          >
                            <SvgIcon>
                              <User01 />
                            </SvgIcon>
                          </Avatar>
                          <Box>
                            <Typography variant='subtitle2'>
                              {access.invitedUser.email}
                            </Typography>
                            <Typography
                              color='text.secondary'
                              variant='body2'
                            >
                              {access.invitedUser.email}
                            </Typography>
                          </Box>
                        </>
                      ) : access.user ? (
                        <>
                          <Avatar
                            src={access.user.avatar ?? undefined}
                            sx={{
                              height: 40,
                              width: 40
                            }}
                          >
                            <SvgIcon>
                              <User01 />
                            </SvgIcon>
                          </Avatar>
                          <Box>
                            <Typography variant='subtitle2'>
                              {access.user.firstName} {access.user.lastName}
                            </Typography>
                            <Typography
                              color='text.secondary'
                              variant='body2'
                            >
                              {access.user.email}
                            </Typography>
                          </Box>
                        </>
                      ) : null}
                    </Stack>
                  </TableCell>

                  <TableCell
                    sx={{ px: 0 }}
                    align='right'
                  >
                    <IconButton
                      disabled={selectedAccess?.id === access.id && loading}
                      onClick={(event) => handleClick(event, access)}
                    >
                      {selectedAccess?.id === access.id && loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <SvgIcon>
                          <DotsHorizontal />
                        </SvgIcon>
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </Stack>
    </>
  )
}

function GuestSettingPopover(props) {
  const { anchorEl, open, onClose, children } = props

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{
        sx: {
          width: 240
        }
      }}
      sx={{
        zIndex: (theme) => theme.zIndex.tooltip + 1
      }}
    >
      <Box sx={{ p: 1 }}>{children}</Box>
    </Popover>
  )
}

function GuestSesettingItem(props) {
  const { primaryText, onClick } = props

  return (
    <ListItemButton
      sx={{
        borderRadius: 1,
        px: 1,
        py: 0.5
      }}
      onClick={onClick}
    >
      <ListItemText
        primary={<Typography variant='body1'>{primaryText}</Typography>}
      />
    </ListItemButton>
  )
}
