import { Stack } from '@mui/material'

export const HexagonAggregate = (props) => {
  const { slots } = props
  return (
    <Stack spacing={1}>
      {slots.Aggregate && <slots.Aggregate />}
      {slots?.Size && <slots.Size />}
    </Stack>
  )
}
