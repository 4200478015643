import { useState } from 'react'
import { UnauthenticatedActionTooltip } from '~ui-components/components/molecules/UnauthenticatedActionTooltip'

type DuplicateMapProps = {
  authenticated: boolean
  onClick: () => void
  onSignInClick: () => void
  slots?: any
}

export function DuplicateMapButton(props: DuplicateMapProps) {
  const { slots: Slots } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    if (!props.authenticated) {
      setAnchorEl(event.currentTarget)
      return
    }
    props.onClick()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <UnauthenticatedActionTooltip
        primary='Want to save this map for later?'
        secondary='Sign in to get started'
        anchorEl={anchorEl}
        onClose={handleClose}
        onSignInClick={props.onSignInClick}
      />
      {Slots?.Button && <Slots.Button onClick={handleClick} />}
    </>
  )
}
