import { isNil, get } from 'lodash'
import { formatDate } from '../formatDate'
import { formatInteger } from '../formatInteger'
import { formatNumber } from '../formatNumber'
import { formatPercentage } from '../formatPercentage'
import { formatCurrency } from '../formatCurrency'

const formatFeaturePropertyValue = ({
  value,
  type,
  fallback = '~',
  options = {}
}) => {
  const propertyType = get(options, 'fieldType', type) || type

  if (!DEFINED_TYPES.includes(propertyType))
    throw new Error(`Type ${type} is not defined`)

  if (isFalsy(value)) return fallback ?? value

  return formatValue[propertyType](value, { fallback, ...options })
}

const DEFINED_TYPES = [
  'integer',
  'float',
  'number',
  'date',
  'text',
  'object',
  'boolean',
  // New types
  'longText',
  'url',
  'email',
  'phoneNumber',
  'currency',
  'percentage',
  'select'
]

const formatValue = {
  integer: formatInteger,
  float: formatNumber,
  number: formatNumber,
  date: formatDate,
  text: (value) => value,
  object: (value) => value,
  boolean: (value) => value,
  // New types
  longText: (value) => value,
  url: (value) => value,
  email: (value) => value,
  phoneNumber: (value) => value,
  currency: formatCurrency,
  percentage: formatPercentage,
  select: (value) => value
}

const isFalsy = (value) => {
  return (
    isNil(value) || value === '' || (typeof value === 'number' && isNaN(value))
  )
}

export { formatFeaturePropertyValue }
