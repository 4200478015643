import {
  Box,
  Container,
  Divider,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import NextImage from 'next/image'
import { MapstackUserCases } from '~ui-components/components/organisms/MapstackUseCases'

import { CTAButtons } from './CTAButtons'
import image1 from './section-4-1.png'
import image2 from './section-4-2.png'
import image3 from './section-4-3.png'

export function Section4() {
  return (
    <Container maxWidth='xl'>
      <Paper
        variant='elevation'
        elevation={8}
        sx={{
          p: 2,
          borderRadius: 4
        }}
      >
        <Stack
          direction='column'
          spacing={8}
          my={8}
          alignItems='center'
        >
          <Typography
            variant='next:h2'
            component='h2'
            textAlign='center'
            fontWeight={700}
            maxWidth='27ch'
          >
            Goodbye manual route planning, hello efficient field mapping
          </Typography>

          <Typography
            component='p'
            variant='next:h6'
            textAlign='center'
            color='text.secondary'
            maxWidth='96ch'
          >
            Eliminate tedious manual route planning. Upload your CRM data and
            instantly see interactive maps that reveal client density and
            suggest optimal routes for your day.
          </Typography>

          <Stack
            direction='row'
            gap={4}
            flexWrap='wrap'
            justifyContent='center'
          >
            <HowItWorkCard
              image={image1}
              title='Upload your client data'
              alt='Upload your client data'
              description={
                <>
                  Easily import your client and prospect data from your CRM or
                  spreadsheets. Mapstack automatically organizes your data, so
                  you can focus on selling.
                </>
              }
            />

            <HowItWorkCard
              image={image2}
              title='Instantly visualize your territory'
              alt='Instantly visualize your territory'
              description={
                <>
                  Within seconds, see your client locations plotted on an
                  interactive map—with built-in route suggestions and territory
                  visualizations that make planning a breeze.
                </>
              }
            />

            <HowItWorkCard
              image={image3}
              title='Analyze and optimize'
              alt='Analyze and optimize'
              description={
                <>
                  Apply filters to highlight high-density areas, analyze
                  territory performance, and identify gaps in coverage. Use
                  these insights to plan the most efficient routes and maximize
                  sales.
                </>
              }
            />
          </Stack>

          <Stack
            spacing={8}
            alignItems='center'
          >
            <Typography
              component='h2'
              variant='next:h3'
              fontWeight={700}
              textAlign='center'
            >
              Powerful sales mapping visualizations
            </Typography>

            <Typography
              component='p'
              variant='next:h6'
              textAlign='center'
              color='text.secondary'
              maxWidth='88%'
            >
              Easily customize your view—from cluster maps to heat maps and
              aggregates—to get deeper insights into your territories and client
              distribution.
            </Typography>

            <MapstackUserCases />
          </Stack>

          <CTAButtons />
        </Stack>
      </Paper>
    </Container>
  )
}

function HowItWorkCard(props) {
  const { title, description, image, alt } = props
  return (
    <Box
      width='100%'
      maxWidth={420}
      component={Paper}
      variant='elevation'
      elevation={8}
      borderRadius={3}
    >
      <Typography
        variant='next:h5'
        component='h3'
        textAlign='left'
        fontWeight={700}
        p={3}
      >
        {title}
      </Typography>
      <Divider />
      <Stack
        p={3}
        spacing={3}
      >
        <Box
          component={Paper}
          variant='outlined'
          sx={{
            position: 'relative',
            aspectRatio: '16/9',
            width: '100%',
            fontStyle: 'italic',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <NextImage
            src={image}
            alt={alt}
            fill
          />
        </Box>
        <Typography
          component='p'
          variant='next:subtitle1'
          textAlign='left'
        >
          {description}
        </Typography>
      </Stack>
    </Box>
  )
}
