import {
  Box,
  Button,
  Paper,
  Stack,
  SvgIcon,
  SxProps,
  Typography,
  alpha
} from '@mui/material'
import { LinkExternal01 } from '@untitled-ui/icons-react'

import { ellipsis } from 'polished'
import { FC } from 'react'
import { CategoryColorList } from './CategoryColorList'
import { QuantityColorList } from './QuantityColorList'

export type MapLegendProps = {
  sx?: SxProps
  visualisation?: 'quantity' | 'category'
  title: string
  legend: any
  onExpandClick?: () => void
}

export const MapLegend: FC<MapLegendProps> = (props) => {
  const { visualisation, sx, title, legend, onExpandClick, ...rest } = props

  return (
    <Box
      component={Paper}
      variant='outlined'
      sx={[
        (theme) => ({
          right: 0,
          bottom: '2.5rem',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          backgroundColor: alpha(theme.palette.background.paper, 1),
          [theme.breakpoints.down('mobile')]: {
            p: 1
          }
        }),
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...rest}
    >
      <Stack
        direction='row'
        alignItems='center'
        flexWrap='wrap'
      >
        <Box
          gap={2}
          flex={1}
          component={Typography}
          sx={[
            ellipsis('--webkit-fill-available'),
            {
              fontSize: '1.25rem',
              fontWeight: 'bold',
              letterSpacing: 'tighter'
            }
          ]}
        >
          {title}
        </Box>
        <Button
          color='inherit'
          size='small'
          disableElevation
          startIcon={
            <SvgIcon>
              <LinkExternal01 />
            </SvgIcon>
          }
          onClick={onExpandClick}
        >
          {/* Full map key */}
        </Button>
      </Stack>

      {visualisation === 'quantity' && <QuantityColorList legend={legend} />}
      {visualisation === 'category' && <CategoryColorList legend={legend} />}
    </Box>
  )
}
