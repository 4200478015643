import { Divider, Stack, Tab, Tabs } from '@mui/material'
import type { ChangeEvent } from 'react'
import React, { useCallback, useState } from 'react'

interface MapShareDrawerProps {
  onTabChange?: (value: string) => void
  slots?: {
    EmailShare?: React.ComponentType
    PublicShare?: React.ComponentType
  }
}

const Empty = () => null

const defaultSlots = {
  EmailShare: Empty,
  PublicShare: Empty
}

function MapShareDrawer(props: MapShareDrawerProps) {
  const { onTabChange, slots } = props

  const [currentTab, setCurrentTab] = useState<string>('email-share')

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: string): void => {
      setCurrentTab(value)
      if (onTabChange) onTabChange(value)
    },
    []
  )

  return (
    <Stack
      sx={{ position: 'absolute', width: '100%', flexGrow: 1, height: '100%' }}
    >
      <Tabs
        sx={{ mx: 2 }}
        indicatorColor='primary'
        onChange={handleTabsChange}
        scrollButtons='auto'
        color='secondary'
        textColor='secondary'
        value={currentTab}
        variant='scrollable'
      >
        <Tab
          label='Share by email'
          value='email-share'
        />

        <Tab
          label='Share publicly'
          value='public-share'
        />
      </Tabs>

      <Divider />

      <Stack
        spacing={2}
        p={2}
        sx={{ flexGrow: 1 }}
      >
        {currentTab === 'email-share' && <Slots.EmailShare />}
        {currentTab === 'public-share' && <Slots.PublicShare />}
      </Stack>
    </Stack>
  )
}

export { MapShareDrawer }
