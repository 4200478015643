import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import dynamic from 'next/dynamic'

const MuiOtpInput = dynamic(
  () => import('mui-one-time-password-input').then((mod) => mod.MuiOtpInput),
  {
    ssr: false,
    loading: () => null
  }
)

import { Fragment } from 'react'
import { AuthFormHeader } from '../FormHeader'

interface ConfirmSignUpFormProps {
  email?: string
  slots?: {
    Form?: React.ElementType
    OtpInput?: React.ElementType
    Button?: React.ElementType
  }
  slotProps?: {
    Form?: any
    OtpInput?: any
    Button?: any
  }
}

export function ConfirmSignUpForm(props: ConfirmSignUpFormProps) {
  const { email, slots, slotProps } = props

  const Slots = {
    Form: Fragment,
    OtpInput: MuiOtpInput,
    Button: LoadingButton,
    ...slots
  }

  return (
    <>
      <AuthFormHeader
        title='Confirm your email'
        subtitle={`We sent a code to ${
          email || 'your email'
        }. Enter the code to start using your account.`}
      />

      <Stack
        direction='column'
        spacing={3}
        component={Slots.Form}
        {...slotProps?.Form}
      >
        <Slots.OtpInput
          length={6}
          sx={{
            '& .MuiFilledInput-input': {
              p: '18px'
            }
          }}
          {...slotProps?.OtpInput}
        />

        <Slots.Button
          color='primary'
          variant='contained'
          type='submit'
          size='large'
          sx={{
            borderRadius: 99
          }}
          {...slotProps?.Button}
        >
          Continue
        </Slots.Button>
      </Stack>
    </>
  )
}
