import { Box, Paper, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'

import image1 from './section-5-1.webp'
import image2 from './section-5-2.webp'
import image3 from './section-5-3.webp'

export function Section5() {
  return (
    <Stack
      py={8}
      spacing={8}
    >
      <Typography
        component='h2'
        variant='next:h2'
        fontWeight={700}
        textAlign='center'
        flex={1}
      >
        Territory Management for Every Team
      </Typography>

      <Stack
        px={4}
        gap={4}
        direction='row'
        justifyContent='center'
        flexWrap='wrap'
      >
        <BenefitCard
          image={image1}
          title='Operations: balance & optimize'
          alt='Operations: balance & optimize'
          description='Whether you manage retail branches or service routes, Mapstack helps you balance workloads and ensure no region is over- or under-served. Create fair, efficient boundaries in minutes.'
        />

        <BenefitCard
          image={image2}
          title='Sales: target key regions'
          alt='Sales: target key regions'
          description='Empower your sales team with clear territory lines, so reps know exactly where to focus. Visualize high-performing areas, identify underserved markets, and adjust boundaries for maximum ROI.'
        />

        <BenefitCard
          image={image3}
          title='Leadership: gain a strategic view'
          alt='Leadership: gain a strategic view'
          description='Executives can see all territories at a glance to make informed decisions on expansion, resource allocation, and overall strategy. Stop guessing and start leading with real-time territory performance.'
        />
      </Stack>
    </Stack>
  )
}

function BenefitCard(props) {
  const { title, description, image, alt } = props
  return (
    <Stack
      direction='column'
      component={Paper}
      variant='elevation'
      elevation={8}
      spacing={3}
      sx={{ p: 4, width: '100%', maxWidth: '420px', borderRadius: 3 }}
    >
      <Typography
        component='h3'
        variant='next:h5'
        fontWeight={700}
        textAlign='center'
        flexBasis='60px'
      >
        {title}
      </Typography>
      <Typography
        flex={1}
        color='text.secondary'
        variant='next:h6'
        letterSpacing='tight'
        textAlign='center'
      >
        {description}
      </Typography>

      <Box
        component={Paper}
        variant='outlined'
        sx={{
          position: 'relative',
          aspectRatio: '1/1',
          maxHeight: '400px',
          fontStyle: 'italic',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: 2
        }}
      >
        <NextImage
          src={image}
          fill
          alt={alt}
        />
      </Box>
    </Stack>
  )
}
