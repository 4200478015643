/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash'
import { nanoid } from 'nanoid'
import { useMemo } from 'react'
import { PropertiesListAll as View } from 'ui-components'
import {
  isCategory,
  isDateType,
  isMapstackProperty,
  isNumericType,
  isStringType
} from 'utils'
import useDateOperator from '~map-filter-view/hooks/useDateOperator'
import useNumericOperator from '~map-filter-view/hooks/useNumericOperator'
import useStringOperator from '~map-filter-view/hooks/useStringOperator'
import { useFilters } from '~map-filter-view/states/filter'
import { useMap } from '~map-filter-view/states/map'

const PropertiesList = (props) => {
  const {
    onFilterRemove = () => {},
    onFilterUpdate = () => {},
    onSetActiveFilters = () => {}
  } = props
  const filters = useFilters()
  const map = useMap()

  const { getRange } = useNumericOperator()
  const { getOptions, countOptions } = useStringOperator()
  const { getDateRange } = useDateOperator()

  const getPropertyValue = (property) => {
    const { type, name } = property

    switch (true) {
      case isStringType(type):
        return getOptions(name)

      case isNumericType(type):
        return getRange(name)

      case isDateType(type):
        return getDateRange(name)

      default:
        throw Error('Unsupported property type')
    }
  }

  const propertiesValue = useMemo(() => {
    const values = map.properties.flatMap((property) => {
      const { name, type, visible } = property

      if (isMapstackProperty(name)) return [] // skip mapstack properties
      if ('visible' in property && property.visible === false) return [] // skip hidden properties
      if (isStringType(type) && !isCategory(countOptions(name))) return [] // skip non-category string properties

      const filter = filters?.find((item) => item.name === name)
      const value = getPropertyValue(property)

      return [
        {
          id: filter?.id || nanoid(5),
          name: name,
          type: type,
          value: value,
          selectedValue: filter?.value
        }
      ]
    })

    return values || []
  }, [map.properties, filters])

  const removeFilterThrowable = (filter) => {
    const { value, id } = filter
    if (!isEmpty(value)) return
    onFilterRemove(id)
    throw new Error('Empty Options')
  }

  const handleApplyFilter = (filter) => {
    try {
      removeFilterThrowable(filter)
      onFilterUpdate(filter)
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  }

  const handleRemoveFilter = (id) => {
    onFilterRemove(id)
  }

  const handleFiltersActiveChange = (activeFilters) => {
    onSetActiveFilters(activeFilters)
  }

  return (
    <View
      propertiesValue={propertiesValue}
      onFilterChange={handleApplyFilter}
      onFilterRemove={handleRemoveFilter}
      onFiltersActiveChange={handleFiltersActiveChange}
    />
  )
}

export { PropertiesList }
