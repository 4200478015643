import { Box, Container, Paper, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'

import { CTAButtons } from './CTAButtons'
import image1 from './section-2-1.png'
import image2 from './section-2-2.png'

export function Section2() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <Stack
        component={Paper}
        spacing={6}
        alignItems='center'
        elevation={8}
        sx={{
          p: {
            xs: 4,
            sm: 6
          },
          borderRadius: 4
        }}
      >
        <Typography
          component='h2'
          variant='next:h2'
          fontWeight={700}
          textAlign='center'
        >
          From messy user data to filtered lists in seconds
        </Typography>

        <Typography
          component='p'
          variant='next:h6'
          color='text.secondary'
          textAlign='center'
          maxWidth='88%'
        >
          Remove wasted time processing user location data and manual analysis.
          Quickly filter and find best matching groups that can be easily shared
          or exported.
        </Typography>

        <HighlightCard
          image={image1}
          alt='Simplify your workflow'
          title='Simplify your workflow'
          description='Stop juggling multiple tools. Mapstack automatically cleans your user data, maps it instantly, and helps you filter and export in one platform—saving you time and simplifying your workflow.'
        />

        <HighlightCard
          direction='row-reverse'
          image={image2}
          alt='No development skills needed'
          title='No development skills needed'
          description={
            <>
              Create your maps with ease with no development knowledge, API
              integrations or mapping experts required.
              <Box py={1} />
              Simply upload or connect your data and see it instantly on an
              interactive map.
            </>
          }
        />

        <HighlightCard
          image={image2}
          alt='Your data, your way'
          title='Your data, your way'
          description={
            <>
              Easily share interactive maps with clients or export filtered user
              lists for instant bookings and sales pitches. You decide how and
              where to use your data.
            </>
          }
        />

        <Typography
          component='h3'
          variant='next:h3'
          fontWeight={700}
          textAlign='center'
        >
          Fixed price and unlimited geocodes
        </Typography>

        <Typography
          component='p'
          variant='next:h6'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Low cost and predictable pricing to suit your needs. Easily get
          signoff
        </Typography>

        <CTAButtons />
      </Stack>
    </Box>
  )
}

function HighlightCard(props) {
  const { direction = 'row', image, alt, title, description } = props
  return (
    <Stack
      direction={direction}
      flexWrap='wrap'
      gap={8}
      justifyContent='center'
    >
      <Box
        sx={{
          position: 'relative',
          aspectRatio: '1/1',
          width: '100%',
          flex: 1,
          maxWidth: 400,
          maxHeight: 400,
          flexBasis: 'clamp(100%, 400px, 100%)'
        }}
      >
        <NextImage
          src={image}
          fill
          alt={alt}
        />
      </Box>
      <Stack
        flex={1}
        flexBasis='30ch'
        spacing={2}
        justifyContent='center'
      >
        <Typography variant='h2'>{title}</Typography>
        <Typography
          color='text.secondary'
          variant='next:h6'
          letterSpacing='tight'
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  )
}
