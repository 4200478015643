import {
  Box,
  Container,
  Divider,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import NextImage from 'next/image'
import { MapstackUserCases } from '~ui-components/components/organisms/MapstackUseCases'

import { CTAButtons } from './CTAButtons'
import image1 from './section-4-1.png'
import image2 from './section-4-2.png'
import image3 from './section-4-3.png'

export function Section4() {
  return (
    <Container maxWidth='xl'>
      <Paper
        variant='elevation'
        elevation={8}
        sx={{
          p: 2,
          borderRadius: 4
        }}
      >
        <Stack
          direction='column'
          spacing={8}
          my={8}
          alignItems='center'
        >
          <Typography
            variant='next:h2'
            component='h2'
            textAlign='center'
            fontWeight={700}
            maxWidth='27ch'
          >
            Goodbye messy spreadsheets, hello instant maps
          </Typography>

          <Typography
            component='p'
            variant='next:h6'
            textAlign='center'
            color='text.secondary'
            maxWidth='96ch'
          >
            Say goodbye to scattered spreadsheets and guesswork. Unify data from
            multiple systems for a complete, interactive view of your
            market—helping you scale confidently.
          </Typography>

          <Stack
            direction='row'
            gap={4}
            flexWrap='wrap'
            justifyContent='center'
          >
            <HowItWorkCard
              image={image1}
              title='Upload your location data'
              alt='Upload your location data'
              description={
                <>
                  Easily add your location and address data from spreadsheets or
                  wherever you store it. No need for data reorganising -
                  Mapstack does all the hard work for you.
                </>
              }
            />

            <HowItWorkCard
              image={image2}
              title='Instantly see your map'
              alt='Instantly see your map'
              description={
                <>
                  Within seconds, Mapstack transforms your sales, logistics, and
                  customer data into an interactive map—revealing top-performing
                  regions and hidden opportunities.
                </>
              }
            />

            <HowItWorkCard
              image={image3}
              title='Analyze and act'
              alt='Analyze and act'
              description={
                <>
                  Quickly segment by region or performance metrics, compare
                  logistics routes, and forecast potential growth. Make smarter
                  decisions using real-time insights you can share across teams.
                </>
              }
            />
          </Stack>

          <Stack
            spacing={8}
            alignItems='center'
          >
            <Typography
              component='h2'
              variant='next:h3'
              fontWeight={700}
              textAlign='center'
            >
              Powerful map visualisations
            </Typography>

            <Typography
              component='p'
              variant='next:h6'
              textAlign='center'
              color='text.secondary'
              maxWidth='88%'
            >
              Quickly adjust the visual layout of your data to get greater
              insight. From clusters, head maps, aggregates and more.
            </Typography>

            <MapstackUserCases />
          </Stack>

          <CTAButtons />
        </Stack>
      </Paper>
    </Container>
  )
}

function HowItWorkCard(props) {
  const { title, description, image, alt } = props
  return (
    <Box
      width='100%'
      maxWidth={420}
      component={Paper}
      variant='elevation'
      elevation={8}
      borderRadius={3}
    >
      <Typography
        variant='next:h5'
        component='h3'
        textAlign='left'
        fontWeight={700}
        p={3}
      >
        {title}
      </Typography>
      <Divider />
      <Stack
        p={3}
        spacing={3}
      >
        <Box
          component={Paper}
          variant='outlined'
          sx={{
            position: 'relative',
            aspectRatio: '16/9',
            width: '100%',
            fontStyle: 'italic',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <NextImage
            src={image}
            alt={alt}
            fill
          />
        </Box>
        <Typography
          component='p'
          variant='next:subtitle1'
          textAlign='left'
        >
          {description}
        </Typography>
      </Stack>
    </Box>
  )
}
