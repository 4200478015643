import { WorkspaceAvatar as View } from 'ui-components'
import { useAuthCurrentWorkspace } from 'user-auth'

export function WorkspaceAvatar(props) {
  const currentWorkspace = useAuthCurrentWorkspace()
  return (
    <View
      {...props}
      src={currentWorkspace?.logo}
    />
  )
}
