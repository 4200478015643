import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import NextImage from 'next/legacy/image'

import { RouterLink } from '~ui-components/components/atoms/RouterLink'

import type { Theme } from '@mui/material/styles/createTheme'
import error401 from './error-401.png'

const Unauthorized = () => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <>
      <Box
        data-testid='unauthorized'
        component='main'
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px'
        }}
      >
        <Container maxWidth='lg'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6
            }}
          >
            <NextImage
              src={error401}
              alt='Not authorized'
              width={400}
              height={400}
              style={{
                maxWidth: '100%',
                maxHeight: '100%'
              }}
            />
          </Box>
          <Typography
            align='center'
            variant={mdUp ? 'h1' : 'h4'}
          >
            401: Authorization required
          </Typography>
          <Typography
            align='center'
            color='text.secondary'
            sx={{ mt: 0.5 }}
          >
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Button
              component={RouterLink}
              href='/'
            >
              Back to Home
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export { Unauthorized }
