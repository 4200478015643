import { Hub } from 'aws-amplify/utils'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { Provider, useAtom } from 'user-auth/libs/jotai'

import { useGetCurrentWorkspace } from 'user-auth/hooks/useGetCurrentWorkspace'
import {
  useGetCurrentUserById,
  useGetCurrentUserToken
} from 'user-auth/hooks/useGetUserById'

import { createStore } from 'user-auth/libs/jotai'
import {
  auth,
  currentWorkspace as currentWorkspaceAtom,
  initialState,
  setAuth
} from 'user-auth/states/auth'

type AuthProviderProps = {
  state?: any
  ssr?: boolean
  children: ReactNode
}

export const AuthProvider = (props: AuthProviderProps) => {
  const { state: stateProps, ssr, children } = props

  const [store] = useState(() => createStore())
  const [initialValue] = useState(() =>
    ssr
      ? [
          [
            auth,
            {
              ...initialState,
              ...getAuth(stateProps, { ssr })
            }
          ]
        ]
      : undefined
  )

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signedOut': {
          break
        }

        default:
        // do nothing
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Provider
      store={store}
      initialValues={initialValue}
    >
      <GetCurrentWorkspaceQuery />
      <GetCurrentUserTokenQuery />
      <GetCurrentUserByIdQuery />
      {children}
    </Provider>
  )
}

function GetCurrentWorkspaceQuery() {
  const [, setCurrentWorkspace] = useAtom(currentWorkspaceAtom)
  useGetCurrentWorkspace({
    onSuccess: (currentWorkspace) => {
      setCurrentWorkspace(currentWorkspace)
    }
  })
  return <></>
}

function GetCurrentUserTokenQuery() {
  useGetCurrentUserToken({
    onSuccess: (token) => {
      setAuth({ token, initialized: true, authenticated: !!token })
    }
  })
  return <></>
}

function GetCurrentUserByIdQuery() {
  const { data: user, status } = useGetCurrentUserById()
  useEffect(() => {
    setAuth(getAuth({ user, status }))
  }, [user, status])
  return <></>
}

const getAuth = (auth, options?) => {
  const { status, user, token } = auth || {}
  const { ssr = false } = options || {}

  const ssrPayload = ssr
    ? {
        initialized: true,
        authenticated: !!token,
        token: token
      }
    : {}

  return {
    ...(status === 'pending'
      ? {
          status: 'loading',
          loading: true,
          ready: false,
          ...ssrPayload
        }
      : {}),

    ...(status === 'error'
      ? {
          status: 'error',
          loading: false,
          ready: true,
          ...ssrPayload
        }
      : {}),

    ...(status === 'success'
      ? {
          status: 'ready',
          loading: false,
          ready: true,
          userId: user?.id,
          user,
          isStaff:
            !!user?.email &&
            ['@mangomap.com', '@mapstack.io'].some((domain) =>
              user.email.includes(domain)
            ),
          ...ssrPayload
        }
      : {})
  }
}
