import { useState } from 'react'

import { IconListItemProps } from '~ui-components/components/molecules/IconListItem'
import { UnauthenticatedActionTooltip } from '~ui-components/components/molecules/UnauthenticatedActionTooltip'

type MapStarButtonProps = IconListItemProps & {
  counts: number
  authenticated: boolean
  selected: boolean
  onSelect: () => void
  onUnselect: () => void
  onSignInClick: () => void
  slots?: any
}

export function BookmarkMapButton(props: MapStarButtonProps) {
  const {
    counts,
    selected,
    authenticated,
    onSelect,
    onUnselect,
    onSignInClick,
    slots: Slots,
    ...rest
  } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    if (!authenticated) {
      setAnchorEl(event.currentTarget)
      return
    }

    if (!selected) onSelect()
    else onUnselect()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <UnauthenticatedActionTooltip
        primary='Want to save this map for later?'
        secondary='Sign in to get started'
        anchorEl={anchorEl}
        onClose={handleClose}
        onSignInClick={onSignInClick}
      />
      {Slots.Button && <Slots.Button onClick={handleClick} />}
    </>
  )
}
