import { useAtom } from 'user-auth/libs/jotai'
import { atoms } from 'user-auth/states/form'

import { useEffect } from 'react'
import { ConfirmSignUpForm } from '../ConfirmSignUp'
import { EmailForm } from '../Email'
import { LoginForm } from '../Login'
import { RegisterForm } from '../Register'
import { SignInEmailPasswordForm } from '../SignInEmailPassword'
import { SignUpEmailPasswordForm } from '../SignUpEmailPassword'
import { VerifyForm } from '../Verify'

export function Form() {
  const [form, setForm] = useAtom(atoms.form)

  useEffect(() => {
    return () => {
      setForm('login')
    }
  }, [])

  return (
    <>
      {form === 'login' && <LoginForm />}
      {/*  */}
      {form === 'email' && <EmailForm />}
      {form === 'register' && <RegisterForm />}
      {form === 'verify' && <VerifyForm />}
      {/*  */}
      {form === 'signInEmailPassword' && <SignInEmailPasswordForm />}
      {form === 'signUpEmailPassword' && <SignUpEmailPasswordForm />}
      {form === 'confirmSignUp' && <ConfirmSignUpForm />}
    </>
  )
}
