import { autoSignIn, confirmSignUp } from 'aws-amplify/auth'
import { useFormik } from 'formik'
import { ConfirmSignUpForm as Form } from 'ui-components'
import * as yup from 'yup'

import { useAtom } from 'user-auth/libs/jotai'
import { atoms } from 'user-auth/states/form'

const schema = yup.object().shape({
  code: yup.string().length(6).required('Code is required')
})

export function ConfirmSignUpForm() {
  const [email] = useAtom(atoms.email)
  const [code, setCode] = useAtom(atoms.code)

  const form = useFormik({
    validationSchema: schema,
    initialValues: {
      code: ''
    },
    onSubmit: async (values) => {
      const { code } = values

      setCode(code)

      try {
        await confirmSignUp({
          username: email,
          confirmationCode: code
        })

        await autoSignIn()

        go('/sign-in/callback')
      } catch (error) {
        console.warn(error)
        go('/sign-in')
      }
    }
  })

  const go = (pathname) => {
    window.location.href = pathname
  }

  const handleChange = (value) => {
    form.setFieldValue('code', value)
  }

  return (
    <Form
      email={email}
      slotProps={{
        Form: {
          component: 'form',
          onSubmit: form.handleSubmit
        },
        OtpInput: {
          value: form.values.code,
          onChange: handleChange
        },
        Button: {
          loading: form.isSubmitting,
          disabled: !form.isValid
        }
      }}
    />
  )
}
