import { useRouter } from 'next/router'
import { useState } from 'react'
import {
  CreateMapButton as Button,
  MapCreateDesktopDialog as Dialog,
  useMediaQuery,
  useTheme
} from 'ui-components'
import { useAuth, useAuthCurrentWorkspace, useFederatedAuth } from 'user-auth'

import { gotoOnBoard } from 'user-onboard/api/gotoOnboard'

export function CreateMapButton(props) {
  const {
    currentWorkspace: currentWorkspaceProps,
    mobileBreakpoint,
    ...rest
  } = props

  const router = useRouter()
  const { user, authenticated } = useAuth()
  const { gotoSignInPage } = useFederatedAuth()

  const authCurrentWorkspace = useAuthCurrentWorkspace()
  const currentWorkspace = currentWorkspaceProps || authCurrentWorkspace // use the prop if it's passed, otherwise use the auth current workspace

  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const mobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint || 1280))

  const handleClick = () => {
    if (mobile) {
      setOpen(true)
      return
    }

    if (!authenticated) {
      gotoSignInPage({ redirect: '/redirect/onboard' })
      return
    }

    const total = user?.membershipCollection?.total || 0

    if (total <= 0) {
      router.push(gotoOnBoard(user?.id))
      return
    }

    router.push(gotoOnBoard(user?.id, currentWorkspace?.id))
  }

  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        key={currentWorkspace?.id}
        {...rest}
        onClick={handleClick}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        slotProps={{
          Button: {
            onClick: handleClose
          }
        }}
      />
    </>
  )
}
