import Button from '@mui/material/Button'
import { Minus as MinusIcon, Plus as PlusIcon } from '@phosphor-icons/react'

type ZoomControlProps = {
  onZoomOut: () => void
  onZoomIn: () => void
}

export const ZoomControl = ({ onZoomOut, onZoomIn }: ZoomControlProps) => {
  return (
    <>
      <Button
        color='inherit'
        variant='outlined'
        onClick={() => onZoomOut()}
        sx={{
          minWidth: 0,
          p: 1,
          borderRadius: '8px',
          borderColor: (theme) => theme.palette.neutral[300],
          '&:hover': {
            bgcolor: (theme) => theme.palette.neutral[200]
          }
        }}
      >
        <MinusIcon />
      </Button>
      <Button
        color='inherit'
        variant='outlined'
        onClick={() => onZoomIn()}
        sx={{
          minWidth: 0,
          p: 1,
          borderRadius: '8px',
          borderColor: (theme) => theme.palette.neutral[300],
          '&:hover': {
            bgcolor: (theme) => theme.palette.neutral[200]
          }
        }}
      >
        <PlusIcon />
      </Button>
    </>
  )
}
