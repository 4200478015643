import { Auth, Seo } from 'ui-components'

import { Form } from './Form'

export function SignIn() {
  return (
    <>
      <Seo title='Sign In' />
      <Auth slots={{ Form }} />
    </>
  )
}
