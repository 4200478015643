import { RegisterForm as Form } from 'ui-components'

import { useAtom } from 'user-auth/libs/jotai'
import { atoms } from 'user-auth/states/form'

export function RegisterForm() {
  const [email] = useAtom(atoms.email)

  const [firstName, setFirstName] = useAtom(atoms.firstName)
  const [lastName, setLastName] = useAtom(atoms.lastName)

  const [, setForm] = useAtom(atoms.form)

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value)
  }

  const handleLastNameChange = (event) => {
    setLastName(event.target.value)
  }

  const handleClick = () => {
    setForm('verify')
  }

  return (
    <Form
      email={email}
      slotProps={{
        FirstNameInput: {
          value: firstName,
          onChange: handleFirstNameChange
        },
        LastNameInput: {
          value: lastName,
          onChange: handleLastNameChange
        },
        Button: {
          onClick: handleClick
        }
      }}
    />
  )
}
