import {
  Stack,
  Box,
  Typography,
  InputLabel,
  Switch,
  Divider
} from '@mui/material'
import { useState } from 'react'

export const InfoPanel = (props) => {
  const { slots, isOpenByDefault = false } = props
  const [openByDefault, setOpenByDefault] = useState(isOpenByDefault)

  return (
    <Stack spacing={2}>
      <Box sx={{ bgcolor: 'background.paper', p: 2 }}>
        <Typography
          variant='body2'
          color='text.secondary'
        >
          Help your users understand the story behind this map by adding detail
          about its purpose, datasource of key insights.
        </Typography>
      </Box>

      <Divider />

      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={3}
      >
        <InputLabel>Open by default</InputLabel>
        <Switch
          color='primary'
          edge='start'
          name='isOpenByDefault'
          checked={openByDefault}
          onChange={(event) => setOpenByDefault(event.target.checked)}
        />
      </Stack>

      <Divider />

      {slots.TextEditor && <slots.TextEditor isOpenByDefault={openByDefault} />}
    </Stack>
  )
}
