import { isEmpty } from 'lodash'
import { IncludePropertiesInput as Input } from 'ui-components'
import { useVisualisationProperties } from '~map-viewer/states/map'
import { Field } from '../Form'

const IncludePropertiesInput = () => {
  const properties = useVisualisationProperties()

  return (
    <Field
      name='properties'
      defaultValue={properties}
      validators={{
        onChange: ({ value }) => {
          return isEmpty(value) ? 'One or more properties required' : undefined
        }
      }}
    >
      {(field) => {
        return (
          <Input
            // Hide when the properties is less than or equal to one
            hidden={properties.length <= 1}
            disabled={properties.length <= 0 || field.form.state.isSubmitting}
            value={field.state.value}
            options={properties}
            onChange={(event, value) => {
              field.handleChange(value as any)
            }}
            error={!isEmpty(field.state.meta.errors)}
            helperText={field.state.meta.errorMap['onChange']}
          />
        )
      }}
    </Field>
  )
}

export { IncludePropertiesInput }
