import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { fetchAuthSession } from 'aws-amplify/auth'
import { graphql as apiKey } from 'ms-client/graphql/api-key'
import { graphql as userPool } from 'ms-client/graphql/user-pool'
import { isAuthenticated } from 'user-auth/helpers/isAuthenticated'
import {
  Query,
  QueryGetUserByIdArgs
} from 'user-auth/types/__generated/gql/graphql'

export const getUserByIdQuery = /* GraphQL */ `
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      uid
      firstName
      lastName
      email
      avatar
      createdAt
      defaultWorkspaceId
      defaultWorkspace {
        id
        name
        description
        logo
        plan
        email
        twitterUsername
        inviteId
        stripeCustomerId
        websiteUrl
        communityUrl
        createdAt
        updatedAt
      }
      membershipCollection {
        total
        items {
          role
          workspace {
            id
            name
            description
            logo
            plan
            email
            twitterUsername
            inviteId
            stripeCustomerId
            websiteUrl
            communityUrl
            createdAt
            updatedAt
            memberCollection {
              total
              items {
                role
                user {
                  id
                  firstName
                  lastName
                  email
                  avatar
                }
              }
            }
            guestCollection {
              total
              items {
                role
                user {
                  id
                  firstName
                  lastName
                  email
                  avatar
                }
              }
            }
          }
        }
      }
    }
  }
`

export type GetUserByIdResult = Pick<Query, 'getUserById'>

export const getCurrentUserByIdKey = () => {
  return ['getCurrentUserById']
}

export const getUserById = async (id: string | null) => {
  const authenticated = await isAuthenticated()
  const graphql = authenticated ? userPool : apiKey
  const data = await graphql<GetUserByIdResult, QueryGetUserByIdArgs>({
    query: getUserByIdQuery,
    variables: { id: id! }
  })
  return data?.getUserById
}

export const getCurrentUserIdKey = () => ['getCurrentUserId']

export const getCurrentUserTokenKey = () => ['getCurrentUserToken']

export const useGetCurrentUserId = () => {
  return useQuery({
    queryKey: getCurrentUserIdKey(),
    queryFn: async () => {
      const session = await fetchAuthSession()
      const userId = session?.tokens?.idToken?.payload?.['custom:id'] as unknown
      return (userId as string) || null
    }
  })
}

export const useGetCurrentUserById = (
  options?
): UseQueryResult<GetUserByIdResult['getUserById']> => {
  const { enabled = true } = options || {}
  return useQuery({
    enabled,
    queryKey: getCurrentUserByIdKey(),
    queryFn: async () => {
      const session = await fetchAuthSession()
      const userId = session?.tokens?.idToken?.payload?.['custom:id'] as string
      const user = await getUserById(userId)
      return user
    }
  })
}

export const useGetCurrentUserToken = (options?) => {
  const { onSuccess } = options || {}
  return useQuery({
    queryKey: getCurrentUserTokenKey(),
    queryFn: async () => {
      const session = await fetchAuthSession()
      const token = session?.tokens?.idToken?.toString() || null
      onSuccess?.(token)
      return token
    }
  })
}
