import { useQuery } from '@tanstack/react-query'
import jscookie from 'js-cookie'
import { base64, fromBase64 } from 'utils'

import { getWorkspace } from 'user-auth/helpers'
import { useAuth } from 'user-auth/hooks/useAuth'

export const getCurrentWorkspaceKey = (userId) => [
  'getCurrentWorkspace',
  userId
]

export const useGetCurrentWorkspace = (options?) => {
  const { onSuccess } = options || {}
  const { ready, user, userId } = useAuth()

  const getCurrentWorkspace = async () => {
    const cached = fromBase64(jscookie.get('workspace_current'))

    if (cached) {
      const workspace = user?.membershipCollection?.items.find(
        (item) => item.workspace.id === cached.id
      )?.workspace

      if (workspace) return workspace
    }

    const member = user?.membershipCollection?.items?.find(
      (item) => item?.workspace?.id === user?.defaultWorkspaceId
    )

    let workspace = member?.workspace

    if (!workspace) {
      const first = user?.membershipCollection?.items?.filter(
        (item) => item?.role === 'owner'
      )[0]

      workspace = first?.workspace
    }

    jscookie.set('workspace_current', base64(getWorkspace(workspace)))

    return workspace
  }

  const query = useQuery({
    enabled: ready,
    queryKey: getCurrentWorkspaceKey(userId),
    queryFn: async () => {
      const currentWorkspace = await getCurrentWorkspace()
      onSuccess?.(currentWorkspace)
      return currentWorkspace
    }
  })

  return query
}
