class UploadError extends Error {
  constructor(message) {
    super(message)
    this.name = 'Upload Error'
  }
}

const getUploadError = (message) => {
  return new UploadError(message)
}

export { getUploadError }
