import { every, forEach } from 'lodash'
import {
  isValidUrl,
  isString,
  isPhoneNumber,
  isDate,
  isCategory,
  isEmail,
  isNumber,
  isCurrency,
  isPercentage
} from '../../functions'

const DEFINED_OPTIONS = {
  text: isString,
  longText: (value) => isString(value) && value.length > 30,
  select: isCategory,
  url: isValidUrl,
  phoneNumber: isPhoneNumber,
  email: isEmail,
  number: isNumber,
  currency: isCurrency,
  date: isDate,
  percentage: isPercentage
}

export const getPropertyOptionRange = (values) => {
  const options = [] as string[]
  forEach(DEFINED_OPTIONS, (fn, key) => {
    if (key === 'select') {
      if (every(values, isString)) {
        fn(values.length) && options.push(key)
      }
    } else if (every(values, fn)) {
      options.push(key)
    }
  })
  return options
}
