import { atom } from 'jotai'
import jscookie from 'js-cookie'
import { store } from 'user-auth/libs/jotai'
import { base64, fromBase64 } from 'utils'

import { getWorkspace } from 'user-auth/helpers'
import { sessionAtom } from 'user-auth/helpers/sessionAtom'
import { User, Workspace } from 'user-auth/types/__generated/gql/graphql'

export type AuthState = {
  status: string | 'idle' | 'loading' | 'ready'
  initialized: boolean
  loading: boolean
  authenticated: boolean
  ready: boolean
  userId?: string
  user?: User
  token: string | null
  isStaff: boolean
}

export const initialState: AuthState = {
  status: 'idle',
  initialized: false,
  loading: false,
  authenticated: false,
  ready: false,
  userId: undefined,
  user: undefined,
  token: null,
  isStaff: false
}

export const auth = sessionAtom<AuthState>('auth', initialState)
export const currentWorkspace = atom<Workspace | undefined>()

export const defaultWorkspace = atom<Workspace | null>(
  (get) => get(auth).user?.defaultWorkspace || null
)

export const workspaces = atom<Workspace[]>(
  (get) =>
    get(auth).user?.membershipCollection?.items?.map(
      (item) => item.workspace
    ) || []
)

const _workspace = atom<Workspace | undefined>(undefined)

export const authWorkspace = atom(
  (get) => get(_workspace),
  (get, set, id?: string) => {
    const authState = get(auth)

    const workspace = authState.user?.membershipCollection?.items?.find(
      (item) => item.workspace.id === id
    )?.workspace

    if (workspace) {
      set(_workspace, workspace)
    }
  }
)

export const setAuth = (state) => {
  const prevState = store.get(auth)

  const combined = { ...prevState, ...state }

  const currentWorkspace = getCurrentWorkspaceCache()

  if (!currentWorkspace && combined.user?.defaultWorkspace) {
    jscookie.set(
      'workspace_current',
      base64(getWorkspace(combined.user.defaultWorkspace))
    )
  }

  store.set(auth, combined)
}

export const resetAuth = () => {
  setAuth(initialState)
}

const getCurrentWorkspaceCache = () =>
  fromBase64(jscookie.get('workspace_current'))
