// @ts-nocheck
import { useEffect, useLayoutEffect, useState } from 'react'
import { CookiesSnack as Snack } from 'ui-components'
import { useAuth } from 'user-auth'

export function Cookies(props) {
  const { children } = props
  const { user, authenticated } = useAuth()

  const [client, setClient] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [open, setOpen] = useState(
    typeof window === 'undefined'
      ? false
      : localStorage.getItem('cookies:accepted') === null
  )

  const handleClose = () => {
    localStorage.setItem('cookies:accepted', String(false))
    setOpen(false)
  }

  const handleAccept = () => {
    localStorage.setItem('cookies:accepted', String(true))
    if (window && window.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted'
      })
    }
    setOpen(false)
  }

  useEffect(() => setClient(true), [])

  useLayoutEffect(() => {
    if (!window) return
    if (!window?.gtag) return
    if (!authenticated) return

    window.gtag('set', {
      user_id: user?.id,
      user_properties: {
        workspace_id: user?.defaultWorkspaceId
      }
    })
  }, [authenticated])

  useLayoutEffect(() => {
    const isGranted = localStorage.getItem('cookies:accepted') === 'true'

    defaultConsent()

    if (!window) return
    if (!window?.gtag) return
    if (!isGranted) return

    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted'
    })
  }, [])

  return (
    <>
      {children}
      {client && (
        <Snack
          open={open}
          onAccept={handleAccept}
          onClose={handleClose}
        />
      )}
    </>
  )
}

const defaultConsent = () => {
  if (!window) return
  if (!window?.gtag) return
  window.gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    wait_for_update: 500
  })
}
