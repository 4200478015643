import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material'
import { Upload as UploadIcon, X as XIcon } from '@phosphor-icons/react'

import { useDropzone } from 'react-dropzone'

import { useState } from 'react'
import type { DropzoneOptions, FileWithPath } from 'react-dropzone'
import { bytesToSize } from '~ui-components/utilities/bytesToSize'
import otherIcon from './icon-other.svg'

export interface MapFileInputProps extends DropzoneOptions {
  sx?: SxProps
  files?: FileWithPath[]
  onChange?: (value: FileWithPath | undefined) => void
}

export function FileIcon(props): React.JSX.Element {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'inline-flex',
        flex: '0 0 auto',
        justifyContent: 'center',
        height: '48px'
      }}
    >
      <SvgIcon
        component={otherIcon}
        alt='File'
        inheritViewBox
        sx={{ height: '100%', width: 'auto' }}
      />
    </Box>
  )
}

export const MapFileInput = (props: MapFileInputProps) => {
  const { sx, onDropAccepted, onDropRejected, onChange, ...options } = props

  const [files, setFiles] = useState<FileWithPath[]>([])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...options,

    multiple: false,

    onDropAccepted: (accepted, event) => {
      setFiles(accepted)
      onDropAccepted?.(accepted, event)
      onChange?.(accepted[0])
    },

    onDropRejected: (rejected, event) => {
      setFiles([])
      onDropRejected?.(rejected, event)
      onChange?.(undefined)
    }
  })

  const handleFileRemove = (event, file: FileWithPath) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f.path !== file.path))
    onChange?.(undefined)
  }

  return (
    <Stack spacing={2}>
      <Box
        sx={[
          {
            alignItems: 'center',
            border: 1,
            borderRadius: 1,
            borderStyle: 'dashed',
            borderColor: (theme) => theme.palette.primary.main,
            borderWidth: 1,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            outline: 'none',
            p: 4,
            ...(isDragActive && {
              backgroundColor: 'action.active'
            }),
            '&:hover': {
              backgroundColor: 'action.hover',
              cursor: 'pointer'
            }
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <Stack
          minWidth={320}
          component={Paper}
          alignItems='center'
          direction='row'
          spacing={2}
          p={2}
          borderRadius={2}
          elevation={24}
        >
          <Avatar
            sx={{
              bgcolor: 'primary.main',
              color: 'primary.contrastText',
              height: 64,
              width: 64
            }}
          >
            <SvgIcon>
              <UploadIcon weight='bold' />
            </SvgIcon>
          </Avatar>

          <Stack spacing={1}>
            <Typography
              sx={{
                '& span': {
                  textDecoration: 'underline'
                }
              }}
              variant='h6'
            >
              <span>Click to upload</span> address file or drag and drop
            </Typography>

            <Typography
              color='text.secondary'
              variant='body2'
            >
              We support xlsx, xls, and csv
            </Typography>
          </Stack>
        </Stack>
      </Box>

      {files.length > 0 && (
        <Stack
          component='ul'
          spacing={1}
          sx={{ listStyle: 'none', m: 0, p: 0 }}
        >
          {files.map((file, index) => {
            return (
              <Paper
                component='li'
                variant='outlined'
                key={`File-${index}`}
                sx={(theme) => ({
                  borderRadius: 2,
                  flex: '1 1 auto',
                  p: 1
                })}
              >
                <Stack
                  direction='row'
                  spacing={1}
                  alignItems='center'
                >
                  <FileIcon />
                  <Box sx={{ flex: '1 1 auto' }}>
                    <Typography variant='subtitle2'>{file.name}</Typography>
                    <Typography
                      color='text.secondary'
                      variant='body2'
                    >
                      {bytesToSize(file.size)}
                    </Typography>
                  </Box>
                  <Tooltip title='Remove'>
                    <IconButton
                      onClick={(event) => {
                        handleFileRemove?.(event, file)
                      }}
                    >
                      <XIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Paper>
            )
          })}
        </Stack>
      )}
    </Stack>
  )
}
