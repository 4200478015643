import { InputLabel, OutlinedInput, Stack, StackProps } from '@mui/material'
import { useFormik } from 'formik'
import { FC } from 'react'

type MapNameForm = {
  name: string
}

type MapSettingsFormProps = StackProps & {
  form: ReturnType<typeof useFormik<MapNameForm>>
  handleUpdateMapName: (name: string) => void
}

export const MapNameForm: FC<MapSettingsFormProps> = (props) => {
  const { form, handleUpdateMapName } = props

  const { values, handleChange } = form

  return (
    <Stack spacing={1}>
      <InputLabel>Map Title</InputLabel>
      <OutlinedInput
        name='name'
        type='text'
        value={values?.name}
        autoFocus
        onChange={handleChange}
        onBlur={() => handleUpdateMapName(values?.name)}
      />
    </Stack>
  )
}
