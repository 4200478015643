import { includes } from 'lodash'

const stringTypes = [
  'text',
  'category',
  'longText',
  'url',
  'email',
  'phoneNumber',
  'select'
]

const isStringType = (type) => includes(stringTypes, type)

export { isStringType }
