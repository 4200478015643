import { Stack, Typography } from '@mui/material'
import { List } from '@untitled-ui/icons-react'

export const MapLegendHeader = (props) => {
  return (
    <Stack
      alignItems='center'
      direction='row'
      spacing={1}
    >
      <List fontSize={28} />
      <Typography variant='h6'>Map key</Typography>
    </Stack>
  )
}
