import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { MapstackLoadingBackdrop, Seo } from 'ui-components'
import { getSignInLink } from 'utils'

import { initialState, setAuth } from 'user-auth/states/auth'
import { postSignOutRedirect } from 'user-auth/states/postSignOutRedirect'

export const SignOut = () => {
  const router = useRouter()

  useEffect(() => {
    const redirect = postSignOutRedirect.get()
    setAuth(initialState)
    router.push(redirect || getSignInLink()).then(() => {
      postSignOutRedirect.remove()
    })
  }, [])

  return (
    <>
      <Seo title='Signing out' />
      <MapstackLoadingBackdrop text='Redirecting...' />
    </>
  )
}
