import { isEmpty } from 'lodash'

export const fromBase64 = (base64?: string) => {
  if (!base64) return undefined
  if (isEmpty(base64)) return undefined
  return JSON.parse(Buffer.from(base64, 'base64').toString('utf-8'))
}

export const base64 = (obj: any) =>
  Buffer.from(JSON.stringify(obj)).toString('base64')
