import { LoadingButton } from '@mui/lab'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { ClipboardCopyButton } from '~ui-components/components/atoms/ClipboardCopyButton'
import { useClipboard } from '~ui-components/hooks/useClipboard'

type MapEmbedProps = {
  link?: string
  onEmbedClick?: () => void
  onDeleteClick?: () => void
  onCopyClick?: () => void
  hideDeleteLink: boolean
}

export const MapEmbed: FC<MapEmbedProps> = (props) => {
  const { hideDeleteLink, link, onEmbedClick, onDeleteClick, onCopyClick } =
    props

  const [, onCopyToClipboard] = useClipboard()

  const htmlString = renderMapEmbedHtml(link)

  const handleCopyHTML = () => {
    onCopyToClipboard(htmlString)
    onCopyClick?.()
  }

  const handleCopyLink = () => {
    onCopyToClipboard(link)
    onCopyClick?.()
  }

  return (
    <Stack sx={{ p: 2 }}>
      {!link && (
        <Box
          component={Stack}
          direction='column'
          gap={3}
          justifyContent='center'
          alignItems='center'
        >
          <Typography>
            Embed your map on Medium, Wordpress or your website - it will always
            update automatically.
          </Typography>
          <LoadingButton
            fullWidth
            color='secondary'
            onClick={onEmbedClick}
            variant='contained'
          >
            Embed
          </LoadingButton>
          <Typography color='text.secondary'>
            This link can be deleted at any time.
          </Typography>
        </Box>
      )}
      {link && (
        <Box
          component={Stack}
          sx={{ width: '100%' }}
          gap={3}
        >
          <Typography variant='h6'>HTML EMBED CODE</Typography>
          <Box
            component={Stack}
            direction='row'
            gap={3}
            justifyContent='space-between'
            alignItems='center'
          >
            <TextField
              value={htmlString}
              variant='outlined'
              fullWidth
            />
            <ClipboardCopyButton onClick={handleCopyHTML} />
          </Box>

          <Typography variant='h6'>SMART EMBED CODE</Typography>
          <Box
            component={Stack}
            direction='row'
            gap={3}
            justifyContent='space-between'
            alignItems='center'
          >
            <TextField
              value={link}
              variant='outlined'
              fullWidth
            />
            <ClipboardCopyButton onClick={handleCopyLink} />
          </Box>
          {!hideDeleteLink && (
            <LoadingButton
              size='large'
              variant='outlined'
              color='error'
              onClick={onDeleteClick}
            >
              Delete embed link
            </LoadingButton>
          )}
        </Box>
      )}
    </Stack>
  )
}

const renderMapEmbedHtml = (mapUrl) => {
  const html = (
    <iframe
      loading='lazy'
      src={mapUrl}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%'
      }}
    ></iframe>
  )

  return renderToStaticMarkup(html)
}
