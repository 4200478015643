import { App } from 'map-feature-info'
import { useDispatch } from 'react-redux'
import { useMap, useTitleProperty } from '~map-viewer/states/map'
import { closeFeatureInfo, useIsFeatureInfo } from '~map-viewer/states/ui'

const MapFeatureInfo = () => {
  const dispatch = useDispatch()
  const map = useMap()

  const isFeatureInfo = useIsFeatureInfo()
  const title = useTitleProperty()

  const handleClose = () => {
    dispatch(closeFeatureInfo())
  }

  return (
    <App
      isPolygonLayout={true}
      map={map}
      title={title.name}
      open={isFeatureInfo}
      onClose={handleClose}
    />
  )
}

export { MapFeatureInfo }
export default MapFeatureInfo
