import { Box } from '@mui/material'
import NextImage from 'next/legacy/image'
import {
  Dialog,
  DialogPropTypes
} from '~ui-components/components/molecules/Dialog'
import lostGif from './lost.gif'

const MapNotFoundDialog = (props) => {
  return (
    <Dialog
      title="Sorry we couldn't find that map"
      noCancel
      noConfirm
      {...props}
    >
      <Box sx={{ width: '100%', borderRadius: '4px', overflow: 'hidden' }}>
        <NextImage
          src={lostGif}
          layout='responsive'
          objectFit='fill'
          width={700}
          height={475}
        />
      </Box>
    </Dialog>
  )
}

MapNotFoundDialog.propTypes = DialogPropTypes

export { MapNotFoundDialog }
export default MapNotFoundDialog
