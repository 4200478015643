const formatNumber = (input = 0, { fallback, decimalPlace, format }) => {
  if (typeof input !== 'number') return fallback
  if (input === 0) return '0'
  let arr = input.toString().split('.')
  const [integer, decimal = 0] = arr
  let processedNumber = input

  const integerLength = integer.length
  let decimalPrecision = integerLength + 4 // Make precision for 3 decimal digits

  // INFO: Keep it for now, need this for float number less than 0 in polygon map
  if (Number(decimal) > 0) {
    const decimalLength = decimal.length // 0023 is length = 4
    const actualDecimalLength = Math.ceil(Math.log10(Number(decimal))) // 0023 is length = 2

    const differenceGap = decimalLength - actualDecimalLength

    // Handle for float number less than 0. Example: 0.000012
    if (Number(integer) === 0 && differenceGap > 2) {
      decimalPrecision = differenceGap + 4 // round check for next decimal digits
    }

    processedNumber = Number(input.toPrecision(decimalPrecision))
  }

  let fractionDigits = 0

  if (decimalPlace) {
    fractionDigits = Number(decimalPlace)
  } else {
    fractionDigits = decimalPrecision - integerLength - 1
  }

  const options = {}
  switch (format) {
    case 'compactShort':
      options['notation'] = 'compact'
      options['compactDisplay'] = 'short'
      options['minimumFractionDigits'] = Number(decimalPlace)
      break
    case 'compactLong':
      options['notation'] = 'compact'
      options['compactDisplay'] = 'long'
      options['minimumFractionDigits'] = Number(decimalPlace)
      break
    case 'pretty':
      options['notation'] = 'standard'
      options['minimumFractionDigits'] = Number(decimalPlace)
      break
    case 'none':
      options['minimumFractionDigits'] = Number(decimalPlace)
      options['useGrouping'] = false
      break
  }

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: fractionDigits,
    ...options
  }).format(processedNumber)
}

export { formatNumber }
