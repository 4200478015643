import { Divider, Stack, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { Sidebar } from '~ui-components/components/molecules/Sidebar'

type Tab = 'general' | 'info'

const Empty = () => null

const defaultSlots = {
  General: Empty,
  InfoPanel: Empty
}

export const MapSettings = (props) => {
  const { slots, slotProps, activeTab = 'general', onTabChange } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <>
      <Tabs
        sx={{ mx: 2 }}
        indicatorColor='primary'
        scrollButtons='auto'
        color='secondary'
        textColor='secondary'
        variant='scrollable'
        value={activeTab}
        onChange={(_, value) => onTabChange(value)}
      >
        <Tab
          label='General'
          value='general'
        />
        <Tab
          label='Info Panel'
          value='info'
        />
      </Tabs>

      <Divider orientation='horizontal' />

      <Stack
        flex={1}
        spacing={3}
        p={2}
        direction='column'
      >
        {activeTab === 'general' && <Slots.General {...slotProps?.General} />}
        {activeTab === 'info' && <Slots.InfoPanel />}
      </Stack>
    </>
  )
}
