import {
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { CaretDown } from '@phosphor-icons/react'

import { useEffect, useState, type FC, type ReactNode } from 'react'

import {
  Dropdown,
  DropdownMenu,
  DropdownTrigger
} from '~ui-components/components/atoms/Dropdown'
import { RouterLink } from '~ui-components/components/atoms/RouterLink'

interface NavItemProps {
  variant?: 'dropdown' | 'list'
  selected?: boolean
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  onClick?: () => void
  path?: string
  title?: string
  description?: string
  children?: ReactNode
}

const useIsSSR = () => {
  const [isSSR, setIsSSR] = useState(true)
  useEffect(() => {
    setIsSSR(false)
  }, [])
  return isSSR
}

export const NavItem: FC<NavItemProps> = (props) => {
  const {
    variant,
    selected,
    disabled,
    external,
    onClick = () => {},
    icon,
    path,
    title,
    description,
    children
  } = props

  const isSSR = useIsSSR()

  if (isSSR && variant === 'dropdown') {
    return (
      <Button
        variant='text'
        color='inherit'
        disabled={disabled}
        startIcon={icon}
      >
        {title}
      </Button>
    )
  }

  // With dropdown
  if (children) {
    if (variant === 'dropdown')
      return (
        <Dropdown>
          <DropdownTrigger>
            <Button
              variant='text'
              color='inherit'
              disabled={disabled}
              startIcon={icon}
              endIcon={
                <SvgIcon
                  inheritViewBox
                  component={CaretDown}
                />
              }
            >
              {title}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            disableScrollLock
            PaperProps={{
              elevation: 8,
              sx: {
                maxWidth: '100%',
                p: 1,
                minWidth: 200
              }
            }}
          >
            {children}
          </DropdownMenu>
        </Dropdown>
      )

    if (variant === 'list')
      return (
        <Stack
          direction='column'
          gap={1}
        >
          <Typography variant='next:subtitle2'>{title}</Typography>
          {children}
        </Stack>
      )

    return null
  }

  const linkProps = path
    ? external
      ? {
          component: 'a',
          href: path,
          target: '_blank'
        }
      : {
          component: RouterLink,
          href: path
        }
    : {}

  // Leaf
  return (
    <ListItemButton
      key={title}
      disabled={disabled}
      selected={selected}
      sx={{
        borderRadius: 1,
        px: 1.5,
        py: 0.5
      }}
      onClick={onClick}
      {...linkProps}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={title}
        secondary={description}
        primaryTypographyProps={{
          sx: {
            color: 'text.primary',
            fontSize: 14,
            fontWeight: 600
          }
        }}
        secondaryTypographyProps={{
          sx: {
            color: 'text.secondary',
            fontWeight: 500,
            fontSize: 13
          }
        }}
      />
    </ListItemButton>
  )
}
